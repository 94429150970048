<div class="po-wrapper">
   <po-toolbar p-title="Suporte"></po-toolbar>  
   <po-page-dynamic-search p-keep-filters="true" p-title="Tickets" [p-actions]="pageactions" [p-filters]="filters" (p-quick-search)="onfilter($event)" (p-change-disclaimers)="changeFilter($event)" (p-advanced-search)="buscaavancada($event)">
         <po-table 
            [p-loading]="carregando"
            [p-columns]="colunas" 
            [p-items]="resources" 
            [p-actions]="tableActions" 
            p-container="border" 
            p-sort="true" 
            p-striped="true"
         >     
      </po-table>
      <div *ngIf="listaTickets.length > 9 && mostrarBtnCarregar " class="po-offset-lg-5 po-offset-xl-5 po-xl-5 po-lg-5 po-md-5 po-sm-12">
         <po-button p-label="Carregar mais resultados" (p-click)="CarregarMais()"> </po-button>                            
     </div>
   </po-page-dynamic-search>
   
 </div>

