import { Router } from '@angular/router';
import { HttpService } from './../services/http.service';
import { Component, OnInit } from '@angular/core';
import { error } from 'protractor';
import { PoNotificationService, PoSelectOption, PoTableAction, PoTableColumn } from '@po-ui/ng-components';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  sessao: any;
  usuario: string;
  andamento = 0 ;
  atrasadas = 0;
  osspendentes = 0;
  ticketspendentes = 0;
  carregando = false;
  carregandolistaos = false;
  carregandodocs = false;
  analistaspendentes = [];
  listahistorico: Array<any>;
  //TODO
  itemsDocsAg: Array<any>;

  columnsDocs: Array<PoTableColumn> = [
    { property: 'codigo', width: '15%', label: 'Cod. Documento' },
    { property: 'titulo', label: 'Projeto'},
    { property: 'descricao'},
    //{ property: 'descricao', visible: false},
    //{ property: 'link', visible: false },
    {
      property: 'status',
      type: 'label',
      width: '10%',
      labels: [
        { value: 'Ag. Validar', color: 'color-07', label: 'Ag. Validar' },
        { value: 'Ag. Assinar', color: 'color-08', label: 'Ag. Assinar' }
      ]
    }

  ];
  readonly statusDocs: Array<PoSelectOption> = [
    { label: 'Ag. Validar', value: 'Ag. Validar' },
    { label: 'Ag. Assinar', value: 'Ag. Assinar' }
  ];

  statusOptions: Array<PoSelectOption> = [
    { value: 'Elaborar', label: 'Elaborar' },
    { value: 'Ag. Validar', label: 'Ag. Validar' },
    { value: 'Ag. Assinar', label: 'Ag. Assinar' },
    { value: 'Assinado', label: 'Assinado' },
  ];

  //FIM DO TODO

  colunaspendencias = [
    { property: 'nome',  label: 'Analista' },
    { property: 'oss', format: 'number', width: '15%', label: 'OSs' },
    { property: 'tickets', width: '15%', label: 'Tickets' },
    { property: 'tarefasatrasadas',  width: '15%', label: 'Tarefas Atrasadas' },
    { property: 'total',  width: '15%', label: 'Total' },
  ]

  tableActions: Array<PoTableAction> = [
    //{label: 'Editar' , action: this.editar.bind(this), icon: 'po-icon po-icon-edit' },
    {label: 'Link'   , action: this.abrirlink.bind(this), icon: 'po-icon po-icon-link' }
  ];

  constructor(
    private http: HttpService,
    private rota: Router,
    private notificacao: PoNotificationService
  ) { }

  ngOnInit() {
    //Inicializando Listas
    this.listahistorico = []
    this.itemsDocsAg = []
    
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));    
    this.usuario = 'Olá ' + this.sessao.nameuser + ', seja bem-vindo.';
    this.getIndicadores();
    this.getOssPendentes();
    this.getDocumentosAg();
    this.getHistoricos();
  }

  ngOnDestroy() {
    console.log('DESTROY')
  }
  
  getDocumentosAg(): void {
    this.carregandodocs = true;
    this.http.get('/eventos/documentosarguardando?tecnico=' + this.sessao.tecnico).subscribe(
      data => {
        this.carregandodocs = false;
        const ret: any = data;
        this.itemsDocsAg = ret.items;
      },
      erro => {
        this.carregandodocs = false;
        this.itemsDocsAg = [];
        console.log(erro);
      }
    );
  }
  getIndicadores(): void {
    this.carregando = true;
    this.http.get('/tarefas/indicadores?tecnico=' + this.sessao.tecnico).subscribe(
      data => {
        this.carregando = false;
        const ret: any = data;
        this.andamento = ret.andamento;
        this.atrasadas = ret.atrasadas;
        this.osspendentes = ret.osspendentes;
        this.ticketspendentes = ret.ticketspendentes;
      },
      erro => {
        this.carregando = false;
        console.log(erro);
      }
    );
  }

  abrirDetalhesUT(){
    this.rota.navigate(['/ultimas-tarefas']);
  }
  
  abrirDetalhesDA(){
    this.rota.navigate(['/documentos-aguardo']);
  }

  abrirtarefas(tipo) {
    if (tipo) {
      this.rota.navigate(['/tarefasanalista'], {queryParams: {status: tipo}});
    } else {
      this.rota.navigate(['/tarefasanalista']);
    }
  }
  
  abriragendamentos() {
    if (this.osspendentes === 0) {
      this.rota.navigate(['/agendamentos']);
    } else {
      this.rota.navigate(['/agendamentos'], {queryParams: {status: 'pendentes'}});
    }
  }

  abrirtickets() {
    if (this.ticketspendentes > 0) {
      this.rota.navigate(['/tickets'], {queryParams: {analista: this.sessao.tecnico}});
    } else {
      this.rota.navigate(['/tickets']);
    }
  }

  getOssPendentes(): void {
    this.carregandolistaos = true;
    this.http.get('/geral/osspendentes?tecnico=' + this.sessao.tecnico).subscribe(
      data => {
        this.carregandolistaos = false;
        const ret: any = data;
        this.analistaspendentes = ret.items;
      },
      erro => {
        this.carregandolistaos = false;
        console.log(erro);
      }
    );
  }

  getHistoricos(): void {
    this.http.get('/projetos/historico?tecnico=' + this.sessao.tecnico).subscribe(
      data => {
        const ret: any = data;
        this.listahistorico = ret.items;
      },
      erro => {
        console.log(erro);
      }
    );
  }

  abrirlink(linha) {
    window.open(linha.link, '_blank');
  }

  //Altera o status do Documento em Aguardo.
  setStatus(linha: any): void {
    console.log(linha);
    this.http.post('/eventos/'+ this.sessao.tecnico + '/?projeto=' + linha.projeto + '&codigo='+ linha.codigo, linha).subscribe(
      data => {
        const ret: any = data;
        if( ret.type === 'error' ){       
          this.notificacao.error(ret.detail);
        }else{
          this.notificacao.success('Status do documento ' + linha.codigo + ' alterado.');
        }
      },
      erro => {
        this.notificacao.success('Errro durante o processo de alteração do documento ' + linha.codigo);
      }
    );
  }

}
