<div class="po-wrapper">

  <po-toolbar  
    p-notification-number="10"
    *ngIf="mostrarmenu"     
    [p-notification-actions]="notificationActions"
    [p-notification-number]="getNotificationNumber()"    
    [p-actions]="actions"
    [p-profile]="profile"
    [p-profile-actions]="profileActions"  
    [p-title]="">
  </po-toolbar>

  <po-menu  *ngIf="mostrarmenu" [p-menus]="menus" p-filter="true" [p-logo]="logomenu"></po-menu>
  
  <router-outlet></router-outlet> 

</div>


