

<div class="po-wrapper">

    <po-page-default 
    [p-title]="usuario"> 
    
    <!--Quadros dos Analistas-->
    <div class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12">

        <po-widget style="position: relative;" (click)="abrirtarefas('')" class="po-xl-2 po-lg-3 po-md-3 po-sm-12" p-title="Tarefas em Andamento">
            <div class="po-font-display po-font-display po-md-8 po-sm-mb-8 po-mb-8 po-lg-mb-8">
                {{andamento}}
            </div>
            <span class="po-icon po-icon-list po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4"></span>
            <po-loading-overlay [hidden]="!carregando" >
            </po-loading-overlay>
        </po-widget>

        <po-widget style="position: relative;"  (click)="abrirtarefas('atrasadas')" class="po-xl-2 po-lg-3 po-md-3 po-sm-12" p-title="Tarefas Atrasadas">
            <div class="po-font-display po-md-8 po-sm-mb-8 po-mb-8 po-lg-mb-8">
                    {{atrasadas}}
            </div>
            <span *ngIf="atrasadas > 0" class="po-icon po-icon-history po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4"></span>
            <span *ngIf="atrasadas === 0" class="po-icon po-icon-ok po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4"></span>
            <po-loading-overlay [hidden]="!carregando" >
            </po-loading-overlay>
        </po-widget>
        
        <po-widget style="position: relative;" (click)="abriragendamentos()" class="po-xl-2 po-lg-3 po-md-3 po-sm-12" p-title="OSs Pendentes">
            <div class="po-font-display po-md-8 po-sm-mb-8 po-mb-8 po-lg-mb-8">
                {{osspendentes}}
            </div>
            <span *ngIf="osspendentes > 0" class="po-icon po-icon-exclamation po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4"></span>
            <span *ngIf="osspendentes === 0" class="po-icon po-icon-ok po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4"></span>
            <po-loading-overlay [hidden]="!carregando" >
            </po-loading-overlay>
        </po-widget>
        
        <po-widget style="position: relative;" (click)="abrirtickets()" class="po-xl-2 po-lg-3 po-md-3 po-sm-12" p-title="Ticket Pendentes">
            <div class="po-font-display po-md-8 po-sm-mb-8 po-mb-8 po-lg-mb-8">
                {{ticketspendentes}}
            </div>
            <span *ngIf="ticketspendentes > 0" class="po-icon po-icon-exclamation po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4"></span>
            <span *ngIf="ticketspendentes === 0" class="po-icon po-icon-ok po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4"></span>
            <po-loading-overlay [hidden]="!carregando" >
            </po-loading-overlay>
        </po-widget>

    </div>

    <!--Pendencias de Analistas-->
    <po-divider class="po-xl-8 po-lg-12 po-md-12 po-sm-12"></po-divider>
    <div class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12">
        <po-widget        
        [hidden]="analistaspendentes.length === 0"
        style="position: relative;"
        class="po-xl-8 po-lg-12 po-md-12 po-sm-12"
        p-title="Analistas com Pendências">
        <po-table    
            [p-columns]="colunaspendencias"
            p-sort="true"         
            p-height="350"          
            [p-loading]="carregandolistaos"
            p-striped="true"
            [p-items]="analistaspendentes">
        </po-table>
        </po-widget>
    </div>

    <!--DOCUMENTOS EM AGUARDO-->
    <div class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12">
        <po-widget        
        [hidden]="itemsDocsAg.length === 0"
        style="position: relative;"
        class="po-xl-8 po-lg-12 po-md-12 po-sm-12"
        p-title="Documentos em Aguardo">
        <po-button p-label="Detalhes"  p-type="primary" p-icon="po-icon po-icon-filter" (p-click)="abrirDetalhesDA()"> </po-button>
        <po-table 
            p-hide-columns-manager   
            p-auto-collapse
            [p-columns]="columnsDocs"
            p-sort="true"         
            p-height="350"
            [p-loading]="carregandodocs"          
            p-striped="true"
            [p-items]="itemsDocsAg"
            [p-actions]="tableActions">
            <ng-template
                p-table-row-template
                let-rowItem
                let-i="rowIndex"
                [p-table-row-template-arrow-direction]="'right'"
            >
                <po-widget  p-title="Detalhes do Documento {{ rowItem.codigo }}"> 
                    
                    <div class="po-row">
                        <po-select
                            name="status"
                            [(ngModel)]="rowItem.status"
                            (p-change)="setStatus(rowItem)"
                            p-label="Status do Documento"
                            [p-options]="statusOptions"
                        >

                        </po-select>
                    </div>
                    
                    <div class="po-row">
                        <po-info p-orientation="horizontal" p-label="Projeto" p-value="{{ rowItem.titulo }}"> </po-info>
                    </div>
                    <div class="po-row">
                        <po-info p-orientation="horizontal" p-label="Link" p-value="{{ rowItem.link }}"> </po-info>
                    </div>
                </po-widget>
            </ng-template>
        </po-table>
        </po-widget>
    </div>

    <!--Últimas atividades de seus projetos-->
    <div class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12">
        <po-widget        
        [hidden]="listahistorico.length === 0"
        style="position: relative;"
        class="po-xl-8 po-lg-12 po-md-12 po-sm-12"
        p-title="Últimas atividades de seus projetos">
        <po-button p-label="Detalhes"  p-type="primary" p-icon="po-icon po-icon-filter" (p-click)="abrirDetalhesUT()"> </po-button>
        <hr/>
        <div *ngFor ="let hist of listahistorico" >
            <po-tag class="po-xl-12 po-lg-12 po-md-12 po-sm-12" p-color="color-01" p-value="{{hist.descprojeto}}"> </po-tag>
            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" [innerHtml]="hist.descricao" >            
            </div>
            <po-container *ngIf="hist.comentario" p-no-border="true">            
                <div class="po-font-text-small po-xl-12 po-lg-12 po-md-12 po-sm-12" [innerHtml]="hist.comentario">
                </div>        
            </po-container>
            <div class="po-font-text-smaller po-offset-lg-11 po-offset-xl-11 po-xl-1 po-lg-1 po-md-12 po-sm-12">{{hist.data + ' ' + hist.hora.substring(0,5)}}</div>
            <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12"></po-divider>
        </div>
        </po-widget>
    </div>
    
    </po-page-default >   


</div>
