<div class="po-wrapper">
    <po-toolbar p-title="Ordens de Serviço"></po-toolbar>  
    <po-page-dynamic-search p-keep-filters="true" p-title="Ordens de Serviço" [p-actions]="pageactions" [p-filters]="filters" (p-quick-search)="onfilter($event)" (p-change-disclaimers)="changeFilter($event)" (p-advanced-search)="buscaavancada($event)">
        <po-table p-show-more="true" p-container="border" p-sort="true" p-striped="true"
          [p-loading]="carregando" [p-columns]="colunas" [p-items]="resources" [p-actions]="tableActions">     
       </po-table>
    </po-page-dynamic-search>
 </div>

 <po-modal #poModal p-title="Laudo" p-hide-close="false">
    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" [innerHtml]="textoLaudo" >            
    </div> 
</po-modal>