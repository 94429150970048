<div class="po-wrapper">
    <po-page-default 
    [p-title]="titulo"> 

        <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
            <ul *ngIf="mostraimagem" class="slider">
                <li *ngFor ="let imagem of imagens; index as i" >                  
                    <input type="radio" [id]="i+1" name="slide" checked>
                    <label *ngIf="imagens.length > 1" [for]="i+1"></label>
                    <img [src]="imagem"/>
                </li>
            </ul>   
        </div>
        
        <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">   
            <br>    
            <div *ngIf="definicao.length > 0" class="po-font-subtitle">
                O que é?
            </div>
            
            <div class="po-font-text-large">
                <a *ngFor ="let def of definicao">
                    {{def}} <br>
                </a>
                <br>
            </div>
            
            <div *ngIf="funcionalidades.length > 0" class="po-font-subtitle">
                Quais as funções? (Tecnicamente)
            </div>
            <div class="po-font-text-large">
                <li *ngFor ="let func of funcionalidades">
                    {{func}} <br>
                </li>        
                <br>
            </div>

            <div *ngIf="oquetestar.length > 0" class="po-font-subtitle">
                O que eu posso testar nesse ambiente?
            </div>

            <div class="po-font-text-large">
                <li *ngFor ="let testar of oquetestar">
                    {{testar}} <br>
                </li>        
                <br>
            </div>

            <div *ngIf="entrega.length > 0"   class="po-font-subtitle">
                O que ele Entrega?
            </div>
            <div class="po-font-text-large">
                <li *ngFor ="let ent of entrega">
                    {{ent}} <br>
                </li>        
                <br>
            </div>

            <div *ngIf="comousar.length > 0" class="po-font-subtitle">
                Como eu faço para usar?
            </div>
            <div class="po-font-text-large">
                <a *ngFor ="let usar of comousar">
                    {{usar}} <br>
                </a>        
                <br>
            </div>

            <div *ngIf="ajuda.length > 0" class="po-font-subtitle">
                Quem poderá me ajudar?
            </div>
            <div class="po-font-text-large">
                <a *ngFor ="let aju of ajuda">
                    {{aju}} <br>
                </a>        
                <br>
            </div>
            
            <div *ngIf="informacoes.length > 0" class="po-font-subtitle">
                Informações Técnicas
            </div>
            <div class="po-font-text-large">
                <li *ngFor ="let info of informacoes">
                    {{info}} <br>
                </li>      
                <br>
                <br>
            </div>

            <div *ngIf="links.length > 0" class="po-font-subtitle">
                Links Úteis
            </div>
            
            
            <div class="po-font-text-large">
                <li *ngFor ="let link of links">
                    <a [href]="link" target="_blank">
                        {{link}}
                    </a>
                </li>
            </div>
        
        <br>   
        <br>   
        
        <iframe width="700" height="500" [src]="urlvideo"
        frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
        </iframe>

    </div>


    </po-page-default>

</div>