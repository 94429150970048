<div class="po-wrapper">
    <po-toolbar p-title="Projetos"></po-toolbar>  
    <po-page-dynamic-search p-keep-filters="true" p-title="Projetos" [p-actions]="pageactions" [p-filters]="filters" (p-quick-search)="onfilter($event)" (p-change-disclaimers)="changeFilter($event)" (p-advanced-search)="buscaavancada($event)">
        <po-table p-show-more="true" p-container="border" p-sort="true" p-striped="true"
          [p-loading]="carregando" [p-columns]="colunas" [p-items]="resources" [p-actions]="tableActions">     
       </po-table>
    </po-page-dynamic-search>
 </div>

 <po-modal #poModal1 p-title="Novo Projeto" [p-primary-action]="confirm" [p-secondary-action]="close" p-hide-close="false">
   <form #optionsForm="ngForm" [hidden]="excluir">

      <po-input p-required="true" p-auto-focus="true" class="po-xl-8 po-lg-8 po-md-8 po-sm-8" name="descricao" p-label="Descrição do Projeto" [(ngModel)]="descricao"></po-input>                  
      
      <po-select class="po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4" name="status"  p-label="Status"  [(ngModel)]="statussel" [p-options]="status"></po-select>                    
      
      <po-select p-required="true" p-placeholder="Selecione o Cliente" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="cliente"  
      (p-change)="getprojetoscliente($event)"  p-label="Cliente" [p-options]="listaclientes" [(ngModel)]="clientesel" ></po-select>        

      <po-select p-required="true" p-placeholder="Selecione o projeto principal" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="projeto"  
      p-label="Projeto" [p-options]="listaprojetos" [(ngModel)]="projetosel" ></po-select>        
      
      <po-datepicker p-optional p-required="false" class="po-xl-4 po-lg-4 po-md-4 po-sm-4" name="datainicio" p-label="Data Inicial" [(ngModel)]="datainicio"></po-datepicker>                                          
      <po-datepicker p-optional p-required="false" class="po-xl-4 po-lg-4 po-md-4 po-sm-4" name=" datafim" p-label="Data Final" [(ngModel)]="datafim"></po-datepicker>                                          
      <po-number p-auto-focus="true" class="po-xl-4 po-lg-4 po-md-4 po-sm-4" name="previsto" p-label="Duração" [(ngModel)]="previsto" ></po-number>           
      <po-multiselect p-auto-focus="true" p-required="false" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" name="membrossel" p-label="Membros do Projeto" [p-options]="listamembros" [(ngModel)]="membrossel">  </po-multiselect>
      
      <po-input p-required="true" p-auto-focus="true" class="po-xl-9 po-lg-9 po-md-9 po-sm-9" name="observacao" p-label="Observação" [(ngModel)]="observacao"></po-input>                  
      <po-radio-group p-columns="3" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="prioridade" p-label="Prioridade" [p-options]="prioridades" [(ngModel)]="prioridade"></po-radio-group>      

      <po-input hidden="true" p-required="false" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="codprojeto" p-label="projeto" [(ngModel)]="codprojeto"></po-input>                  

    </form>

   <div *ngIf="excluir">
       <div class="po-font-text-large" >
           Confirma a exclusão da tarefa?
       </div>
       <div class="po-font-text">
           {{this.descricao}}
       </div>
   </div>

</po-modal>

<po-modal #poModal2 p-title="Histórico" p-hide-close="false">
    <div *ngFor ="let hist of listahistorico" >
        <po-tag class="po-xl-12 po-lg-12 po-md-12 po-sm-12" p-color="color-01" p-value="{{hist.descprojeto}}"> </po-tag>
        <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" [innerHtml]="hist.descricao" >            
        </div>
        <po-container *ngIf="hist.comentario" p-no-border="true">            
            <div class="po-font-text-small po-xl-12 po-lg-12 po-md-12 po-sm-12" [innerHtml]="hist.comentario">
            </div>        
        </po-container>
        <div class="po-font-text-smaller po-offset-lg-11 po-offset-xl-11 po-xl-1 po-lg-1 po-md-12 po-sm-12">{{hist.data + ' ' + hist.hora.substring(0,5)}}</div>
        <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12"></po-divider>
    </div>
 
</po-modal>