import { Injectable } from '@angular/core';

import { 
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest }
from '@angular/common/http';

import { Observable } from 'rxjs';


@Injectable()

export class Interceptor implements HttpInterceptor {

  intercept( request: HttpRequest<any>, next: HttpHandler ):Observable<HttpEvent<any>> {

    let dados:any = {}

    try {      
      dados = JSON.parse(localStorage.getItem('PO_USER_LOGIN') )      
    } catch (error) {      
    }
    
    if (dados && dados.token ) {
      request = request.clone({
        headers: request.headers.set('Authorization', dados.token)  
       });
    }

    return next.handle(request);
    
  }

}