<po-page-login class="login"
    p-product-name = "Olá, Bem-Vindo"
    [p-literals]="customLiterals"    
    [p-loading]="carregando"
    (p-login-submit)="efetuarLogin($event)"
    [p-logo]="logo"
    >
</po-page-login>

<!-- [p-background]="background" -->








