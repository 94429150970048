import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PoNotificationService } from '@po-ui/ng-components';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base/base.component';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-ultimas-tarefas',
  templateUrl: './ultimas-tarefas.component.html',
  styleUrls: ['./ultimas-tarefas.component.css']
})
export class UltimasTarefasComponent extends BaseComponent implements OnInit {

  sessao: any;
  
  //## LISTA DE INFORMAÇÕES ##
  listahistorico = [];
  listaprojetos = [];
  lsitacliente = [];
  analistas = [];
  projetos = [];
  clientes = [];
  //###
  
  mostrarBtnCarregar: boolean;
  carregando: boolean;
  projetosel: any;
  analistassel: any;
  dtgrav: any;
  clientesel:any;

  //## Opções salvas para o carregar mais
  // Guarda as informações que foram selecionadas durante a ação de filtro,
  // para quando o usuário clicar em "Carregar Mais" essas informações serem usadas.
  private loadProjeto: any;
  private loadAnalista: any;
  private loadDtGrav: any;
  private loadCliente: any;
  private numeroPagina: number;
  @ViewChild('optionsForm', { static: true }) form: NgForm;
  
  constructor( private http: HttpService, private rota: Router, private httpClient: HttpClient, private notificacao: PoNotificationService ) 
  { 
    super(http, '/projetos/tarefas/', '')
  }

  ngOnInit(): void {
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    this.loadProjeto = '';
    this.loadAnalista = '';
    this.loadDtGrav = '';
    this.loadCliente = '';
    this.numeroPagina = 0;
    this.mostrarBtnCarregar = true;
    this.carregando = true;
    this.getHistoricos();    
    // Pega todos os clientes.
    this.getClientes();
    // Pega todos os analistas
    this.getaux('analistas').subscribe(
      data => {
        this.analistas = [];
        this.analistas.push( {label: 'Todos', value: '' });
        this.dadosAux = data.items;
        this.dadosAux.forEach(element => {
          this.analistas.push({label: element.nome, value: element.codigo});
        });
      }
    );

    // Pega todos os projetos vinculados ao usuário logado
    this.http.get("/projetos/" + this.sessao.tecnico).subscribe(
      data => {
        this.projetos = [];
        this.projetos.push( {label: 'Todos', value: '' });
        this.dadosAux = data.items;
        this.dadosAux.forEach(element => {
          this.projetos.push( {label: element.descricao, value: element.codigo});
        });
      }
    );
    this.carregando = false;
  }

  /**
   * Recupera todo o historico e execuções de tarefas de um projeto e preenche a variavel responsável pelo grid das últimas atividades.
   */
  getHistoricos(): void {
    this.http.get('/projetos/historico?tecnico=' + this.sessao.tecnico).subscribe(
      data => {
        const ret: any = data;
        this.listahistorico = ret.items;
      },
      erro => {
        console.log(erro);
      }
    );
  }

  /**
   * Recupera todos os clientes e preenche a variavel responsável pela dropbox dos clientes no filtro.
   */
  getClientes() {
    this.http.get('/agendasagisrn/' + this.sessao.tecnico + '/clientes').subscribe(
      data => {
        this.clientes = [];
        this.clientes.push({label: 'Todos', value: '' });
        data.items.forEach(element => {
            this.clientes.push({label: element.nomecliente, value: element.codigo});
        });
      },
      error => {
        this.notificacao.error("Erro na requisição de clientes.")
      },
    );
  }

  /**
   * Formata a data do formato aaaa-mm-dd para o formato dd/mm/aaaa
   * @param data Data que desejamos formatar.
   * @returns Data formatada.
   */
  private formatarData( data: string): string{ 
    let novaData: string;
    if(data){
      let auxData: Array<string> = data.split("-");
      novaData = auxData[2]+'/'+auxData[1]+'/'+auxData[0];
    }
    return novaData;
  }
  
  /**
   * Preenche as variaveis de acordo com as opções selecionadas no filtro.
   */
  private loadVariavies(): void {
    this.loadAnalista = this.form.value.tecnico ?? '';
    this.loadCliente = this.form.value.cliente ?? '';
    this.loadDtGrav = this.formatarData(this.form.value.dgravacaotarefa) ?? '';
    this.loadProjeto = this.form.value.projeto ?? '';
    this.numeroPagina = 0;
  }

  /**
   * Submete as opções selecionadas no filtro e atualiza a grid.
   */
  SubmitFiltro(): void{
    this.carregando = true;
    this.http.post("/projetos/ultimastarefas?tecnico=" + this.sessao.tecnico, 
    {
      "cliente": this.form.value.cliente ?? '',	    
	    "dgravacaotarefa": this.formatarData(this.form.value.dgravacaotarefa) ?? '',
	    "projeto": this.form.value.projeto ?? '',
	    "tecnico": this.form.value.tecnico ?? '',
	    "tamanhopagina" : 0
    }).subscribe(
      data => {
        const response: any = data;
        
        if( response.code === "400")
        {
          this.notificacao.error(response.detail);
        }else{
          this.listahistorico = response.items;
          this.loadVariavies();
        }
        this.carregando = false;
        this.mostrarBtnCarregar = true;
      },
      error => {
        this.notificacao.error("Erro durante o processo de filtro");
        this.carregando = false;
        this.mostrarBtnCarregar = true;
      }
    )
  }

  /**
   * Recupera mais dados das últimas tarefas.
   */
  CarregarMais(): void{
    this.carregando = true;
    this.http.post("/projetos/ultimastarefas?tecnico=" + this.sessao.tecnico, 
    {
      "cliente": this.loadCliente,	    
	    "dgravacaotarefa": this.loadDtGrav,
	    "projeto": this.loadProjeto,
	    "tecnico": this.loadAnalista,
	    "tamanhopagina" : ++this.numeroPagina
    }).subscribe(
      data => {
        const response: any = data;
        
        if( response.code === "400")
        {
          this.notificacao.error(response.detail);
        }else{
          if(response.items.length == 0){
            this.mostrarBtnCarregar = false;
            this.notificacao.warning("Não existe mais tarefas.")
          }
          response.items.forEach(element => {
            this.listahistorico.push(element);
          });
        }
        this.carregando = false;
      },
      error => {
        this.notificacao.error("Erro durante o processo de Carregar Mais");
        this.carregando = false;
      }
    )
  }
}
