
<po-upload [hidden]="!anexoativo" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12"        
    p-auto-upload="true"
    p-drag-drop="true"
    [p-literals]="customLiterals"
    name="upload"  
    p-drag-drop-height="160"
    [p-url]="urlupload" 
    (p-upload)="antesdeupar($event)" 
    p-multiple="false"> 
</po-upload>

<po-widget
    class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12"
    p-no-shadow >

    <po-divider  p-label="Arquivo anexados"></po-divider>
    
    <div class="po-row" *ngFor ="let arquivo of arquivos">
        
    <div class="po-font-text-small-bold po-xl-10 po-lg-10 po-md-10 po-sm-10 po-mt-1">
        <a href="{{url + pathanexos + '/' + arquivo}}" target="_blank">
            {{arquivo}} 
        </a>
    </div>           

    <po-divider></po-divider>

    <po-button class="po-font-text-large po-xl-2 po-lg-2 po-md-2 po-sm-2"
        (p-click)="excluirarquivo(arquivo)"
        p-icon="po-icon po-icon-delete"
        p-label="Excluir"               
        p-small="true"
        p-type="link">
    </po-button>
    
    </div>

</po-widget>