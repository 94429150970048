import { OnInit, Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { PoPageFilter, PoTableColumn } from '@po-ui/ng-components';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export abstract class BaseComponent implements OnInit {

  constructor(
    public httpService: HttpService,
    public endpoint: String,
    public filtros: String,

  ) { }

  carregando = true;
  resources: any;
  resourcesAux: any;
  dadosAux: any;
  filtro = '';
  filtroaux = [];
  colunas: Array<PoTableColumn>;
  filter = [];
  paginacao: string = '';
  totalPagina:number = -1;
  sessao: any;
  titulo;
  root;
  
  ngOnInit() {}

  get(instaceComponent?) {

    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    this.carregando = true;
    this.httpService.get(this.endpoint + this.sessao.tecnico + '?' + this.filtros + this.paginacao).subscribe(
      data => {
        this.resourcesAux = data.items;
        this.resources = data.items;
        if (data.titulo !== undefined) {
          this.titulo = data.titulo;
        }
        if (data.root !== undefined) {
          this.root = data.root;
        }

        if( data.proximo !== undefined && instaceComponent !== undefined && this.resources !== undefined){
          instaceComponent.mostrarBtnCarregar = data.proximo;
          if (this.resources.size != 0) {
            this.resources.forEach(element => {
              instaceComponent.listaTickets.push(element);
            });
            this.resources = instaceComponent.listaTickets;
            this.resourcesAux = instaceComponent.listaTickets;
          }
        }

        if (this.filter.length > 0) {
          this.onfilter(this.filter[0]);
        }
      },
      error => {
        this.resources = [];
        this.carregando = false;
      },
      () => {
        this.carregando = false;
      }
    );

  }

  getaux(path: string): Observable<any> {
    console.log(this.endpoint + this.sessao.tecnico + '/' + path);
    return this.httpService.get(this.endpoint + this.sessao.tecnico + '/' + path + '?' + this.filtros);
  }

  onfilter(filterstr) {
    if (filterstr) {
      this.filter = [];
      this.filter.push(filterstr);
      this.filtroaux = this.filter.map(value => ({ value }));
      if (!this.resourcesAux) {
        this.resourcesAux = this.resources;
      }
      this.filtrar();
    } else {
      this.get();
    }
  }

  filtrar() {
    const filters = this.filtroaux.map(filtro1 => filtro1.value);
    if (filters.length > 0 ) {
      this.resources = this.resourcesAux.filter(item => {
        return Object.keys(item)
          .some(key => (!(item[key] instanceof Object) && this.incluifiltro(item[key], filters)));
      });

    } else {
      this.resources = this.resourcesAux;
    }
  }

  incluifiltro(item, filters) {
    return filters.some(filter => String(item).toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  }
}
