<div>
    <po-toolbar p-title="Documentos em Aguardo - detalhes"> 
    </po-toolbar>
    
    <po-page-default p-title="Documentos em Aguardo">
        <!--FILTRO-->
        <form #optionsForm="ngForm">
            <po-widget class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-title="Filtro dos documentos em aguardo">
                <po-select p-auto-focus="true" p-required="true" class="po-md-3 po-sm-mb-3 po-mb-3 po-lg-mb-3" name="projeto"  p-label="Projetos"  [(ngModel)]="projetoSelect" [p-options]="projetoOptions"></po-select>        
                <po-select p-required="true" class="po-md-3 po-sm-mb-3 po-mb-3 po-lg-mb-3" name="status"  p-label="Status do Documento"  [(ngModel)]="tipoSelect" [p-options]="tipoOptions"></po-select>
            </po-widget>
            <po-button p-type="primary" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-label="Filtrar" (p-click)="Submit()"> </po-button>
        </form>
        <po-divider *ngIf="itemsDocsAg.length>0" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-label="Resultado do Filtro"></po-divider>
        <!--DOCUMENTOS EM AGUARDO-->
        <div class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12">
            <po-widget        
            [hidden]="itemsDocsAg.length === 0"
            style="position: relative;"
            class="po-xl-8 po-lg-12 po-md-12 po-sm-12"
            p-title="Documentos em Aguardo">
            
            <po-table 
                p-hide-columns-manager   
                p-auto-collapse
                [p-columns]="columnsDocs"
                p-sort="true"         
                p-height="350"          
                p-striped="true"
                [p-items]="itemsDocsAg"
                [p-actions]="tableActions">
                <ng-template
                    p-table-row-template
                    let-rowItem
                    let-i="rowIndex"
                    [p-table-row-template-arrow-direction]="'right'"
                >
                    <po-widget  p-title="Detalhes do Documento {{ rowItem.codigo }}"> 
                        
                        <div class="po-row">
                            <po-select
                                name="status"
                                [(ngModel)]="rowItem.status"
                                (p-change)="setStatus(rowItem)"
                                p-label="Status do Documento"
                                [p-options]="statusOptions"
                            >

                            </po-select>
                        </div>
                        <div class="po-row">
                            <po-info p-orientation="horizontal" p-label="Projeto" p-value="{{ rowItem.titulo }}"> </po-info>
                        </div>
                        <div class="po-row">
                            <po-info p-orientation="horizontal" p-label="Link" p-value="{{ rowItem.link }}"> </po-info>
                        </div>
                    </po-widget>
                </ng-template>
            </po-table>
            </po-widget>
        </div>
    </po-page-default>
</div>