import { element } from 'protractor';
import { HttpService } from 'src/app/services/http.service';
import { AuthGuard } from './services/auth.guard';
import { Component} from '@angular/core';
import { PoDialogService, PoMenuItem, PoToolbarAction, PoToolbarProfile, PoNotificationService } from '@po-ui/ng-components';
import { Router, ActivatedRoute } from '@angular/router';
// import {Observable} from 'rxjs/Rx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent   {


  logomenu = 'assets/imgs/uf-logo-menu.png';

  mostrarmenu: boolean;
  usuariologado: string;
  actions = [];
  observableDados: any;      

  menus: Array<PoMenuItem> = [
  ];

  notificationActions = [];

  profileActions: Array<PoToolbarAction> = [
    { icon: 'po-icon-exit', label: 'Sair', type: 'danger', separator: true, action: item => this.sair() }
  ];

  profile: PoToolbarProfile = {
    title: 'Logado com:',
  };

  constructor(  private http: HttpService, 
                private authguard: AuthGuard, 
                private route: Router, 
                private notificao: PoNotificationService,                
                ) { 

                }
  
  ngOnInit() {    
    this.authguard.mostrarMenuEmitter.subscribe(
      (mostrar: boolean) => {
         this.mostrarmenu = mostrar;
      }
    );
    this.authguard.usuarioEmitter.subscribe(
      (sessao: any) => {
        this.usuariologado = sessao.nameuser;
        this.profile.subtitle = sessao.nameuser;
        this.montamenu(sessao);        
      }
    );

    
    let sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    if (sessao.tecnico) {
      //this.getNotificacoes(sessao.tecnico)
    }

  }

  getNotificacoes(id){
  
   this.monitoraNotificacoes(id);

    setInterval(() => {
      this.monitoraNotificacoes(id)
    }, 300000)

  }

  monitoraNotificacoes(id) {

    this.notificationActions = [];
    this.http.get('/geral/notificacoes?tecnico=' + id).subscribe(
      data => {
        if (data.items.length > 0) {
          data.items.forEach((element) => {
            this.notificationActions.push(            
              { label: element.mensagem, type: 'danger',action: (item) => this.onClickNotification(item, element.id, id)}
            )          
          });          
        }
      });

  }

  sair() {
    sessionStorage.clear();
    localStorage.clear();
    this.mostrarmenu = false;
    this.route.navigate(['/login']);
  }

  montamenu(sessao) {
    if (sessao && this.menus.length === 0 ) {
      if (sessao.acessos.terceiro) {
        this.menus.push({ label: 'Agendamentos', link: '/agendamentos', icon: 'po-icon po-icon-calendar', shortLabel: ' ' });
      } else {
        this.menus.push({ label: 'Início', link: '/', icon: 'po-icon po-icon-home', shortLabel: ' '});

        const submenu = [
          { label: 'Minha agenda', link: '/agendamentos', icon: 'po-icon po-icon-calendar', shortLabel: ' ' },
          { label: 'Consulta', link: '/agendaconsulta', icon: 'po-icon po-icon-calendar', shortLabel: ' ' }
        ];

        this.menus.push({ label: 'Agenda', icon: 'po-icon po-icon-calendar', shortLabel: ' ', subItems: submenu });

        // this.menus.push({ label: 'Portifólio'  , link: '/portifolio', icon: 'po-icon po-icon-news', shortLabel: ' ' });
        this.menus.push({ label: 'Banco de Conhecimento'  , link: '/bc', icon: 'po-icon po-icon-database', shortLabel: ' ' });
      
        if (sessao.acessos.projetos) {
          this.menus.push({ label: 'Projetos'  , link: '/projetos', icon: 'po-icon po-icon-calendar-ok', shortLabel: ' ' });
        }
        
        this.menus.push({ label: 'Suporte'  , link: '/tickets', icon: 'po-icon po-icon-settings', shortLabel: ' ' })
        this.menus.push({ label: 'Ordens Serviço'  , link: '/ordens', icon: 'po-icon po-icon-folder', shortLabel: ' ' })
        
        // this.menus.push({ label: 'Redmines'  , link: '/painel-redmine', icon: 'po-icon po-icon-list', shortLabel: ' ' })
      
      }
    }
  }


  getNotificationNumber() {
    let count = 0;
    this.notificationActions.forEach(element => {
      if (element.type === 'danger') {
        count++
      }
    });
    return count;
  }


  onClickNotification(item: PoToolbarAction, idnotificacao, id){
    let body = {id: idnotificacao};
    this.http.post('/geral/notificacoes?tecnico=' + id, body).subscribe(
      data => {
        let ret = Object(data);
        if (ret.code && ret.code === '200') {
          item.type = 'default';
          //this.getNotificacoes(id);
        }
      },
      error => {
        this.notificao.error('Não foi possivel salvar a notificação com lida.')
      }
    )        
  }



}
