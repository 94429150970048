<div class="po-wrapper">

    <po-toolbar p-title="Banco de Conhecimento"></po-toolbar>  

    
    <po-page-default p-title="Banco de Conhecimento">

        <po-widget class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-title="Buscar no banco de Conhecimento">
            <po-multiselect p-required="true" class="po-md-2 po-sm-mb-2 po-mb-2 po-lg-mb-2" name="tipo" p-label="Buscar em laudo" [p-options]="tipo" [(ngModel)]="tiposel">  </po-multiselect>
            <po-input p-auto-focus="true" class="po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4" p-placeholder="Informe o que quer pesquisar" [(ngModel)]="buscar" name="buscar" p-label="O que pesquisar?"> </po-input>
            <po-select class="po-md-2 po-sm-mb-2 po-mb-2 po-lg-mb-2" name="tipo"  p-label="Tipo de busca"  [(ngModel)]="pesquisasel" [p-options]="tipopesquisa"></po-select>            
            <po-datepicker p-optional="true" class="po-md-2 po-sm-mb-1 po-mb-1 po-lg-mb-1" name="datade"    [(ngModel)]="datade"  p-label="Período inicial" ></po-datepicker>
            <po-datepicker p-optional="true" class="po-md-2 po-sm-mb-2 po-mb-2 po-lg-mb-2" name="dataate"   [(ngModel)]="dataate" p-label="Período final"   ></po-datepicker>
        </po-widget>
        
        <po-button p-type="primary" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-label="Efetuar busca no BC" (p-click)="getbc(false)"> </po-button>
        
        <po-divider *ngIf="bc.length>0" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-label="Resultado da busca"></po-divider>
        
        <div *ngFor ="let item of bc">
            <po-widget p-height="300" class="po-md-3 po-sm-mb-1 po-mb-1 po-lg-mb-1" p-title="{{item.chamado + ' - ' + item.data}}">
                <div class="po-m-0">
                    <div class="po-font-text-bold">{{item.cliente}}</div>
                    <div class="po-font-text-small">{{item.laudo}}</div>
                    <po-divider></po-divider>
                    <po-tag class="" [p-value]="item.tecnico"> </po-tag>
                </div>
            </po-widget>
        </div>

        <po-loading-overlay *ngIf="carregando"
            [p-screen-lock]="false"
            p-text="Buscando dados" >
        </po-loading-overlay>
        
        <div *ngIf="bc.length > 0 && totalpaginas > 2 && (paginaatual + 1) < totalpaginas" class="po-offset-lg-5 po-offset-xl-5 po-xl-5 po-lg-5 po-md-5 po-sm-12">
            <po-button p-label="Carregar mais resultados" (p-click)="getbc(true)"> </po-button>                            
        </div>

    </po-page-default>

 </div>
