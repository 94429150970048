<div class="po-wrapper">
    <po-toolbar [p-title]="titulo"></po-toolbar>      
    <po-page-default p-title="Status Report">        
        <div class="po-row">
            <po-container 
                p-no-border="true"
                p-no-padding="true"
                class="po-lg-12 po-mb-1" >    

                <po-widget
                    p-height="350"                                         
                    class="po-lg-4 po-mb-1"
                    p-title="Pendências">
                    <po-table           
                        p-striped="true"
                        [p-columns]="colpendencias"
                        [p-items]="pendencias">
                    </po-table>
                </po-widget>

                <po-widget
                    p-height="350" 
                    class="po-lg-4 po-mb-1"
                    p-title="Riscos">
                    <po-table           
                        p-striped="true"
                        [p-columns]="colriscos"
                        [p-items]="riscos">
                    </po-table>
                </po-widget>

                <po-widget
                    p-height="350" 
                    class="po-lg-4 po-mb-1"
                    p-title="Documentos">
                    <po-table           
                        p-striped="true"
                        [p-columns]="coldocumentos"
                        [p-items]="documentos">
                    </po-table>
                </po-widget>
            
            </po-container>
            
        </div>

        <div class="po-row">
            <po-container 
                p-no-border="true"
                p-no-padding="true"
                class="po-lg-6 po-mb-1" >
                <po-widget class="po-lg-12 po-mb-1" p-title="SPI" >                    
                    
                    <div class="po-lg-12 po-mb-1">                       
                        <div [ngClass]="{'po-font-text-large-risco': spi < 0.8, 'po-font-text-large-atencao': spi < 0.9, 'po-font-text-large-normal': spi > 0.9 }" >
                            {{spi}}                                              
                        </div>
                    </div>
                    
                    <div class="po-font-text-smaller">
                         <span class="normal"></span>       
                         Normal
                    </div>                            
                    <div class="po-font-text-smaller">
                        <span class="atencao"></span>                      
                        Atençao
                    </div>
                    
                    <div class="po-font-text-smaller">
                        <span class="risco"></span>                      
                        Risco
                    </div>

                </po-widget> 
                <po-widget   
                    p-height="260"                   
                    class="po-lg-6" p-title="Tarefas x Quantidade({{totalquantidade}})">            
                        <canvas baseChart 
                            [datasets]="tarefasqtdData" 
                            [labels]="labelsChart" 
                            [options]="optionsChart"
                            [legend]="legendChart" 
                            [chartType]="typeChart">                        
                        </canvas>                    
                    </po-widget>
                <po-widget 
                    p-height="260"
                    class="po-lg-6" p-title="Tarefas x Horas({{totalhoras}})">            
                    <canvas baseChart
                        [datasets]="tarefashrsData" 
                        [labels]="labelsChart" 
                        [options]="optionsChart"
                        [legend]="legendChart" 
                        [chartType]="typeChart">
                    </canvas>
                </po-widget>      
            </po-container>
            <po-container 
                p-no-border="true"
                p-no-padding="true"
                class="po-lg-6 po-mb-1" >           
                <po-widget
                    p-height="435"
                    class="po-lg-6 po-mb-1"
                    p-title="Tarefas em Andamento">
                    <po-table           
                        p-striped="true"
                        [p-columns]="colandamento"
                        [p-items]="andamento">
                    </po-table>
                </po-widget>        
                <po-widget
                    p-height="435"
                    class="po-lg-6 po-mb-1"
                    p-title="Próximas tarefas">
                    <po-table           
                        p-striped="true"
                        [p-columns]="colproximas"
                        [p-items]="proximas">
                    </po-table>
                </po-widget>                
            </po-container>
        </div>    

    </po-page-default>    

</div>

