import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-audio-capture',
  templateUrl: './audio-capture.component.html',
  styleUrls: ['./audio-capture.component.css']
})
export class AudioCaptureComponent implements OnInit {
  audioStream: MediaStream | null = null;
  audioRecorder: MediaRecorder | any;
  audioChunks: any[] = [];
  audioBlob: any

  @Output() retornarAudio = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  // Solicita permissão para acessar o microfone e começa a gravar
  startRecording(): void {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.audioStream = stream;
        this.audioRecorder = new MediaRecorder(stream);
        
        // Captura os pedaços de áudio enquanto grava
        this.audioRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        // Começa a gravação
        this.audioRecorder.start();
      })
      .catch((error) => {
        console.error('Erro ao acessar o microfone', error);
      });
  }

  // Para a gravação e gera o arquivo de áudio
  stopRecording(justStop = false) {
    if (this.audioRecorder) {
      this.audioRecorder.onstop = () => {
        if (!justStop) {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(audioBlob);
          this.audioChunks = [];
          this.audioBlob =  audioBlob
          this.retornarAudio.emit('')
        }
        // const audio = new Audio(audioUrl);
        // audio.play();
        
      };

      this.audioRecorder.stop();
      
      
    }

    if (this.audioStream) {
      // Para o acesso ao microfone
      const tracks = this.audioStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  }
}
