import { Injectable, EventEmitter} from '@angular/core';
import { CanActivate,  Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { PoNotificationService } from '@po-ui/ng-components';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class AuthGuard implements CanActivate {

  sessao: any;

  mostrarMenuEmitter = new EventEmitter<boolean>();
  usuarioEmitter = new EventEmitter<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private notitificacao: PoNotificationService,
    private router: Router,
  ) { }

  canActivate(
    ): Observable<boolean> | boolean {

    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));

    if (this.sessao === null) {
      this.sessao = JSON.parse(sessionStorage.getItem('PO_USER_LOGIN'));  
      localStorage.setItem('PO_USER_LOGIN', JSON.stringify(this.sessao));
    }

    if (this.sessao != null && this.sessao.code === '200') {
      this.mostrarMenuEmitter.emit(true);
      this.usuarioEmitter.emit(this.sessao);
      return true;
    }

    if (this.sessao != null && this.sessao.code === '401') {

      this.sessao = JSON.parse(sessionStorage.getItem('PO_USER_LOGIN'));  
      localStorage.setItem('PO_USER_LOGIN', JSON.stringify(this.sessao));
      
      if (this.sessao != null && this.sessao.code === '200') {
        this.mostrarMenuEmitter.emit(true);
        this.usuarioEmitter.emit(this.sessao);
        return true;
      }
            
      this.notitificacao.error(this.sessao.message);
          
    }

    this.mostrarMenuEmitter.emit(false);
    this.usuarioEmitter.emit('');
    this.router.navigate(['/login']);

    return false;

  }

}
