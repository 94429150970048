import { HttpService } from './../services/http.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PoNotificationService } from '@po-ui/ng-components';

@Component({
  selector: 'app-tarefas-detalhes',
  templateUrl: './tarefas-detalhes.component.html',
  styleUrls: ['./tarefas-detalhes.component.css']
})
export class TarefasDetalhesComponent implements OnInit {

  tarefaatual;
  titulo;
  descricaotarefa;
  historico;
  observacao;
  datacriacao = new Date();
  pendencia;
  codigo;
  listaanexos;
  pathanexos;
  sessao;
  carregando;
  tarefa;
  topo;

  constructor(
    private notitificacao: PoNotificationService,
    private route: ActivatedRoute,
    private http: HttpService
  ) { }

  ngOnInit(): void { 
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    this.route.paramMap.subscribe(parameters => {
      this.tarefa = parameters.get('tarefa');
      this.listadetalhes();
    });
  }

  listadetalhes() {
    this.http.get('/tarefas/tarefa?tarefa=' + this.tarefa).subscribe(
      data => {
        const items = data;
        const item = items.items[0];
        this.tarefaatual = item;
        this.titulo = item.ordem + ' - ' + item.titulo;
        this.descricaotarefa = item.descricao;
        this.historico = item.historico;
        this.observacao = '';
        this.datacriacao = new Date();
        this.pendencia = false;
        this.codigo = item.codigo;
        this.listaanexos = item.anexos;
        this.pathanexos = item.pathanexos;
      });
  }

  salvacomentario() {

    const body = {
      tecnico: this.sessao.tecnico,
      observacao: this.observacao,
      pendencia: this.pendencia,
      datacriacao: this.datacriacao,
      topo: this.topo
    };
    this.carregando = true;
    this.http.post('/tarefas/setstatus?tarefa=' + this.tarefaatual.codigo  + '&status=' +
      this.tarefaatual.status , body).subscribe(
      data => {
        this.carregando = false;
        this.observacao = '';
        this.topo = false;
        this.pendencia = false;
        this.listadetalhes();
      },
      error => {
        this.carregando = false;
        this.notitificacao.error('Não foi possível gravar comentário, por favor tente novamente. ');
      }
    );
}

deletarhistorico(event) {

  console.log(event);

  this.carregando = true;
  this.http.delete('/tarefas/historico?tarefa=' + event.id).subscribe(
      data => {
        this.carregando = false;
        this.listadetalhes();
      },
      error => {
        this.carregando = false;
        this.notitificacao.error('Não foi possível excluir comentário, por favor tente novamente. ');
      }
    );

}

}
