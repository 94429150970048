<po-page-default > 
    
    <po-loading-overlay *ngIf="carregando"
        p-screen-lock="true"
        p-text="Carregando" >
    </po-loading-overlay>

    <div class="center top">
        <div class="po-visible-sm po-xl-0 po-lg-0 po-md-0 po-sm-2" id="btDetalhes" >
            <po-button
                p-auto-focus="false"
                (p-click)="toogleDetalhes()"
                p-icon="po-icon po-icon-document-filled"
                p-label=""
                p-small="true" >
            </po-button>
        </div>
        
        <po-widget p-no-border="true">            
            <div [ngStyle]="screenWidth > 481 ? {} : {
                'position': 'fixed',
                'z-index': '90',
                'overflow': 'auto',
                'background-color': '#ffffff',
                'width': '80vw',
                'height': '70vh',
                'border': '1px solid #fbfbfbf6',
                'box-shadow': '6px 6px 3px #0c9abe',
                'background-image': 'linear-gradient(180deg, #fff, #ddd 40%, #ccc)'
              }" *ngIf="showMe" class="po-xl-3 po-lg-3 po-md-3 po-sm-3 po-offset-sm-1">

                <po-divider 
                    p-label="Detalhes do Ticket"
                    class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                </po-divider>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text">
                        Ticket
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text-bold">
                        {{detalheticket.ticket}}
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text">
                        Criado em
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text-bold">
                        {{detalheticket.inicio}}
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text">
                        Cliente
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text-bold">
                        {{detalheticket.nomecliente}}
                    </div>
                </div>
                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text">
                        Usuário
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text-bold">
                        {{detalheticket.usuario}}
                    </div>
                </div>
                
                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text">
                        Status
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <po-tag 
                        [p-value]="detalheticket.status"
                        [p-color]="corstatus">
                    </po-tag>
                </div>

                <po-divider 
                    p-label="Informações do Ticket"
                    class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                </po-divider>

                <po-select
                    (p-change)="salvaralteracao(false)"    
                    p-label="Classificação"                    
                    p-auto-focus="false" 
                    p-required="false" 
                    class="po-md-6 po-sm-mb-6 po-mb-6 po-lg-mb-6" 
                    name="modulo"                     
                    p-placeholder="Não classificado"
                    [p-options]="classificacao" 
                    [(ngModel)]="classifica">  
                </po-select>

                <po-select
                    (p-change)="salvaralteracao(false)"
                    p-label="Status"                    
                    p-auto-focus="false" 
                    p-required="false" 
                    class="po-md-6 po-sm-mb-6 po-mb-6 po-lg-mb-6" 
                    name="status"                     
                    [p-options]="listastatus" 
                    [(ngModel)]="status">  
                </po-select>

                <po-select
                    (p-change)="salvaralteracao(false)"
                    p-label="Projeto"                    
                    p-auto-focus="false" 
                    p-required="false" 
                    class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" 
                    name="projeto"                     
                    p-placeholder="Projeto não definido"
                    [p-options]="listaprojetos" 
                    [(ngModel)]="projeto">  
                </po-select>

                <po-select
                    (p-change)="salvaralteracao(false)"
                    p-label="Tipo"                    
                    p-auto-focus="false" 
                    p-required="false" 
                    class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" 
                    name="tipo"                     
                    p-placeholder="Tipo não Definido"
                    [p-options]="tipos" 
                    [(ngModel)]="tipo">  
                </po-select>

                <po-select
                    (p-change)="salvaralteracao(false)"
                    p-label="Atribuído"                    
                    p-auto-focus="false" 
                    p-required="false" 
                    class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" 
                    name="analista"                     
                    p-placeholder="Analista não atribuído"
                    [p-options]="listaanalistas" 
                    [(ngModel)]="analista">  
                </po-select>

                <po-multiselect (p-change)="salvaralteracao(true)" multiselectp-auto-focus="true" p-required="false" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12"
                    name="contatossel" p-label="Contatos Adicionais" [p-options]="contatos" [(ngModel)]="contatossel">
                </po-multiselect>

                <!-- <div [hidden]="statusinterno!=='0'">
                    <po-divider 
                        p-label="Outras Ações"
                        class="po-mt-5 po-xl-12 po-lg-12 po-md-12 po-sm-12">
                    </po-divider>

                    <div class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12">
                        <po-button  
                            p-icon="po-icon po-icon-change"
                            p-type="secondary"
                            p-small="true"
                            p-label="Solicitar Aceite Comercial" 
                            (p-click)="aceiteComercial()"> 
                        </po-button>                
                    </div>
                </div> -->

                <po-divider 
                    p-label="Anexos do Ticket"
                    class="po-mt-5 po-xl-12 po-lg-12 po-md-12 po-sm-12">
                </po-divider>
    
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" *ngFor ="let arquivo of detalheticket.anexos">
                        
                    <div class="po-font-text-smaller po-xl-12 po-lg-12 po-md-12 po-sm-12 po-mt-1">
                        <a href="{{url + detalheticket.pathanexos + '/' + arquivo}}" target="_blank">
                            {{arquivo}} 
                        </a>
                    </div>           

                </div>

                <po-button  
                    p-icon="po-icon po-icon-attach"
                    p-type="link"
                    p-small="true"
                    p-label="Novo Anexo" 
                    (p-click)="novoAnexo()"> 
                </po-button>

            </div>

            <div class=" po-xl-9 po-lg-9 po-md-9 po-sm-12">
                <div class="po-ml-5">

                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12  po-font-subtitle">
                        {{detalheticket.assunto}}
                    </div>
 
                    <po-container
                    p-height="400"                              
                    p-no-border="true"
                    p-no-padding="true"
                    p-no-shadow="true"
                    class="po-ml-1 po-font-text po-xl-12 po-lg-12 po-md-12 po-sm-12">
                        <div [innerHtml]="detalheticket.descricao">
                        </div>
                    </po-container>

                    <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12" >
                    </po-divider>

                    <po-checkbox class="po-xl-12 po-lg-12 po-md-12 po-sm-12"
                        [(ngModel)]="interno"                         
                        name="interno" 
                        p-label="Comentário apenas interno"> 
                    </po-checkbox>

                    <po-rich-text class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  
                        p-clean p-placeholder="Inserir Comentário" 
                        name="comentario" 
                        [(ngModel)]="comentario" 
                        p-height="170"> 
                    </po-rich-text>                              
            
                    <div class="salvar po-xl-3 po-lg-3 po-md-3 po-sm-3">
                        <po-button 
                            [p-disabled]="comentario"
                            p-icon="po-icon po-icon-sms"
                            p-type="primary"
                            p-small="true"
                            p-label="Salvar Comentário" 
                            (p-click)="setDuracao()"> 
                        </po-button>
                    </div>
                    
                    <po-container *ngFor ="let seq of sequencias; let i = index"
                        p-no-border="true"    
                        [class]="seq.interno ? 'background_interno po-xl-12 po-lg-12 po-md-12 po-sm-12' : i % 2 === 0 ? 'background_par po-xl-12 po-lg-12 po-md-12 po-sm-12' : 'po-xl-12 po-lg-12 po-md-12 po-sm-12' "> 
                                                
                        <div class="po-xl-3 po-lg-3 po-md-3 po-sm-5 ">

                            <po-avatar 
                                p-size="sm"
                                p-src="{{seq.suporte ? 'assets/imgs/suporte.png' : 'assets/imgs/user.png'}}">                        
                            </po-avatar>                        

                            <div class="po-font-text-bold ">
                                {{seq.usuario}}
                            </div>
                                
                            <div class="po-font-text-smaller ">
                                {{seq.data + ' - ' + seq.hora  }}
                            </div>                        

                        </div>
                        
                        <div [innerHtml]="seq.descricao" class="po-xl-9 po-lg-9 po-md-9 po-sm-7" >
                        </div>

                    </po-container>
            
                </div>

            </div>

        </po-widget>
    </div>

    
</po-page-default>

<po-modal #poModalAnexo
    p-title="{{'Anexos do Ticket | ' + ticket}}" 
    p-hide-close="true">
                
    <app-anexos 
        [chave]="detalheticket.ticket" 
        [arquivos]="detalheticket.anexos" 
        diretorio="tickets" 
        [pathanexos]="detalheticket.pathanexos" 
        [anexoativo]="true">
    </app-anexos>                

</po-modal>

<po-modal #poModalDuracao
    [p-primary-action]="confirm" [p-secondary-action]="close"
    p-title="{{'Atendimento do Ticket | ' + ticket}}" 
    p-hide-close="true">
                 
    <po-select        
        p-label="Classificação"                            
        p-required="false" 
        class="po-xl-6 po-lg-6 po-md-6 po-sm-6" 
        name="modulo"                     
        p-placeholder="Não classificado"
        [p-options]="classificacao" 
        [(ngModel)]="classifica">  
    </po-select>

    <po-select        
        p-label="Status"                            
        p-required="false" 
        class="po-xl-6 po-lg-6 po-md-6 po-sm-6" 
        name="status"                     
        [p-options]="listastatus" 
        [(ngModel)]="status">  
    </po-select>

    <po-select        
        p-label="Atribuído"                    
        p-auto-focus="false" 
        p-required="false" 
        class="po-md-10 po-sm-mb-10 po-mb-10 po-lg-mb-10" 
        name="analista"                     
        p-placeholder="Analista não atribuído"
        [p-options]="listaanalistas" 
        [(ngModel)]="analista">  
    </po-select>

    <po-input [hidden]="geraros"
        p-auto-focus="true" 
        p-mask-format-model="true" 
        p-placeholder="00:00" 
        p-required="true" 
        p-error-pattern="Hora Inválida" 
        p-mask="99:99" 
        p-maxlength=5 
        p-minlength=5 
        class="po-xl-2 po-lg-2 po-md-2 po-sm-2" 
        name="duracao"  
        p-label="Duração"
        [(ngModel)]="duracao" >
    </po-input>      

    
    <po-checkbox class="po-xl-4 po-lg-4 po-md-4 po-sm-4"
        [(ngModel)]="geraros"                         
        name="geraros" 
        p-label="Gerar OS"> 
    </po-checkbox>

    <div [hidden]="!geraros">
    
        <po-divider 
            p-label="Ordem de serviço"
            class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
        </po-divider>

    <po-datepicker  class="po-xl-4 po-lg-4 po-md-4 po-sm-4"  name="dataos" p-label="Data" [(ngModel)]="dataos"></po-datepicker>                                 

        <po-input  
            p-auto-focus="true" 
            p-mask-format-model="true" 
            p-placeholder="00:00"              
            p-error-pattern="Hora Inválida" 
            p-mask="99:99" 
            p-maxlength=5 
            p-minlength=5 
            class="po-xl-3 po-lg-3 po-md-3 po-sm-3" 
            name="inicio"  
            p-label="Hora Inicial"
            [(ngModel)]="inicio" >
        </po-input>  

        <po-input  
            p-auto-focus="true" 
            p-mask-format-model="true" 
            p-placeholder="00:00"              
            p-error-pattern="Hora Inválida" 
            p-mask="99:99" 
            p-maxlength=5 
            p-minlength=5 
            class="po-xl-3 po-lg-3 po-md-3 po-sm-3" 
            name="final"  
            p-label="Hora Final"
            [(ngModel)]="final" >
        </po-input>           

        <po-input  
            p-auto-focus="true" 
            p-mask-format-model="true" 
            p-placeholder="00:00"              
            p-error-pattern="Hora Inválida" 
            p-mask="99:99" 
            p-maxlength=5 
            p-minlength=5 
            class="po-xl-2 po-lg-2 po-md-2 po-sm-2" 
            name="intervalo"  
            p-label="Intervalo"
            [(ngModel)]="intervalo" >
        </po-input>           
        
        <po-input  
            p-placeholder="Atividade"              
            class="po-xl-6 po-lg-6 po-md-6 po-sm-6" 
            name="atividade"  
            p-label="Atividade"
            [(ngModel)]="atividade" >
        </po-input>  

        <po-input  
            p-placeholder="Sub atividade"              
            class="po-xl-6 po-lg-6 po-md-6 po-sm-6" 
            name="subatividade"  
            p-label="Sub Atividade"
            [(ngModel)]="subatividade" >
        </po-input>  


        <!-- <po-textarea  
            class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  
            p-required="true"     
            p-clean 
            p-label="Laudo OS"
            p-placeholder="Preencha o laudo para geração da OS" 
            name="laudo" 
            [(ngModel)]="laudo" 
            p-rows="8">
        </po-textarea>    -->

    </div>
 

</po-modal>


<po-modal #poModalAceite
    [p-primary-action]="confirmAceite" [p-secondary-action]="closeAceite"
    p-title="Solicitar Aceite Comercial" 
    p-hide-close="true">

    <po-textarea  
        p-placeholder="Justifique a sua solicitação" 
        p-required="false" 
        [(ngModel)]="textoaceite" 
        class="po-md-12" 
        name="textoaceite"  
        p-label="Justificativa:" 
        p-rows="4">
    </po-textarea>    
</po-modal>