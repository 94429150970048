import { element } from 'protractor';
import { PoPageDynamicSearchModule } from '@po-ui/ng-templates';
import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { PoNotificationService, PoTableColumn, PoPageAction, PoTableAction, PoModalComponent, PoModalAction, 
  PoMultiselectOption, PoRadioGroupOption } from '@po-ui/ng-components';
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-projetos',
  templateUrl: './projetos.component.html',
  styleUrls: ['./projetos.component.css']
})
export class ProjetosComponent extends BaseComponent implements OnInit {

  @ViewChild('poModal1') poModal1: PoModalComponent;
  @ViewChild('poModal2') poModal2: PoModalComponent;
  @ViewChild('optionsForm', { static: true }) form: NgForm;

  constructor(
    private notitificacao: PoNotificationService,
    public httpService: HttpService,
    private router: Router) {
    super(httpService, '/projetos/', '' );
    }

    excluir;
    clientesel;
    listaclientes = [];
    projetosel;
    listaprojetos = [];
    listamembros = [];
    membrossel;
    multiselect;
    descricao;
    datainicio;
    datafim;
    previsto;
    editando;
    codprojeto;
    statussel;
    prioridade;
    observacao;
    listahistorico;

    status = [
      { value: '0', label: 'Parado' },
      { value: '1', label: 'Em andamento' },
      { value: '2', label: 'Finalizado' },
      { value: '3', label: 'Aguardando Cliente' },
    ];

    prioridades: Array<PoRadioGroupOption> =  [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' }];

    tableActions: Array<PoTableAction> = [
      {label: 'Editar'    , action: this.editar.bind(this), icon: 'po-icon po-icon-edit' },
      // {label: 'Histórico' , action: this.historico.bind(this), icon: 'po-icon po-icon-history' }
    ];

    options: Array<PoMultiselectOption> = [
      { value: 'poMultiselect1', label: 'PO Multiselect 1' },
      { value: 'poMultiselect2', label: 'PO Multiselect 2' }
    ];

    close: PoModalAction = {
      action: () => {
        this.poModal1.close();
      },
      label: 'Cancelar',
    };

    confirm: PoModalAction = {
      action: () => {
          this.salvarprojeto();
      },
      label: 'Salvar'
    };

    pageactions: Array<PoPageAction> = [
      {label: 'Novo Projeto'  , action: this.novoprojeto.bind(this)},
      {label: 'Visão geral'   , action: this.visaogeral.bind(this)},
      {label: 'Minhas Tarefas', action: this.minhastarefas.bind(this)},
    ];

    filters;

    async ngOnInit() {

      let auxfiltros = [];

      let initstatus;
      let initanalista;
      let initcliente;

      this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));

      if (localStorage.getItem('filtrosprojetos')) {
         auxfiltros = JSON.parse(localStorage.getItem('filtrosprojetos'));
         auxfiltros.forEach(element => {
           if (element.property === 'status') {
              initstatus = element.value;
           } else if (element.property === 'analista') {
              initanalista = element.value;
           } else if (element.property === 'cliente') {
              initcliente = element.value;
           }
         });
      }

      this.carregando = true;
      await this.getClientes();
      await this.getAnalistas();

      this.filters = [
        { property: 'status'  , label: 'Status'  , gridColumns: 6, options: this.status, optionsMulti: true,
          initValue: initstatus},
        { property: 'analista', label: 'Analista', gridColumns: 6, options: this.listamembros, optionsMulti: false,
          initValue: initanalista},
        { property: 'cliente' , label: 'Cliente' , gridColumns: 12, options: this.listaclientes, optionsMulti: false, 
          initValue: initcliente},
      ];

      this.colunas = this.getColumns();

      if (auxfiltros.length === 0) {
        this.get();
      } else {
        this.carregando = false;
      }

    }

    changeFilter(disclaimer) {

      let filtroaux = '';
      let valor;
      console.log(disclaimer);

      disclaimer.forEach(fil => {
        if (Array.isArray(fil.value)) {
          valor = '';
          fil.value.forEach(e => {
            valor += e + ',';
          });
        } else {
          valor = fil.value;
        }
        filtroaux += '&' + fil.property + '=' + valor;
      });

      localStorage.setItem('filtrosprojetos', JSON.stringify(disclaimer));

      this.filtros = filtroaux;
      this.get();
    }

    buscaavancada(filter) {
      let disclaimer = [];

      console.log(filter);

      if (filter.status) {
        disclaimer.push({property: 'status', value: filter.status});
      }
      if (filter.analista) {
        disclaimer.push({property: 'analista', value: filter.analista});
      }
      if (filter.cliente) {
        disclaimer.push({property: 'cliente', value: filter.cliente});
      }

      this.changeFilter(disclaimer);

    }

    getColumns(): Array<PoTableColumn> {
      return [
        { property: 'codigo', label: 'Código'   },
        { property: 'descricao', label: 'Descrição' },
        { property: 'observacao', label: 'Observação', visible: false, color: (row, column) => this.corcoluna(row) },
        { property: 'prioridade', label: 'Prioridade', visible: false },
        { property: 'cliente', label: 'Cliente'},
        { property: 'inicioprevisto', label: 'Inicio(P)'},
        { property: 'fimprevisto', label: 'Fim(P)'},
        { property: 'horasprevisto', label: 'Horas(P)'},
        { property: 'horasrealizado', label: 'Horas(R)'},
        {
          property: 'status',
          type: 'label',
          width: '10%',
          labels: [
            { value: '0'       , color: 'color-07', label: 'Parado'},
            { value: '1'       , color: 'color-10', label: 'Em andamento'},
            { value: '2'       , color: 'color-03', label: 'Finalizado'},
            { value: '3'       , color: 'color-08', label: 'Aguardando Cliente'},
          ]
        },
        { property: 'spi', label: 'SPI'},
        { property: 'tarefasqtdgrid', label: 'Tarefas'},
        {
          property: 'acoes',
          label: 'Ações',
          type: 'icon',
          icons: [
            {
              action: this.tarefas.bind(this),
              icon: 'po-icon po-icon-list',
              tooltip: 'Tarefas',
              value: 'tarefas',
            },
            {
              action: this.cronograma.bind(this),
              icon: 'po-icon po-icon-calendar',
              tooltip: 'Cronograma',
              value: 'cronograma',
            },
            {
              action: this.eventos.bind(this),
              icon: 'po-icon po-icon-archive',
              tooltip: 'Eventos',
              value: 'eventos',
            },
            {
              action: this.statusreport.bind(this),
              icon: 'po-icon po-icon-chart-columns',
              tooltip: 'Status Report',
              value: 'statusreport',
            },
          ]
        },
      ];
    }

    novoprojeto(linha) {

      if (linha === undefined) {
        this.form.reset();
      }

      this.editando = false;
      this.poModal1.open();

    }

    async getClientes() {

      return new Promise((resolve, reject) => {
        this.httpService.get('/agendasagisrn/' + this.sessao.tecnico + '/clientes').subscribe(
          async data => {
            this.listaclientes = [];
            data.items.forEach(element => {
                this.listaclientes.push({label: element.nomecliente, value: element.codigo});
            });
            resolve(true);
          },
          error => {
            reject();
          },
          () => {
          }
        );
      });


    }

    async getAnalistas() {
      return new Promise((resolve, reject) => {
        this.httpService.get('/projetos/tarefas/' + this.sessao.tecnico + '/analistas').subscribe(
          data => {
            this.listamembros = [];
            data.items.forEach(element => {
                this.listamembros.push({label: element.nome, value: element.codigo});
            });
            resolve(true);
          },
          error => {
            reject();
          },
          () => {
          }
        );
      });
    }

    async getprojetoscliente(cliente)  {
      this.listaprojetos = [];
      this.httpService.get('/projetos/projetoscliente/' + this.sessao.tecnico + '/projetoscliente?cliente=' + cliente).subscribe(
        data => {
          this.listaprojetos = [];
          data.items.forEach(element => {
            this.listaprojetos.push({label: element.descricao, value: element.codigo});
          });
        },
        error => {
          this.carregando = false;
        },
        () => {
          this.carregando = false;
          this.poModal1.open();
        }
      );
    }

    salvarprojeto() {
      if (this.form.invalid) {
        this.notitificacao.error('Selecione o cliente e o projeto');
      } else {
        this.confirm.loading = true;
        const acao = this.editando ? 'editar' : 'novo';
        this.httpService.post(this.endpoint + this.sessao.tecnico + '/?projeto=' + acao , this.form.value).subscribe(
          data => {
            console.log(this.filter);
            this.confirm.loading = false;
            this.poModal1.close();
            this.get();
          },
          error => {
            this.confirm.loading = false;
            this.notitificacao.error('Erro ao tentar gravar projeto');
          },
          () => {
            this.confirm.loading = false;
            this.form.reset();
          }
         );
        }
    }

    async editar(linha) {
      this.codprojeto = linha.codigo;
      this.descricao = linha.descricao;
      this.clientesel = linha.codcliente;
      this.projetosel = linha.codsubprojeto;
      this.previsto = linha.horasprevisto;
      this.datainicio = linha.dtiniprev;
      this.datafim = linha.dtfimprev;
      this.statussel = linha.status;
      this.observacao = linha.observacao;
      this.prioridade = linha.prioridade;
      this.membrossel = linha.membros;
      await this.getprojetoscliente(linha.codcliente);
      this.novoprojeto(linha);
      this.editando = true;
    }

    cronograma(row) {
      this.router.navigate(['/cronograma', row.codigo]);
    }

    tarefas(row) {
      this.router.navigate(['/tarefas', row.codigo]);
    }

    eventos(row) {
      this.router.navigate(['/eventos', row.codigo]);
    }

    statusreport(row) {
      this.router.navigate(['/statusreport', row.codigo]);
    }

    minhastarefas() {
      this.router.navigate(['/tarefasanalista']);
    }

    visaogeral() {
      this.router.navigate(['/visaogeral']);
    }

    corcoluna(linha) {
      if (linha.prioridade === '1') {
        return 'color-07';
      } else if (linha.prioridade === '2') {
        return 'color-08';
      }
      return '';
    }

    historico(linha) {
      //this.listahistorico = linha.historico;
      
      this.httpService.post("/projetos/historico?projeto="+linha.codigo+"&tecnico=" + this.sessao.tecnico, 
      {
        "orderby": "C",
        "tamanhopagina" : 0
      }).subscribe(
        data => {
          const response: any = data;
          
          if( response.code === "400")
          {
            this.listahistorico = []
            this.notitificacao.error(response.detail);
          }else{
            this.listahistorico = response.items;
          }
          this.carregando = false;
          //this.mostrarBtnCarregar = true;
        },
        error => {
          this.listahistorico = [];
          this.notitificacao.error("Erro durante o processo de filtro");
          this.carregando = false;
          //this.mostrarBtnCarregar = true;
        }
      )
      
      this.poModal2.open();

    }

}

