import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { PoMultiselectOption } from '@po-ui/ng-components';
@Component({
  selector: 'app-bc',
  templateUrl: './bc.component.html',
  styleUrls: ['./bc.component.css']
})
export class BcComponent implements OnInit {

  tipo: Array<PoMultiselectOption> = [
    { value: 'C', label: 'Cliente' },
    { value: 'I', label: 'Interno' }
  ];

  tipopesquisa: Array<PoMultiselectOption> = [
    { value: 'A', label: 'Aproximada' },
    { value: 'E', label: 'Exata' }
  ];

  bc: any;
  datade: any;
  dataate: any;
  carregando: boolean;
  pesquisasel: string;
  buscar: string;
  tiposel;
  totalpaginas = 0;
  paginaatual = 1;
  tampagina = 12;

  constructor(
    public httpService: HttpService,
  ) { }

  ngOnInit() {
    this.carregando = false;
    this.pesquisasel = 'A';
    this.tiposel = ['C'];
    this.bc = [];
  }

  getbc(carregarmais: boolean) {

    console.log(this.bc.length);
    console.log(this.totalpaginas);
    console.log(this.paginaatual + 1);

    if (carregarmais) {
      this.paginaatual++;
    } else {
      this.paginaatual = 1;
      this.totalpaginas = 0;
    }

    let endpoint = '/agendasagisrn/tec/bc?pagina=' + this.paginaatual + '&tampagina=' + this.tampagina;

    if (this.tiposel.length > 1) {
      endpoint +=  '&tipo=A';
    } else {
      endpoint +=  '&tipo=' + this.tiposel[0];
    }

    endpoint += '&tppesq='   + this.pesquisasel;
    endpoint += '&pesquisa=' + this.buscar;

    if (this.datade && this.dataate) {
      endpoint += '&datade=' + this.datade.replace(/-/g, '');
      endpoint += '&dataate=' + this.dataate.replace(/-/g, '');
    }

    this.carregando = true;
    this.httpService.get(endpoint).subscribe(
      data => {
        this.totalpaginas = data.total / this.tampagina;
        this.totalpaginas = Math.round(this.totalpaginas);
        if (carregarmais) {
          data.items.forEach(element => {
            this.bc.push(element);
          });
        } else {
          this.bc = data.items;
        }
      },
      error => {
        this.bc = [];
        this.carregando = false;
      },
      () => {
        this.carregando = false;
      }
    );
  }
}
