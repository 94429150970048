<div class="po-wrapper">
    <po-toolbar p-title="Últimas atividades de seus projetos - detalhes"> 
    </po-toolbar>
    
    <po-page-default p-title="Últimas Atividades">
        <!--FILTRO-->
        <form #optionsForm="ngForm">
            <po-widget class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-title="Filtro das últimas atividades">
                <po-select p-auto-focus="true" p-required="true" class="po-md-3 po-sm-mb-3 po-mb-3 po-lg-mb-3" name="projeto"  p-label="Projetos"  [(ngModel)]="projetosel" [p-options]="projetos"></po-select>
                <po-select p-required="true" class="po-md-3 po-sm-mb-3 po-mb-3 po-lg-mb-3" name="tecnico"  p-label="Tarefas Realizadas pelo Analista"  [(ngModel)]="analistassel" [p-options]="analistas"></po-select>
                <po-select p-required="true" class="po-md-3 po-sm-mb-3 po-mb-3 po-lg-mb-3" name="cliente"  p-label="Cliente"  [(ngModel)]="clientesel" [p-options]="clientes"></po-select>
                <po-datepicker class="po-md-3 po-sm-mb-3 po-mb-3 po-lg-mb-3" name="dgravacaotarefa" p-label="Data de Gravação da Tarefa"  [(ngModel)]="dtgrav" > </po-datepicker>
            </po-widget> 
            <po-button p-type="primary" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-label="Filtrar" (p-click)="SubmitFiltro()"> </po-button>     
        </form>

        <po-divider *ngIf="listahistorico.length>0" class="po-md-10 po-sm-mb-10 po-mb-12 po-lg-mb-10" p-label="Resultado do Filtro"></po-divider>
        
        <!--Componente da Lista das Tarefas-->
        <div class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12">
            <po-widget 
            [hidden]="listahistorico.length === 0"
            style="position: relative;"
            class="po-xl-8 po-lg-12 po-md-12 po-sm-12"
            p-title="Últimas atividades de seus projetos">
            <div *ngFor ="let hist of listahistorico" >
                <po-tag class="po-xl-12 po-lg-12 po-md-12 po-sm-12" p-color="color-01" p-value="{{hist.descprojeto}}"> </po-tag>
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" [innerHtml]="hist.descricao" >            
                </div>
                <po-container *ngIf="hist.comentario" p-no-border="true">            
                    <div class="po-font-text-small po-xl-12 po-lg-12 po-md-12 po-sm-12" [innerHtml]="hist.comentario">
                    </div>        
                </po-container>
                <div class="po-font-text-smaller po-offset-lg-11 po-offset-xl-11 po-xl-1 po-lg-1 po-md-12 po-sm-12">{{hist.data + ' ' + hist.hora.substring(0,5)}}</div>
                <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12"></po-divider>
            </div>
            </po-widget>
       
        </div> 
        
        <po-loading-overlay *ngIf="carregando"
            [p-screen-lock]="false"
            p-text="Buscando dados" >
        </po-loading-overlay>
        
        <div *ngIf="listahistorico.length > 9 && mostrarBtnCarregar " class="po-offset-lg-5 po-offset-xl-5 po-xl-5 po-lg-5 po-md-5 po-sm-12">
            <po-button p-label="Carregar mais resultados" (p-click)="CarregarMais()"> </po-button>                            
        </div>
    </po-page-default>
</div>