import { HttpService } from './../services/http.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PoPageAction, PoModalComponent, PoModalAction, PoTableColumn, PoTableAction } from '@po-ui/ng-components';
import { PoStorageService } from '@po-ui/ng-storage';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-agendamentos-consulta',
  templateUrl: './agendamentos-consulta.component.html',
  styleUrls: ['./agendamentos-consulta.component.css']
})
export class AgendamentosConsultaComponent implements OnInit {

  constructor(
    private http: HttpService, private storageService: PoStorageService
  ) { }
  
  @ViewChild(PoModalComponent, { static: true }) poModal: PoModalComponent;
  @ViewChild('optionsForm', { static: true }) form: NgForm;
  @ViewChild('buttonHover', { read: ElementRef, static: true }) buttonHoverRef: ElementRef;
  
  analistasselecionados = [];
  sessao;
  oculto = false;
  cadastraFiltro = false;
  analistas = [];
  clientes = [];
  cliente = '';
  dias;
  descricaoFiltro = '';
  diainicio;
  diafinal;
  todasasagendas = [];
  filtros = [];
  colunas ;
  edita = true;
  gerandoos = false;

  tableActions: Array<PoTableAction> = [
    {label: 'Editar' , action: this.editar_filtro.bind(this), icon: 'po-icon po-icon-edit' },
    {label: 'Deletar' , action: this.deletar_filtro.bind(this), icon: 'po-icon po-icon-delete'},
  ];

  close: PoModalAction = {
    action: () => {
      this.poModal.close();
    },
    label: 'Cancelar',
  };

  confirm: PoModalAction = {
    action: () => {
      this.salvar();
    },
    label: 'Confirmar'
  };

  public readonly actions: Array<PoPageAction> = [
    {
      label: 'Ocultar',
      action: this.ocultar.bind(this),
      icon: 'po-icon po-icon-eye-off',
    },
    {
      label: 'Adicionar Filtro',
      action: this.adicionar_filtro.bind(this),
      icon: 'po-icon po-icon-plus-circle',
    },
    {
      label: 'Filtros',
      action: this.selecionar_filtro.bind(this),
      icon: 'po-icon po-icon-filter'
    }
  ];

  async ngOnInit() {
    let filtros;

    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));

    await this.getAnalistas();
    if (localStorage.getItem('filtro_agenda')) {
      filtros = JSON.parse(localStorage.getItem('filtro_agenda'));
      this.diainicio = filtros.data_inicio;
      this.diafinal = filtros.data_fim;
      this.analistasselecionados = filtros.selecionados;
      this.cliente = filtros.cliente;
    }
    await this.getClientes();
    this.colunas = this.getColumns();
    this.getFiltros();
    this.montadias();
  }

  async getAnalistas() {
    return new Promise((resolve, reject) => {
      this.http.get('/projetos/tarefas/' + this.sessao.tecnico + '/analistas').subscribe(
        data => {
          this.analistas = [];
          data.items.forEach(element => {
              this.analistas.push({label: element.nome, value: element.codigo, cor: '#ff0000'});
          });
          resolve(true);
        },
        error => {
          reject();
        },
        () => {
        }
      );
    });
  }

  async getAgendas() {

    let filtro = '';

    filtro = '?datade=' + this.diainicio.replace(/-/g, '');
    filtro += '&dataate=' + this.diafinal.replace(/-/g, '');
    filtro += '&tecnicos=' + this.diafinal.replace(/-/g, '');

    let tecnicos = '';

    console.log(this.analistasselecionados);

    this.analistasselecionados.forEach(element => {
      tecnicos += element + ',';
    });

    filtro += '&tecnicos=' + tecnicos;
    // filtro.concat('&cliente=' + this.cliente)
    // console.log(filtro)
    return new Promise((resolve, reject) => {
      this.http.get('/agendasagisrn/' + this.sessao.tecnico + filtro.concat('&cliente=' + this.cliente)).subscribe(
        data => {
          this.todasasagendas = data.items;
          resolve(true);
        },
        error => {
          reject();
        },
        () => {
        }
      );
    });
  }

  async montadias(multiselect = '') {

    let diaatual;
    let dataatual;
    let agendasdodia = [];
    this.dias = [];
    this.descricaoFiltro = ( multiselect === '' ? this.descricaoFiltro : '' );
    
    if (!this.diainicio || !this.diafinal || ( this.analistasselecionados.length === 0 && !this.cliente) ) {
      return;
    }

    await this.getAgendas();

    const dtinicio = new Date();
    const dtfinal = new Date();

    let dtaux = this.diainicio.split('-');

    dtinicio.setFullYear(Number(dtaux[0]), Number(dtaux[1] - 1), Number(dtaux[2]));

    if (dtinicio.getDay() !== 1) {
      while (dtinicio.getDay() !== 1)  {
        dtinicio.setDate(dtinicio.getDate() - 1);
      }
    }

    dtaux = this.diafinal.split('-');
    dtfinal.setFullYear(Number(dtaux[0]), Number(dtaux[1] - 1), Number(dtaux[2]));

    const optiondia1 = { weekday: 'short'};
    const optiondia2 = { day: '2-digit',  month: '2-digit'};

    const locale = 'pt-br';

    while (dtinicio <= dtfinal) {

      agendasdodia = [];

      dtaux = dtinicio.toISOString().slice(0, 10).split('-');
      dataatual = dtaux[2] + '/' + dtaux[1] + '/' + dtaux[0].slice(2, 4);

      this.todasasagendas.findIndex((item) => {        
        if (item.data === dataatual) {
          agendasdodia.push(item);
        }
      });
      
      diaatual = {
        diasemana: dtinicio.toLocaleDateString(locale, optiondia1).toUpperCase(),
        dia: dtinicio.toLocaleDateString(locale, optiondia2),
        agendas: agendasdodia
      };

      this.dias.push(diaatual);
      dtinicio.setDate(dtinicio.getDate() + 1);

    }

    const filtroAgenda = {
      data_inicio: this.diainicio,
      data_fim: this.diafinal,
      selecionados: this.analistasselecionados,
      cliente: this.cliente
    };

    localStorage.setItem('filtro_agenda', JSON.stringify(filtroAgenda));

  }

  pulalinha(i: number) {
    return ( (i + 1) % 7 === 0 ? true : false);
  }

  saboudom(dia: any) {
    return ( dia.diasemana === 'SÁB.' || dia.diasemana === 'DOM.' ? true : false);
  }

  hoje(dia: any) {
    const locale = 'pt-br';
    const hoje = new Date();
    const optiondia2 = { day: '2-digit',  month: '2-digit'};
    return  dia === hoje.toLocaleDateString(locale, optiondia2);
  }

  async getClientes() {

    return new Promise((resolve, reject) => {
      this.http.get('/agendasagisrn/' + this.sessao.tecnico + '/clientes').subscribe(
        async data => {
          this.clientes = [];
          data.items.forEach(element => {
              this.clientes.push({label: element.nomecliente, value: element.codigo});
          });
          this.clientes.push({label: 'Todos', value: ' '});
          resolve(true);
        },
        error => {
          reject();
        },
        () => {
        }
      );
    });
  }

  ocultar() {
    this.oculto = !this.oculto;
  }

  adicionar_filtro() {
    this.descricaoFiltro = '';
    this.cadastraFiltro = true;
    this.edita = false;
    this.poModal.open();
  }

  salvar() {
    this.getAnalistas();

    if (!this.cadastraFiltro) {
      this.poModal.close();
    } else {
      const filtro = [{
        descricao: this.descricaoFiltro,
        selecionados: this.analistasselecionados
      }];
      const descricao = this.descricaoFiltro;
      this.storageService.exists('filtros').then((response) => {
        if (!response) {
          this.storageService.set('filtros', filtro).then(() => {});
        } else {
          this.storageService.removeItemFromArray('filtros', 'descricao', descricao).then(resp => {
            this.storageService.appendArrayToArray('filtros', filtro).then(() => {});
          }).catch(resp => { });
        }
      });
      this.poModal.close();
    }
    this.montadias();
  }

  selecionar_filtro() {
    this.edita = true;
    this.cadastraFiltro = false;
    this.getFiltros();
    this.poModal.open();
  }

  seleciona_grupo(linha) {
    this.analistasselecionados = [];
    this.analistasselecionados = linha.selecionados;
    this.descricaoFiltro = linha.descricao;

    this.filtros.forEach(item => {
      if (linha.descricao !== item.descricao) {
        item.$selected = false;
      }
    });
  }

  getFiltros() {
    this.storageService.get('filtros').then((response) => { this.filtros = response; });
  }

  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'descricao', label: 'Filtro'   }
    ];
  }

  limpa_selecionados() {
    this.analistasselecionados = [];
  }

  deletar_filtro(linha) {
    this.storageService.removeItemFromArray('filtros', 'descricao', linha.descricao).then(resp => {
      this.getFiltros();
    }).catch(resp => { });
  }

  editar_filtro(linha) {
    this.descricaoFiltro = linha.descricao;
    this.analistasselecionados = linha.selecionados;
    this.cadastraFiltro = true;
  }

  /**
   * @author Iury Lamonie
   * Imprime a OS de uma agenda.
   * @param agenda Agenda na qual a OS está vinculada
   */
  imprimir(agenda) {
    console.log(agenda)
    if (agenda.chamado) {

      this.gerandoos = true;

      this.http.get('/impressaoagisrn?chamado=' + agenda.chamado.nrchamado).subscribe(
        data => {
            console.log(data);
            this.gerandoos = false;
            window.open(data.link, '_blank');
        },
        error => {
          this.gerandoos = false;
        },
        () => {
          this.gerandoos = false;
        }
      );
    }

  }

}
