import { Router } from '@angular/router';
import { PoMultiselectOption, PoNotificationService, PoUploadComponent } from '@po-ui/ng-components';
import { HttpService } from './../services/http.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-novo-ticket',
  templateUrl: './novo-ticket.component.html',
  styleUrls: ['./novo-ticket.component.css']
})
export class NovoTicketComponent implements OnInit {

  @ViewChild('passwordForm', { read: NgForm, static: true }) passwordForm: NgForm;
  @ViewChild('upload', { static: true }) upload: PoUploadComponent;
  
  assunto;
  descricao;
  cliente;
  usuario;
  sessao;
  solicitante;

  chave: string;
  arquivos: string[];
  anexoativo: boolean;

  url = environment.urlHttp;
  urlupload = environment.urlApi + '/nyxupload';
  
  project: Array<any> = [];

  modulos: Array<PoMultiselectOption> = [
    { value: 'SIGATMK', label: 'Call Center'},
    { value: 'SIGACOM', label: 'Compras'},
    { value: 'SIGACTB', label: 'Contabilidade'},
    { value: 'SIGAEST', label: 'Estoque'},
    { value: 'SIGAFAT', label: 'Faturamento'},
    { value: 'SIGAFIN', label: 'Financeiro'},
    { value: 'SIGAFIS', label: 'Fiscal'},
    { value: 'SIGAPON', label: 'Ponto Eletrônico'},
    { value: 'SIGAGPE', label: 'RH'},
    { value: 'OUTROS' , label: 'Outros'},  
  ];

  listaclientes = [];
  listausuarios = [];

  modulo = '';

  constructor(
      private http: HttpService,
      private poNotifica: PoNotificationService,
      private rota: Router,
      private httpService: HttpService
    ) { }

  async ngOnInit() {
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    await this.getClientes()
  }

  antesdeupar(event): void {
    console.log('evento', event);
    console.log(this.project);
  }

  uparAnexos(chave): void {

    for (let index = 0; index < this.project.length; index++) {
      const element = this.project[index];
      const file = element.rawFile;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          const arquivo = element.name;
          const body = {
            base64: reader.result, extension: element.extension, 
            name: arquivo, diretorio: 'tickets', chave: chave};
          this.http.post('/nyxupload/', body).subscribe(
            data => {
              if (index === this.project.length) {
                this.rota.navigate(['/tikets'])
              }
            }
          );
      };      
    }

    this.upload.sendFiles();
    this.upload.clear();
  }

    async getClientes() {    
      return new Promise((resolve, reject) => {
        this.httpService.get('/agendasagisrn/' + this.sessao.tecnico + '/clientes').subscribe(
          async data => {
            this.listaclientes = [];
            data.items.forEach(element => {
                this.listaclientes.push({label: element.nomecliente, value: element.codigo});
            });
            resolve(true);
          },
          error => {
            reject();
          },
          () => {
          }
        );
      });

    }

  salvarTicket(): void {
    console.log(this.passwordForm.value)
    
    this.http.post('/atendimento/ticket?analista='+this.sessao.tecnico + '&ticket=z' , this.passwordForm.value).subscribe(
      data => {
        const ret: any = data;
        this.poNotifica.success(ret.message);
        this.passwordForm.resetForm();       
        this.uparAnexos(ret.ticket);
        this.descricao = '';
        if (this.project.length === 0) {
          this.rota.navigate(['/tickets'])
        }
      },
      error => {
        const ret: any = error;
        this.poNotifica.error(error.message);
      }
    );

  }

  getUsuariosCliente(cliente) {
    this.listausuarios = []
    this.usuario = '';
    this.httpService.get('/atendimento/usuarios?cliente='+cliente).subscribe(
      data => {
        this.listausuarios = [];
        data.items.forEach(element => {
            this.listausuarios.push({label: element.nome, value: element.id});
        });
      },
      error => {
      }
    )};
  

}
