import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private router: Router ) { }

  // tslint:disable-next-line: max-line-length
  headers = new HttpHeaders().set('tenantId', 'userfunction');

  get(path: string): Observable<any> {    
    return this.http.get<[]>(environment.urlApi + path, { headers: this.headers }).pipe(
      catchError(this.handleError<[]>('get', path))
    );
  }

  post(path: string, body: any): Observable<[]> {    
    return this.http.post<[]>(environment.urlApi + path, body,  { headers: this.headers } ).pipe(
      catchError(this.handleError<[]>('post', path))
    );
  }

  delete(path: string): Observable<[]> {    
    return this.http.delete<[]>(environment.urlApi + path, {headers: this.headers }).pipe(
      catchError(this.handleError<[]>('delete', path))
    );
  }

  private handleError<T>(operation = 'operation', path, result?: T) {
    return (error: any): Observable<T> => {
      console.log(error.status);
      if (error.status === 401) {
        localStorage.removeItem('PO_USER_LOGIN')
        this.router.navigate(['/login']);
      }
      console.error(operation + ': ' + path, error);
      return throwError(error);
    };
  }

}
