import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { PoNotificationService, PoPageAction, PoTableAction, PoTableColumn, PoModalAction, PoModalComponent, PoMultiselectOption, PoUploadFileRestrictions, PoUploadComponent, PoUploadLiterals } from '@po-ui/ng-components';
import { HttpService } from '../services/http.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tarefas',
  templateUrl: './tarefas.component.html',
  styleUrls: ['./tarefas.component.css']
})
export class TarefasComponent extends BaseComponent implements OnInit {

  @ViewChild('poModal1') poModal1: PoModalComponent;
  @ViewChild('poModal2') poModal2: PoModalComponent;
  @ViewChild('poModal3') poModal3: PoModalComponent;
  @ViewChild('poModal4') poModal4: PoModalComponent;
  @ViewChild('poModal5') poModal5: PoModalComponent;
  
  @ViewChild('optionsForm', { static: true }) form: NgForm;
  @ViewChild(PoUploadComponent, { static: true }) upload: PoUploadComponent;

  constructor(
    private route: ActivatedRoute,
    private notificacao: PoNotificationService,
    public httpService: HttpService,
    private router: Router) {
    super(httpService, '/projetos/tarefas/', '' );
  }

  restricoes: PoUploadFileRestrictions = {
    allowedExtensions: ['.csv']
  }
  
  url = environment.urlHttp;
  urlupload = environment.urlApi + '/nyxupload';

  antesdeupar(event) {    
    const file = event.file.rawFile;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const arquivo = event.file.name;
        const body = {
          base64: reader.result, extension: event.file.extension, name: arquivo, diretorio: 'importartarefas', chave: this.idprojeto};
        this.httpService.post('/nyxupload', body).subscribe(
          data => {            
            this.get()
          }
        );
    };
  }

  customLiterals: PoUploadLiterals = {
    selectFile: 'Selecionar Arquivo',
    startSending: 'Importar CSV'
   };

  hrspordia;
  datainicio;
  diasselecionados;
  tarefainicial = '';

  ordem;
  codigo;
  idprojeto;
  projeto;
  tiposel;
  analista;
  descricao;
  titulo;
  excluir = false;
  ordemnova;
  statussel;
  previsto;
  titulotarefa;
  analistassel = [];
  listaanexos = [];
  pathanexos;

  dias = [
    { value: '2', label: 'Segunda'},
    { value: '3', label: 'Terça' },
    { value: '4', label: 'Quarta'},
    { value: '5', label: 'Quinta'},
    { value: '6', label: 'Sexta' }
  ];

  pageactions: Array<PoPageAction> = [
    {label: 'Incluir', action: this.incluir.bind(this)},
    {label: 'Ordem', action: this.ajustaordem.bind(this)},
    {label: 'Refatorar Ordem', action: this.refatorar.bind(this)},
    {label: 'Calcular Previsão', action: this.calcular.bind(this)},
    {label: 'Importar tarefas', action: this.importar.bind(this)}
  ];

  tableActions: Array<PoTableAction> = [
    {label: 'Editar'  , action: this.editar.bind(this), icon: 'po-icon po-icon-edit' },
    {label: 'Excluir' , action: this.exclui.bind(this), icon: 'po-icon po-icon-delete' },
    {label: 'Anexos'  , action: this.anexos.bind(this), icon: 'po-icon po-icon-attach' },
    {label: 'Detalhes', action: this.detalhes.bind(this), icon: 'po-icon po-icon-list' },
    {label: 'Cronograma', action: this.cronograma.bind(this), icon: 'po-icon po-icon-calendar' }
  ];

  tipo: Array<PoMultiselectOption> = [
    { value: 'A', label: 'Analítico' },
    { value: 'S', label: 'Sintético' }
  ];

  analistas: Array<PoMultiselectOption> = [
    { value: 'A', label: 'Analítico' },
    { value: 'S', label: 'Sintético' }
  ];

  status: Array<PoMultiselectOption> = [
    { value: ' ', label: ' ' },
    { value: '0', label: 'Aguardando' },
    { value: '1', label: 'Fazer' },
    { value: '2', label: 'Fazendo' },
    { value: '3', label: 'Feito' },
    { value: '4', label: 'Cancelado' },
  ];

  close: PoModalAction = {
    action: () => {
      this.poModal1.close();
      this.poModal2.close();
      this.poModal3.close();
      this.poModal5.close();
    },
    label: 'Cancelar',
  };

  confirm: PoModalAction = {
    action: () => {
      this.confirmar();
    },
    label: 'Confirmar'
  };

  confirm2: PoModalAction = {
    action: () => {
      this.confirmarrefatorar();
    },
    label: 'Confirmar'
  };

  confirm3: PoModalAction = {
    action: () => {
      this.processarcalculo();
    },
    label: 'Confirmar'
  };
  
  confirm5: PoModalAction = {
    action: () => {
      this.importarTarefas();
    },
    label: 'Confirmar'
  };

  ngOnInit() {
    this.route.paramMap.subscribe(parameters => {
      this.idprojeto = parameters.get('projeto');
      this.filtros = 'projeto=' + this.idprojeto;
      this.get();
      this.colunas = this.getColumns();

      this.getaux('analistas').subscribe(
        data => {
          this.analistas = [];
          this.dadosAux = data.items;
          this.dadosAux.forEach(element => {
            this.analistas.push({label: element.nome, value: element.codigo});
        }
        );
       });
    });
  }

  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'ordem', label: 'Ordem'  },
      {
        property: 'tipo',
        type: 'label',
        width: '10%',
        labels: [
          { value: 'A'       , color: 'color-01', label: 'Analítico' },
          { value: 'S'       , color: 'color-02', label: 'Sintético' },
        ]
      },
      { property: 'previsto' , label: 'Horas(P)'   },
      { property: 'realizado', label: 'Horas(R)'   },
      { property: 'titulo'   , label: 'Título'   },
      { property: 'dtprevistoini', label: 'Data Inicio(P)'   },
      { property: 'dtprevistofim', label: 'Data Fim(P)'   },
      { property: 'tecnico'  , label: 'Analista(s)'   },
      {
        property: 'status',
        type: 'subtitle',
        width: '80px',
        subtitles: [
          { value: '0'            , color: 'color-08', label: 'Aguardando', content: 'AG' },
          { value: '1'            , color: 'color-07', label: 'Fazer', content: 'FZ' },
          { value: '2'            , color: 'color-06', label: 'Fazendo', content: 'FA' },
          { value: '3'            , color: 'color-10', label: 'Feito', content: 'FE' },
          { value: '4'            , color: 'color-04', label: 'Cancelado', content: 'CA' },
        ]
      },

    ];
  }

  incluir() {
    this.codigo = '';
    this.projeto = this.idprojeto;
    this.excluir = false;
    this.poModal1.open();
  }

  editar(linha) {
    this.excluir = false;
    this.ordem = linha.ordem;
    this.tiposel = linha.tipo;
    this.descricao = linha.descricao;
    this.titulotarefa = linha.titulo;
    this.codigo = linha.codigo;
    this.previsto = linha.previsto;
    this.projeto = this.idprojeto;
    this.statussel = linha.status;
    this.analistassel = linha.analistassel;
    this.poModal1.open();
  }

  exclui(linha) {
    this.excluir = true;
    this.codigo = linha.codigo;
    this.projeto = linha.idprojeto;
    this.descricao = linha.descricao;
    this.poModal1.open();
  }

  refatorar() {
    this.ordem = '';
    this.ordemnova = '';
    this.poModal2.open();
  }

  confirmarrefatorar() {
    if (this.ordem.length !== 1 || this.ordemnova.length !== 1) {
      this.notificacao.error('A refatoração só é permitida no nível 1');
    } else {
      const body = {ordemde: this.ordem, ordempara: this.ordemnova};

      this.httpService.post('/projetos?projeto=' + this.idprojeto + '&ordena=R', body, ).subscribe(
        data => {
          this.poModal2.close();
          this.get();
        },
        error => {
          this.notificacao.error('Erro ao tentar refatorar.');
        },
        () => {
        }
      );
    }
  }

  confirmar() {

    if (this.excluir) {
      this.confirm.loading = true;
      this.httpService.post(this.endpoint + '?projeto=' + this.idprojeto + '&tarefa=' + this.codigo, {}, ).subscribe(
        data => {
          this.confirm.loading = false;
          this.poModal1.close();
          this.get();
        },
        error => {
          this.confirm.loading = false;
          this.notificacao.error('Erro ao tentar excluir tarefa');
        },
        () => {
          this.confirm.loading = false;
          this.form.reset();
        }
      );
    } else {

    if (this.form.invalid) {
      this.notificacao.error('Preencha os dados corretamente.');
    } else {
      this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
      this.confirm.loading = true;
      this.httpService.post(this.endpoint + this.sessao.tecnico + '/', this.form.value).subscribe(
        data => {
          this.confirm.loading = false;
          this.poModal1.close();
          this.get();
          this.notificarAnalistas();
        },
        error => {
          this.confirm.loading = false;
          this.notificacao.error('Erro ao tentar gravar tarefa');
        },
        () => {
          this.confirm.loading = false;
          this.form.reset();
        }
      );
    }
  }
  }

  ajustaordem() {
    this.router.navigate(['/ordem'],
      { queryParams: { projeto: this.idprojeto, dados: JSON.stringify(this.resources), titulo: this.titulo }});
  }

  calcular() {
    this.poModal3.open();
  }

  processarcalculo() {

    const body = {
      dias: this.diasselecionados,
      horaspordia: this.hrspordia,
      datainicio: this.datainicio,
      tarefainicial: this.tarefainicial
     };

    this.confirm3.loading = true;
    this.httpService.post('/tarefas/setPrevisao?projeto=' + this.idprojeto, body).subscribe(
      data => {
        this.confirm3.loading = false;
        this.notificacao.success('Calculo processado com sucesso');
        this.poModal3.close();
      },
      error => {
        this.confirm3.loading = false;
        this.notificacao.error('Erro ao tentar calcular previsão');
      }
    );

  }

  anexos(linha) {
    this.codigo = linha.codigo;
    this.listaanexos = linha.anexos;
    this.pathanexos = linha.pathanexos;
    console.log(this.pathanexos)// = linha.pathanexos;
    this.poModal4.open();
  }

  detalhes(linha) {
    this.router.navigate(['/tarefasanalista', linha.codigo]);
  }

  cronograma(linha) {            
    this.router.navigate(['/cronograma', linha.projeto], { queryParams: { tarefa: linha.codigo }});
  }

  // TODO Notificação Tarefas
  private notificarAnalistas(): void {

    const nomeTarefa: string = this.form.value.nome;
    const analSelecionados: Array<string> = this.form.value.analistassel;
    
    analSelecionados.forEach(element => {
      
      this.httpService.post('/geral/gerarnotificacao?tecnico=' + element,
      {
        "descricao": "Você foi vinculado a tarefa '" + nomeTarefa + "' por " + this.sessao.nameuser + "."
      }).subscribe(
        data => {
          const response: any = data;
          if( response.code === '401'){
            this.notificacao.warning('Cadastrar notificação do analista falhou: '+ response.message);
          }
        }, 
        error => {
          this.notificacao.error('Erro ao tentar notificar o analista '+ element);
        });
    
      });
  }


  importar() {  
      this.poModal5.open()

  }

  importarTarefas() {

  }

}
