<div class="po-wrapper">
  <po-toolbar [p-title]="titulo"></po-toolbar>      
  <po-page-default    
    p-title="Cronograma">    
    
    <po-loading-overlay *ngIf="carregando"
        p-screen-lock="true"
        p-text="carregando" >
    </po-loading-overlay>
    
    <po-container  p-title="Filtros do Cronograma">
        <po-datepicker p-optional="true" class="po-md-2 po-sm-mb-2 po-mb-2 po-lg-mb-2" name="datade"    [(ngModel)]="datade"  p-label="Período inicial" ></po-datepicker>
        <po-datepicker p-optional="true" class="po-md-2 po-sm-mb-2 po-mb-2 po-lg-mb-2" name="dataate"   [(ngModel)]="dataate" p-label="Período final"   ></po-datepicker>
        <po-select class="po-md-2 po-sm-mb-2 po-mb-2 po-lg-mb-2" name="tipo"  p-label="Tipo de busca"  [(ngModel)]="pesquisasel" [p-options]="tipopesquisa"></po-select>                    
        <po-select class="po-md-2 po-sm-mb-2 po-mb-2 po-lg-mb-2" name="tipo"  p-label="Analítico/Sintético"  [(ngModel)]="tiposel" [p-options]="tipofiltro"></po-select>                    
        <po-input p-placeholder="Ex.: 1-3-6" p-optional p-required="false" p-auto-focus="true" class="po-xl-2 po-lg-2 po-md-2 po-sm-2" name="ordens" p-label="Ordens" [(ngModel)]="ordens"></po-input>            
        <div class="filtrar">                            
            <po-button p-icon="po-icon po-icon-filter" p-type="primary" p-label="Filtrar" (p-click)="getdados()"> </po-button>            
        </div>
    </po-container>
    <po-container>      
      <ag-grid-angular 
       #agGrid
        
        class="ag-theme-alpine"
        domLayout='autoHeight'
        [enableBrowserTooltips]="true"
        [rowData]="rowData"                         
        (cellValueChanged)="editar($event)"        
        (gridReady)="onGridReady($event)"
        [columnDefs]="columnDefs">                
      </ag-grid-angular>
    </po-container>
    
    </po-page-default >   

  </div>
