import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PoPageAction, PoTableAction, PoTableColumn } from '@po-ui/ng-components';
import { BaseComponent } from '../base/base.component';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent extends BaseComponent implements OnInit {

  constructor(
      public httpService: HttpService,
      private route: ActivatedRoute,
      public router: Router
      ) { 
    super(httpService, '/atendimento/tickets/', '' )
  }

  colunas;
  filters;
  ticketatual;
  listaanexos = []
  pathanexos;
  listaclientes = [];
  listaanalistas = [];
  
  paginaAtual: number;
  listaTickets: any[];
  mostrarBtnCarregar: boolean;
  
  tableActions: Array<PoTableAction> = [
  ];

  pageactions: Array<PoPageAction> = [
    {label: 'Novo Ticket', action: this.novoTicket.bind(this), icon: 'po-icon po-icon-plus' }
  ];
 
  tipos = [
    { value: '1', label: 'Dúvida Processo' },
    { value: '2', label: 'Erro Rotina Padrão' },
    { value: '3', label: 'Erro Rotina Customizada' },
    { value: '4', label: 'Melhoria' },
    { value: '5', label: 'Manutençao de Customização' },
    { value: '6', label: 'Cloud' },
    { value: '7', label: 'Solicitação' },
  ];

  status = [
    { value: '1', label: 'Novo' },
    { value: '2', label: 'Em atendimento' },
    { value: '3', label: 'Aguardando Cliente' },
    { value: '4', label: 'Finalizado' },
  ];

  async ngOnInit() {

    let auxfiltros = [];

    let initstatus;
    let initanalista;
    let initcliente;

    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    
    this.paginaAtual = 1
    this.mostrarBtnCarregar = true;
    this.listaTickets = []

    if (localStorage.getItem('filtrostickets')) {
       auxfiltros = JSON.parse(localStorage.getItem('filtrostickets'));
       auxfiltros.forEach(element => {
         if (element.property === 'status') {
            initstatus = element.value;
         } else if (element.property === 'analista') {
            initanalista = element.value;
         } else if (element.property === 'cliente') {
            initcliente = element.value;
         }
       });       
    } else {
      // initanalista = this.sessao.tecnico;
      initstatus = ['1','2'];
      this.filtros = '&status=1,2'
    }


    this.route
    .queryParams
    .subscribe(params => {
      if (params.analista) {
        initstatus = ['1','2'];
        initanalista = params.analista;
        initcliente = '';
      }
    });

    this.carregando = true;
    await this.getClientes();
    await this.getAnalistas();

    this.filters = [
      { property: 'status'  , label: 'Status'  , gridColumns: 6, options: this.status, optionsMulti: true,
        initValue: initstatus},
      { property: 'analista', label: 'Analista', gridColumns: 6, options: this.listaanalistas, optionsMulti: false,
        initValue: initanalista},
      { property: 'cliente' , label: 'Cliente' , gridColumns: 12, options: this.listaclientes, optionsMulti: false, 
        initValue: initcliente},
    ];
    
    this.colunas = this.getColumns();
    
    if (auxfiltros.length === 0) {      
      this.carregando = true;
      this.paginacao = '&page=1&pagesize=10'
      this.paginaAtual = 1
      this.get(this);
    } else {
      this.carregando = false;
    }

  }

  

  async getClientes() {

    return new Promise((resolve, reject) => {
      this.httpService.get('/agendasagisrn/' + this.sessao.tecnico + '/clientes').subscribe(
        async data => {
          this.listaclientes = [];
          data.items.forEach(element => {
              this.listaclientes.push({label: element.nomecliente, value: element.codigo});
          });
          resolve(true);
        },
        error => {
          reject();
        },
        () => {
        }
      );
    });


  }

  async getAnalistas() {
    return new Promise((resolve, reject) => {
      this.httpService.get('/projetos/tarefas/' + this.sessao.tecnico + '/analistas').subscribe(
        data => {
          this.listaanalistas = [];
          data.items.forEach(element => {
              this.listaanalistas.push({label: element.nome, value: element.codigo});
          });
          resolve(true);
        },
        error => {
          reject();
        },
        () => {
        }
      );
    });
  }

  getColumns(): Array<PoTableColumn> {
    return [      
        {
          property: 'status',
          type: 'subtitle',
          width: '4%',
          subtitles: [
            { value: 'Novo', color: 'color-10', label: 'Novo', content: '' },
            { value: 'Em Atendimento', color: 'color-02', label: 'Em Atendimento', content: '' },
            { value: 'Aguardando Cliente', color: 'color-07', label: 'Aguardando Cliente', content: '' },
            { value: 'Finalizado', color: 'color-08', label: 'Finalizado', content: '' },
          ]
        },
        { property: 'ticket', label: 'Ticket', width: '5%' },
        { property: 'assunto', label: 'Assunto', },
        { property: 'nomecliente', label: 'Cliente', },
        { property: 'modulo', label: 'Módulo', },
        { property: 'usuario', label: 'Solicitante', },
        { property: 'inicio', label: 'Início' },
        {
          label: 'Classificação',
          property: 'classificacao',
          type: 'label',
          width: '10%',
          labels: [
            { value: 'Urgente'    , color: 'color-07', label: 'Urgente'},
            { value: 'Alto'       , color: 'color-08', label: 'Alto'},
            { value: 'Normal'     , color: 'color-02', label: 'Normal'},
            { value: 'Baixo'      , color: 'color-01', label: 'Baixo'},
          ]
        },      
        { property: 'atualizado', label: 'Dt. Atualizado' },
        { property: 'horaatualizado', label: 'Hora Atualizado' },
        { property: 'nomeanalista', label: 'Atribuído' },
        { property: 'sla', label: 'SLA' },
        { property: 'tipodescri', label: 'Tipo', visible: false },
        {
          property: 'acoes',
          label: 'Ações',
          type: 'icon', 
          icons: [
            {              
              action: this.detalhe.bind(this),
              icon: 'po-icon po-icon-list',
              tooltip: 'Detalhes do Ticket',
              value: 'detalhes',
            },
          ]}
      ];    
  }


  anexos(linha): void {
    this.ticketatual  = linha.ticket;
    this.listaanexos = linha.anexos;
    this.pathanexos = linha.pathanexos;    
  }

  detalhe(linha): void {
    console.log(linha);
    this.router.navigate(['/consulta', linha.ticket]);    
  }
  
  changeFilter(disclaimer) {

    let filtroaux = '';
    let valor;

    disclaimer.forEach(fil => {
      if (Array.isArray(fil.value)) {
        valor = '';
        fil.value.forEach(e => {
          valor += e + ',';
        });
      } else {
        valor = fil.value;
      }
      filtroaux += '&' + fil.property + '=' + valor;
    });

    localStorage.setItem('filtrostickets', JSON.stringify(disclaimer));

    this.filtros = filtroaux;
    this.paginacao = '&page=1&pagesize=10';
    this.paginaAtual = 1;
    this.listaTickets = [];
    this.get(this);
  }

  buscaavancada(filter) {
    let disclaimer = [];

    // console.log(filter);

    if (filter.status) {
      disclaimer.push({property: 'status', value: filter.status});
    }
    if (filter.analista) {
      disclaimer.push({property: 'analista', value: filter.analista});
    }
    if (filter.cliente) {
      disclaimer.push({property: 'cliente', value: filter.cliente});
    }

    this.changeFilter(disclaimer);
    
  }

  novoTicket() {
    this.router.navigate(['/ticket'])
  }

  CarregarMais(): void{
    
    this.carregando = true;
    this.paginaAtual++
    this.paginacao = '&page='+ this.paginaAtual.toString() +'&pagesize=10';
    
    this.get(this); 

  }

  getListaTickets(itens, instaceComponent): void{
    instaceComponent.mostrarBtnCarregar = true;
    if(itens.length == 0){
      instaceComponent.mostrarBtnCarregar = false
    }else if(itens !== undefined){
      itens.forEach(element => {
        instaceComponent.listaTickets.push(element);
      });
    }
    instaceComponent.carregando = false
  }

}
