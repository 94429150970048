
<div class="po-wrapper">
    <po-toolbar [p-title]="titulo"></po-toolbar>  
    <po-page-dynamic-search p-title="Eventos" [p-actions]="pageactions" [p-filters]="filters" (p-quick-search)="onfilter($event)" (p-change-disclaimers)="onfilter(null)">
        <po-table p-show-more="true" p-container="border" p-sort="true" p-striped="true"
          [p-loading]="carregando" [p-columns]="colunas" [p-items]="resources" [p-actions]="tableActions">     
       </po-table>
    </po-page-dynamic-search>
 </div>

 <po-modal #poModal1 p-title="Eventos do Projeto" [p-primary-action]="confirm" [p-secondary-action]="close" p-hide-close="false">
    
    <form #optionsForm="ngForm" [hidden]="excluir">

        <po-divider p-label="Tipo de Evento" ></po-divider>
                
        <po-radio-group p-columns="3" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="tipo" p-label="" [p-options]="tipos" [(ngModel)]="tipoevento"></po-radio-group>            

        <po-divider p-label="Dados do evento"></po-divider>

        <div class="po-row">
            <po-input p-required="false" p-auto-focus="true" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="descricao" p-label="Descrição" [(ngModel)]="descricao"></po-input>            
        </div>   
        
        <div class="po-row" *ngIf="tipoevento==='D'">
            <po-datepicker p-required="false" class="po-xl-6 po-lg-6 po-md-6 po-sm-6" name="data1" p-label="Data Criação" [(ngModel)]="data1"></po-datepicker>                                          
            <po-datepicker p-required="false" class="po-xl-6 po-lg-6 po-md-6 po-sm-6" name="data2" p-label="Data Assinatura" [(ngModel)]="data2"></po-datepicker>                                          
            <po-select class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" name="status"  p-label="Status"  [(ngModel)]="statussel" [p-options]="statusDocs"></po-select>               
            <po-input p-required="false" p-auto-focus="true" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="link" p-label="Link" [(ngModel)]="link"></po-input>            
        </div>
        <div class="po-row" *ngIf="tipoevento==='P'">
            <po-datepicker p-required="false" class="po-xl-6 po-lg-6 po-md-6 po-sm-6" name="data1" p-label="Data Criação" [(ngModel)]="data1"></po-datepicker>                                          
            <po-datepicker p-required="false" class="po-xl-6 po-lg-6 po-md-6 po-sm-6" name="data2" p-label="Data Finalização" [(ngModel)]="data2"></po-datepicker>                                          
            <po-select class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" name="status"  p-label="Status"  [(ngModel)]="statussel" [p-options]="statusPend"></po-select>               
        </div>
        <div class="po-row" *ngIf="tipoevento==='R'">
            <po-select class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" name="status"  p-label="Status"  [(ngModel)]="statussel" [p-options]="statusRiscos"></po-select>               
            <po-textarea  p-placeholder="Açao tomada" p-required="false" [(ngModel)]="acao" class="po-md-12" name="acao"  p-label="Açao" p-rows="4"></po-textarea>
        </div>

    </form>

    <div *ngIf="excluir">
        <div class="po-font-text-large" >
            Confirma a exclusão?
        </div>
        <div class="po-font-text">
            {{this.descricao}}
        </div>
    </div>

 </po-modal>