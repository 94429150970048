import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as Gantt from 'frappe-gantt';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-projetos-visaogeral',
  templateUrl: './projetos-visaogeral.component.html',
  styleUrls: ['./projetos-visaogeral.component.css']
})
export class ProjetosVisaogeralComponent implements OnInit {

  @ViewChild('ganttChart', {static: true}) ganttEl: ElementRef;

  constructor(
    public httpService: HttpService
  ) {  }

  sessao;
  clientesel;
  listaclientes = [];
  statussel;
  analistasel = '';
  mensagem;
  analistas = [];

  status = [
    { value: '' , label: 'Todos' },
    { value: '0', label: 'Parado' },
    { value: '1', label: 'Em andamento' },
    { value: '2', label: 'Finalizado' },
    { value: '3', label: 'Aguardando Cliente' },
  ];

  tipovisualizacao = [
    { label: 'Semana'   , value: 'Week' , action: this.mudavisualizacao.bind(this) },
    { label: 'Mês'      , value: 'Month', action: this.mudavisualizacao.bind(this) },
    { label: 'Dia'      , value: 'Day'  , action: this.mudavisualizacao.bind(this) },
    { label: 'Mês/Dia'  , value: 'Quarter Day', action: this.mudavisualizacao.bind(this) },
  ];

  gantt;
  tasks = [];

  ngOnInit() {
    this.mensagem = 'Selecione status e cliente';
    this.statussel = [''];
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    this.getProjetos();
    this.getClientes();
    this.getAnalistas();
  }

  mudavisualizacao(tipo) {
    this.gantt.change_view_mode(tipo.value);
  }

  getProjetos() {

    if (this.clientesel && this.statussel) {
      if (this.analistasel === '0' ) {
        this.analistasel = '';
      }
      this.httpService.get('/projetos/?cliente=' + this.clientesel + '&status=' + this.statussel + '&analista=' + this.analistasel).subscribe(
        data => {
          this.tasks = [];
          if (data.items.length === 0) {
            this.mensagem = 'Nenhum projeto encontrado.';
          } else {
            this.mensagem = '';
            let projeto;
            let progresso;
            data.items.forEach(element => {
              progresso = element.tarefasqtd.realizadas / element.tarefasqtd.total * 100;
              projeto = {
                id: element.codigo,
                name: element.descricao,
                start: element.dtiniprev,
                end: element.dtfimprev,
                progress: progresso,
              };
              this.tasks.push(projeto);
            });
            this.gantt = new Gantt.default('#ganttChart', this.tasks, {language: 'ptBr', padding: 18, bar_height: 25});
            this.gantt.change_view_mode('Month');
          }
        },
        error => {
        },
      );
    }
  }

  getClientes() {
    this.httpService.get('/agendasagisrn/' + this.sessao.tecnico + '/clientes').subscribe(
      data => {
        this.listaclientes = [];
        data.items.forEach(element => {
            this.listaclientes.push({label: element.nomecliente, value: element.codigo});
        });
      },
    );
  }
  getAnalistas() {
    this.httpService.get('/projetos/tarefas/' + this.sessao.tecnico + '/analistas').subscribe(
      data => {
        this.analistas.push({label: 'Todos', value: '0'});
        data.items.forEach(element => {
          this.analistas.push({label: element.nome, value: element.codigo});
        });
      },
    );
  }

}
