<div class="po-wrapper">
    <po-toolbar [p-title]="titulo"></po-toolbar>  
    <po-page-dynamic-search p-title="Tarefas" [p-actions]="pageactions" (p-quick-search)="onfilter($event)" (p-change-disclaimers)="onfilter($event)">
       <po-table p-show-more="true" p-container="border" p-sort="true" p-striped="true"
          [p-loading]="carregando" [p-columns]="colunas" [p-items]="resources" [p-actions]="tableActions">     
       </po-table>
    </po-page-dynamic-search>
 </div>


 <po-modal #poModal1 p-title="Tarefas" [p-primary-action]="confirm" [p-secondary-action]="close" p-hide-close="false">
    <form #optionsForm="ngForm" [hidden]="excluir">

        <div class="po-row">
            <po-input p-optional p-required="false" p-auto-focus="true" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="ordem" p-label="Ordem" [(ngModel)]="ordem"></po-input>            
            <po-select class="po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4" name="tipo"  p-label="Analítico/Sintético"  [(ngModel)]="tiposel" [p-options]="tipo"></po-select>                    
            <po-select [p-disabled]="!root" class="po-md-5 po-sm-mb-5 po-mb-5 po-lg-mb-5" name="status"  p-label="Status"  [(ngModel)]="statussel" [p-options]="status"></po-select>                    
            <po-input p-required="true" p-auto-focus="true" class="po-xl-9 po-lg-9 po-md-9 po-sm-9" name="nome" p-label="Titulo" [(ngModel)]="titulotarefa" ></po-input>            
            <po-number p-auto-focus="true" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="previsto" p-label="Duração" [(ngModel)]="previsto" ></po-number>            
        </div>   

        <div class="po-row">
            <po-multiselect p-auto-focus="true" p-required="false" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" name="analistassel" p-label="Analistas" [p-options]="analistas" [(ngModel)]="analistassel">  </po-multiselect>
            <po-textarea  p-placeholder="Descrição da Atividade" p-required="false" [(ngModel)]="descricao" class="po-md-12" name="descricao"  p-label="Descrição" p-rows="7"></po-textarea>           
        </div>   

        <po-input [hidden]="true" p-required="false" p-auto-focus="true" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="projeto" [(ngModel)]="projeto" p-label="projeto" [(ngModel)]="projeto"></po-input>            
        <po-input [hidden]="true" p-required="false" p-auto-focus="true" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="codigo" [(ngModel)]="codigo" p-label="codigo" ngModel></po-input>            

    </form>

    <div *ngIf="excluir">
        <div class="po-font-text-large" >
            Confirma a exclusão da tarefa?
        </div>
        <div class="po-font-text">
            {{this.descricao}}
        </div>
    </div>
</po-modal>

 <po-modal #poModal2 p-title="Refatorar tarefa" [p-primary-action]="confirm2" [p-secondary-action]="close" p-hide-close="false">
     <div>
         Atenção, o refatoramento só será aceito no nível 1 da tarefa, ou seja, tarefas que tenha a ordem com 1 caracter. 
     </div>
     <po-divider></po-divider>
    <po-input p-required="true" p-auto-focus="true" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="ordem" p-label="Ordem Atual" [(ngModel)]="ordem"></po-input>            
    <po-input p-required="true" p-auto-focus="true" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="ordem" p-label="Nova Ordem" [(ngModel)]="ordemnova"></po-input>            
 </po-modal>

 <po-modal #poModal3 p-title="Calcular Previsão" [p-primary-action]="confirm3" [p-secondary-action]="close" p-hide-close="false">

    <po-checkbox-group
        class="po-xl-5 po-lg-5 po-md-5 po-sm-5"    
        p-columns="3"
        p-label="Dias da Semana"
        [(ngModel)]="diasselecionados"
        name="diasdasemaa"
        [p-options]="dias">
    </po-checkbox-group>

    <po-number p-auto-focus="true" class="po-xl-2 po-lg-2 po-md-2 po-sm-2" name="previsto" p-label="Horas/dia" [(ngModel)]="hrspordia" ></po-number>            
    <po-datepicker p-required="false" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="datainicio" p-label="Data Inicial" [(ngModel)]="datainicio"></po-datepicker>                                              
    <po-input p-required="true" p-auto-focus="true" class="po-xl-2 po-lg-2 po-md-2 po-sm-2" name="nome" p-label="Tarefa Inicial" [(ngModel)]="tarefainicial" ></po-input>            
</po-modal>

<po-modal #poModal4 p-title="Anexos" p-hide-close="false">
    <app-anexos [chave]="codigo" [arquivos]="listaanexos" diretorio="tarefas" [pathanexos]="pathanexos" [anexoativo]="true"></app-anexos>
</po-modal>

<po-modal #poModal5 p-title="Importar Tarefas" p-hide-close="false">
    <div class="po-font-text">
        <b>a)</b> Insira as tarefas neste template: 
        <a href="https://docs.google.com/spreadsheets/d/1a2vHfZIMp8aW2UswmIfl8aes7HJ2rMoRGBFu0ZpHTJ0/edit?usp=sharing" target="_blank">
            Link
        </a>

    </div>
    <div class="po-font-text">
        <b>b)</b> Na planilha do google, clique em: "File >> Download >> .csv"
    </div>
    <div class="po-font-text">
        <b>c)</b> Volte nessa tela e selecione o arquivo csv gerado no passo anterior
    </div>
    <div class="po-font-text">
        <b>d)</b> Clique em "Importar CSV"
    </div>
    
    <po-divider></po-divider>
    <po-upload   
        [p-restrictions]="restricoes"      
        [p-literals]="customLiterals"
        name="upload"  
        p-drag-drop-height="160"
        [p-url]="urlupload" 
        (p-upload)="antesdeupar($event)" 
        p-multiple="false">
    </po-upload>
</po-modal>