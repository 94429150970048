<po-toolbar p-title="Agenda"></po-toolbar>      
<po-page-default p-title="Consultar Agendamentos" [p-actions]="actions">   
    <po-loading-overlay
        [hidden]="!gerandoos"
        p-screen-lock="true"
        p-text="Gerando arquivo" >
    </po-loading-overlay>  
    <div [hidden]="oculto">
        <div class="row" >
            <po-datepicker
                (p-change)="montadias()"
                class="po-xl-3 po-lg-3 po-md-3 po-sm-12"
                [(ngModel)]="diainicio"            
                p-label="Inicio">
            </po-datepicker>

            <po-datepicker
                (p-change)="montadias()"
                class="po-xl-3 po-lg-3 po-md-3 po-sm-12"
                [(ngModel)]="diafinal"            
                p-label="Final">
            </po-datepicker>

            <po-select p-required="true" p-placeholder="Selecione o Cliente" class="po-xl-6 po-lg-6 po-md-6 po-sm-12" name="cliente"  
            (p-change)="clientes"  p-label="Cliente" [p-options]="clientes" [(ngModel)]="cliente" (p-change)="montadias()"></po-select>      
        </div>

        <div class="row">
            <po-multiselect p-auto-focus="true" p-required="false" class="po-xl-10 po-lg-10 po-md-10 po-sm-12" (p-change)="montadias('multiselect')" name="analistasel" p-label="Analistas - {{descricaoFiltro}}" [p-options]="analistas" [(ngModel)]="analistasselecionados">  </po-multiselect>
            <div class="po-xl-2 po-lg-2 po-md-2 po-sm-12">
                <div class="row po-field-title" style="margin-left:8px">Legenda</div>
                <div class="row po-icon po-icon-calendar-ok"><span class="icones-legenda ">- Confirmado</span></div>
                <div class="row" ></div>
                <div class= "row po-icon po-icon-device-desktop"><span class="icones-legenda">- Remoto</span></div>
                <div class="row" ></div>
                <div class= "row po-icon po-icon-warehouse"><span class="icones-legenda ">- Presencial</span></div>
                <div class="row" ></div>
                <div class= "po-icon po-icon-download"><span class="icones-legenda ">- OS lançada</span></div>
            </div>   
        </div>
    </div>
    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12"></div>      
    <div *ngFor="let dia of dias; let i=index" >
        
        <div [ngClass]="{'po-xl-2 po-lg-2 po-md-2 po-sm-12': !saboudom(dia),'po-xl-1 po-lg-1 po-md-1 po-sm-12': saboudom(dia)}" >
            
            <div class="centralizar po-font-text-bold po-xl-12 po-lg-12 po-md-12 po-sm-12">
                {{dia.dia}}                 
            </div>
            
            <!-- <div [ngClass]="{'centralizarhoje po-xl-12 po-lg-12 po-md-12 po-sm-12': hoje(dia.dia), 'centralizardia po-xl-12 po-lg-12 po-md-12 po-sm-12': !hoje(dia.dia)}"> -->
            
            <div [hidden]="!hoje(dia.dia)" class="centralizarhoje po-xl-12 po-lg-12 po-md-12 po-sm-12">
                {{dia.diasemana}} 
            </div>
            
            <div  [hidden]="hoje(dia.dia)" class="centralizardia po-xl-12 po-lg-12 po-md-12 po-sm-12">
                {{dia.diasemana}} 
            </div>

            <div  *ngFor="let agenda of dia.agendas; let i=index"
            [style]="'margin-bottom: 7px; border-radius: 4px; background-color: rgb(1, ' + agenda.tecnico.substring(6,3) + ', ' + '1'+ agenda.tecnico.substring(5,6)  * 2 + ')'"                
                class="po-xl-12 po-lg-12 po-md-12 po-sm-12">                                
                <div #buttonHoverRef>
                    <div style="color: white;" class="po-font-text-small-bold po-xl-8 po-lg-8 po-md-8 po-sm-8">
                        {{agenda.analista }}                        
                    </div>

                    <div *ngIf="agenda.confirmado" class="icones po-icon po-icon-calendar-ok po-xl-1 po-lg-1 po-md-1 po-sm-1"></div>
                    <div *ngIf="agenda.remoto" class= "icones po-icon po-icon-device-desktop po-xl-1 po-lg-1 po-md-1 po-sm-1"></div>
                    <div *ngIf="!agenda.remoto" class= "icones po-icon po-icon-warehouse po-xl-1 po-lg-1 po-md-1 po-sm-1"></div>
                    <div *ngIf="agenda.status == 'Lancado'" (click)="imprimir(agenda)" class= "icones po-icon po-icon-download po-xl-1 po-lg-1 po-md-1 po-sm-1"></div>
                    
                    
                    <div style="color: white;" class="po-font-text-small po-xl-12 po-lg-12 po-md-12 po-sm-12">
                        {{agenda.nomecliente + ' ' + agenda.hrinicio + ' - ' + agenda.hrfinal}}
                        <!-- {{agenda.details ? agenda.details[0].observacao : ''}}         -->                        
                    </div>

                    <po-popover *ngIf="agenda.details"  
                    p-title="Observação"    
                    p-trigger="hover" 
                        [p-target]="buttonHoverRef">
                        <div class="po-font-text-small">
                            {{agenda.details ? agenda.details[0].observacao : ''}} 
                        </div>
                    </po-popover>
                    

                </div>                        
            </div>
        </div>
        
        <div *ngIf="pulalinha(i)">
            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12"></div>
        </div>
                

    </div>

    <po-modal  p-title="Filtros" [p-primary-action]="confirm" [p-secondary-action]="close" p-hide-close="false">
        <form #optionsForm="ngForm" [hidden]="!cadastraFiltro" >
            <po-input name="descricaoFiltro" p-label="Descrição" [p-readonly]="edita" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" [(ngModel)]="descricaoFiltro" > </po-input>
            <po-multiselect p-auto-focus="true" p-required="false" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="analistasel" p-label="Analistas" [p-options]="analistas" [(ngModel)]="analistasselecionados">  </po-multiselect>
         </form>    

         <po-table p-container="border" p-striped="true" [p-columns]="colunas" [p-items]="filtros" [p-actions]="tableActions" [hidden]="cadastraFiltro" p-selectable="true" (p-selected)="seleciona_grupo($event)" (p-unselected)="limpa_selecionados()" >     
        </po-table>

    </po-modal>

</po-page-default>
