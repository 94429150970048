<div class="po-wrapper">
   <po-toolbar p-title="Agendamentos do analista"></po-toolbar>  
   <po-page-dynamic-search p-title="Agendamentos" [p-actions]="pageactions" [p-filters]="filters" (p-quick-search)="onfilter($event)" (p-change-disclaimers)="changeFilter($event)" (p-advanced-search)="buscaavancada($event)">

      <po-loading-overlay
         [hidden]="!gerandoos"
         p-screen-lock="true"
         p-text="Gerando arquivo" >
      </po-loading-overlay>   

      <po-table  #buttonHoverRef
      (p-expanded)="onExpandDetail($event)"
      p-show-more="true" p-container="border" p-sort="true" p-striped="true"
         [p-loading]="carregando" [p-columns]="colunas" [p-items]="resources" [p-actions]="tableActions">     
      </po-table>
   </po-page-dynamic-search>
</div>

<po-modal #poModalOs p-title="Ordem de Serviço" [p-primary-action]="confirm" [p-secondary-action]="close" p-hide-close="false">
   <form #optionsForm="ngForm">
      <!--  -->
      <div *ngIf="carregandoModal">
         <po-loading p-text="Carregando informações, por favor, aguarde…"></po-loading>
      </div>
      
      <div *ngIf="!carregandoModal">
         <div class="po-row">         
   
            <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12" [p-label]="resumo"></po-divider>
   
            <po-select [p-readonly]="readonly" (p-change)="getprojetoscliente($event)"  p-required="true" p-placeholder="Selecione o Cliente" class="po-xl-5 po-lg-5 po-md-5 po-sm-5" name="cliente"  p-label="Cliente" [p-options]="listaclientes" [ngModel]="clientesel" (ngModelChange)="clientesel = $event" ></po-select>        
            <po-datepicker [p-readonly]="readonly" p-required="true" class="po-xl-3 po-lg-3 po-md-3 po-sm-3"  name="data" p-label="Data" [(ngModel)]="data"></po-datepicker>                                 
            <po-radio-group class="po-xl-4 po-lg-4 po-md-4 po-sm-4" name="tipoagendamento" p-label="Tipo" [p-options]="tiposagendamento" [(ngModel)]="tipoagendamento"></po-radio-group>
            <po-select [p-readonly]="readonly"  (p-change)="setMotivo($event)" p-required="true" p-placeholder="Projeto" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="projeto"  
            p-label="Projeto" [p-options]="listaprojetos" [(ngModel)]="projetosel" ></po-select>        
               
            
            <po-input class="po-xl-2 po-lg-2 po-md-2 po-sm-2" p-auto-focus="true" p-mask-format-model="true" [p-placeholder]="pinicial" p-required="true" p-error-pattern="Dado inválido" p-mask="99:99" p-maxlength=5 p-minlength=5  name="horainicial"  [(ngModel)]="horainicial" p-label="Hora Inicial" (p-blur) ="calcularhoratotal()"></po-input>
            <po-input class="po-xl-2 po-lg-2 po-md-2 po-sm-2" p-mask-format-model="true" [p-placeholder]="pfinal" p-required="true" p-error-pattern="Dado inválido" p-mask="99:99" p-maxlength=5 p-minlength=5  name="horafinal"    [(ngModel)]="horafinal" p-label="Hora Final" (p-blur) ="calcularhoratotal()"></po-input>
            <po-input class="po-xl-2 po-lg-2 po-md-2 po-sm-2" p-mask-format-model="true" p-placeholder="00:00" p-required="true"  p-mask="99:99" p-maxlength=5 p-minlength=5  name="horaintervalo"  [(ngModel)]="horaintervalo" p-label="Intervalo" (p-blur) ="calcularhoratotal()"></po-input>
            <!-- <po-input class="po-xl-2 po-lg-2 po-md-2 po-sm-2" p-optional="true" p-mask-format-model="true" p-placeholder="00:00" p-required="false"  p-mask="99:99" p-maxlength=5 p-minlength=5  name="horatranslado"  [(ngModel)]="horatranslado" p-label="Translado"></po-input> -->
   
            <po-input class="po-xl-2 po-lg-2 po-md-2 po-sm-2" p-mask-format-model="true" p-placeholder="00:00" p-readonly="true" p- p-mask="99:99" p-maxlength=5 p-minlength=5 [(ngModel)]="tempototal" name="tempototal" p-label="Tempo Total" id="tempoTotal" ></po-input>
   
            <po-select [p-readonly]="readonly" p-optional="true" p-placeholder="Motivo" class="po-xl-4 po-lg-4 po-md-4 po-sm-4" name="motivo"  p-label="Motivo" [p-options]="listamotivos" [ngModel]="motivosel" (ngModelChange)="motivosel = $event"></po-select>        
   
            <div class="po-font-text-small po-offset-xl-8 po-offset-lg-8 po-offset-md-8 po-offset-sm-8 po-xl-12 po-lg-12 po-md-12 po-sm-12">
   
               <div *ngIf=" ponto.hora1e || ponto.hora1s || ponto.hora2e || ponto.hora2s " class="po-font-text-small">
                  <div  class="po-font-text-small-bold">
                        Pontos do dia: 
                  </div>              
                  {{ponto.hora1e}}  
                  {{ponto.hora1s}}  
                  {{ponto.hora2e}}  
                  {{ponto.hora2s}}  
               </div>
      
            </div>
               
         </div>
         <div class="row">
            <po-button p-type="{{!this.gravandoAudio ? 'primary' : 'danger'}}" class="{{!this.gravandoAudio ? 'po-md-2' : 'po-md-12'}}" p-label="{{!this.gravandoAudio ? '' : 'Gravando... (Clique para parar)'}}" p-icon="po-icon po-icon-microphone" (p-click)="gravarAudio()"> </po-button>
         </div>
         <div style="position: relative;" class="po-row">
            <po-loading-overlay [p-screen-lock]="false" *ngIf="carregandoIa"></po-loading-overlay>
            <po-textarea #laudotecnico  p-placeholder="Preencha o laudo de seu atendimento" p-required="true" [(ngModel)]="laudo" class="po-md-12" name="laudo"  p-label="Laudo Técnico:" p-rows="9"></po-textarea>
         </div>
   
         <div class="po-row" >
            <po-button-group class="po-md-12" [p-buttons]="textButtons" > </po-button-group>
         </div>
         <div class="po-row">
            <po-textarea  p-placeholder="Preencha o laudo de seu atendimento para a equipe interna" p-required="false" [(ngModel)]="laudointerno" class="po-md-12" name="laudointerno"  p-label="Laudo Interno:" p-rows="3"></po-textarea>
         </div>
      </div>

    </form>
   
 </po-modal>

 <po-modal #poModalEmail   
   [p-primary-action]="enviar" [p-secondary-action]="close"
    p-title="{{'Enviar OS por email' }}" 
    p-hide-close="true">

    <po-email [(ngModel)]="email.to" class="po-md-12" name="to" p-label="Para"> </po-email>
    <po-email [(ngModel)]="email.cc" class="po-md-12" name="cc" p-label="Cc"> </po-email>
    <po-input [(ngModel)]="email.assunto" class="po-md-12" name="assunto" p-label="Assunto"> </po-input>

    <div class="po-row">
      <po-textarea  p-required="false" [(ngModel)]="email.msg" class="po-md-12" name="msg"  p-label="Mensagem" p-rows="3"></po-textarea>
   </div>

 </po-modal>