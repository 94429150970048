// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // urlApi: 'http://localhost:5050/protheus?path=',
  urlApi: 'https://api-suporte.userfunction.com.br/protheus?path=',
  urlHttp: 'http://userfunction.ddns.net:8091/',
  urlIa: 'https://dx5qf2pljsdkar4iwt7irxs74m0xubzi.lambda-url.sa-east-1.on.aws'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
