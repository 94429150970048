import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { PoNotificationService, PoPageAction, PoTableAction, PoTableColumn, PoModalComponent, PoModalAction, PoRadioGroupOption, PoSelectOption } from '@po-ui/ng-components';
import { HttpService } from '../services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent extends BaseComponent implements  OnInit {

  @ViewChild('poModal1') poModal1: PoModalComponent;
  @ViewChild('optionsForm', { static: true }) form: NgForm;

  constructor(
    private notitificacao: PoNotificationService,
    private route: ActivatedRoute,
    public httpService: HttpService,
    private router: Router) {
    super(httpService, '/eventos/', '' );
    }

    idprojeto;
    tipoevento;
    statussel;
    excluir;
    descricao;
    data1;
    data2;
    codigo;
    link;
    acao;

    statusDocs: Array<PoSelectOption> = [
      { value: 'Elaborar', label: 'Elaborar' },
      { value: 'Ag. Validar', label: 'Ag. Validar' },
      { value: 'Ag. Assinar', label: 'Ag. Assinar' },
      { value: 'Assinado', label: 'Assinado' },
      { value: 'Arquivado', label: 'Arquivado'}
    ];

    statusPend: Array<PoSelectOption> = [
      { value: 'Pendente'  , label: 'Pendente'   },
      { value: 'Finalizada', label: 'Finalizada' },
    ];

    statusRiscos: Array<PoSelectOption> = [
      { value: 'Alto'  , label: 'Alto'  },
      { value: 'Médio' , label: 'Médio' },
      { value: 'Baixo' , label: 'Baixo' },
    ];

    tipos: Array<PoRadioGroupOption> =  [
      { label: 'Documentos', value: 'D' },
      { label: 'Pendências', value: 'P' },
      { label: 'Riscos', value: 'R' }];

    close: PoModalAction = {
      action: () => {
        this.poModal1.close();
      },
      label: 'Cancelar',
    };

    confirm: PoModalAction = {
      action: () => {
        this.confirmar();
      },
      label: 'Confirmar'
    };

    pageactions: Array<PoPageAction> = [
      {label: 'Incluir', action: this.incluir.bind(this)}
    ];

    tableActions: Array<PoTableAction> = [
      {label: 'Editar' , action: this.editar.bind(this), icon: 'po-icon po-icon-edit' },
      {label: 'Excluir', action: this.exclui.bind(this), icon: 'po-icon po-icon-delete' },
      {label: 'Link'   , action: this.abrilink.bind(this), icon: 'po-icon po-icon-link' }
    ];

    public readonly filters: Array<any> = [
      { property: 'inicio', label: 'Data inicial', gridColumns: 6, type: 'date' },
      { property: 'fim', label: 'Data final', gridColumns: 6, type: 'date' },
    ];

    ngOnInit() {
      this.route.paramMap.subscribe(parameters => {
        this.idprojeto = parameters.get('projeto');
        this.filtros = 'projeto=' + this.idprojeto;
        this.get();
        this.colunas = this.getColumns();
      });
    }

    getColumns(): Array<PoTableColumn> {
      return [
        { property: 'tipo', label: 'Tipo' },
        { property: 'descricao', label: 'Descrição'},
        { property: 'status', label: 'Status'},
        { property: 'data1', label: 'Data Inicial' },
        { property: 'data2', label: 'Data Final'},
        { property: 'acao', label: 'Ação'},
      ];
    }

    incluir() {
      this.tipoevento = 'D';
      this.poModal1.open();
    }

    abrilink(linha) {
      window.open(linha.link, '_blank');
    }

    confirmar() {
      if (this.excluir) {
        console.log(this.codigo);
        this.confirm.loading = true;
        this.httpService.post(this.endpoint + '?codigo=' + this.codigo, {} ).subscribe(
          data => {
            this.confirm.loading = false;
            this.poModal1.close();
            this.get();
          },
          error => {
            this.confirm.loading = false;
            this.notitificacao.error('Erro ao tentar excluir tarefa');
          },
          () => {
            this.confirm.loading = false;
            this.form.reset();
          }
        );
      } else {

      if (this.form.invalid) {
        this.notitificacao.error('Preencha os dados corretamente.');
      } else {
        console.log(this.form.value);
        this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
        this.confirm.loading = true;
        this.httpService.post(this.endpoint + this.sessao.tecnico + '/' + '?projeto=' +
                  this.idprojeto + '&codigo=' + this.codigo, this.form.value).subscribe(
          data => {
            this.confirm.loading = false;
            this.poModal1.close();
            this.get();
          },
          error => {
            this.confirm.loading = false;
            this.notitificacao.error('Erro ao tentar gravar tarefa');
          },
          () => {
            this.confirm.loading = false;
            this.form.reset();
          }
        );
      }
    }
  }

  editar(linha) {
    this.codigo = linha.codigo;
    this.excluir = false;
    this.tipoevento = linha.tipo.substring(0, 1);
    this.descricao = linha.descricao;
    this.statussel = linha.status;
    this.acao = linha.acao;
    this.link = linha.link;

    let dtaux;

    if (linha.data1 !== '  /  /  ') {
      console.log(linha.data1)
      dtaux = linha.data1.split('/');
      const dt1 = new Date();
      dt1.setFullYear(Number('20' + dtaux[2]), Number(dtaux[1] - 1), Number(dtaux[0]));
      this.data1 = dt1;
    }

    if (linha.data2 !== '  /  /  ') {
      dtaux = linha.data2.split('/');
      const dt2 = new Date();
      dt2.setFullYear(Number('20' + dtaux[2]), Number(dtaux[1] - 1), Number(dtaux[0]));
      this.data2 = dt2;
    }

    this.poModal1.open();
  }

  exclui(linha) {
    this.excluir = true;
    this.codigo = linha.codigo;
    this.descricao = linha.descricao;
    this.poModal1.open();
  }

}
