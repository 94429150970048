import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PoNotificationService, PoSelectOption, PoTableAction, PoTableColumn } from '@po-ui/ng-components';
import { BaseComponent } from '../base/base.component';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-documentos-aguardo',
  templateUrl: './documentos-aguardo.component.html',
  styleUrls: ['./documentos-aguardo.component.css']
})
export class DocumentosAguardoComponent extends BaseComponent implements OnInit {
  sessao: any;

  projetoOptions: Array<PoSelectOption>;
  tipoOptions: Array<PoSelectOption>;
  projetoSelect: any;
  tipoSelect: any;
  itemsDocsAg: Array<any>;
  
  columnsDocs: Array<PoTableColumn> = [
    { property: 'codigo', width: '15%', label: 'Cod. Documento' },
    { property: 'titulo', label: 'Projeto'},
    { property: 'descricao'},
    //{ property: 'link', visible: false },
    {
      property: 'status',
      type: 'label',
      width: '10%',
      labels: [
        { value: 'Elaborar', color: 'color-08', label: 'Elaborar' },
        { value: 'Assinado', color: 'color-10', label: 'Assinado' },
        { value: 'Ag. Validar', color: 'color-07', label: 'Ag. Validar' },
        { value: 'Ag. Assinar', color: 'color-07', label: 'Ag. Assinar' },
        { value: 'Arquivado', color: 'color-10', label: 'Arquivado' }

      ]
    }

  ];

  tableActions: Array<PoTableAction> = [
    //{label: 'Editar' , action: this.editar.bind(this), icon: 'po-icon po-icon-edit' },
    {label: 'Link'   , action: this.abrilink.bind(this), icon: 'po-icon po-icon-link' }
  ];

  statusOptions: Array<PoSelectOption> = [
    { value: 'Elaborar', label: 'Elaborar' },
    { value: 'Ag. Validar', label: 'Ag. Validar' },
    { value: 'Ag. Assinar', label: 'Ag. Assinar' },
    { value: 'Assinado', label: 'Assinado' },
    { value: 'Arquivado', label: 'Arquivado' },
  ];

  @ViewChild('optionsForm', { static: true }) form: NgForm;
  
  constructor( private http: HttpService, private rota: Router, private httpClient: HttpClient, private notificacao: PoNotificationService ) 
  { 
    super(http, '/projetos/tarefas/', '')
  }

  ngOnInit(): void {
    this.itemsDocsAg = []
    this.projetoOptions = []
    this.tipoOptions = []
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    this.getDocumentosAg('/eventos/documentosarguardando?tecnico=' + this.sessao.tecnico);
    this.getProjetos();
    this.getTipos();
  }

  /**
   * Pega todos os documentos que estão com o status de Aguardando.
   * @param path Caminho que vai ser utilizado para a requisição get
   */
  getDocumentosAg(path: string): void {
    this.http.get(path).subscribe(
      data => {
        const ret: any = data;
        this.itemsDocsAg = ret.items;
      },
      erro => {
        console.log(erro);
      }
    );
  }

  /**
   * Pega todos os projetos cadastrados e preenche as opções de filtro de Projetos.
   */
  getProjetos(): void {
    this.http.get("/projetos/").subscribe(
      data => {
        this.projetoOptions = [];
        this.projetoOptions.push( {label: 'Todos', value: '' });
        this.dadosAux = data.items;
        this.dadosAux.forEach(element => {
          this.projetoOptions.push( {label: element.descricao, value: element.codigo});
        });
      }
    );
  }

  /**
   * Preenche as opções disponíves para o filtro do Status do Documentos.
   */
  getTipos(): void {
    this.tipoOptions = [];
    this.tipoOptions.push( {label: 'Todos', value: '*' });
    this.tipoOptions.push( {label: 'Elaborar', value: 'Elaborar' });
    this.tipoOptions.push( {label: 'Ag. Validar', value: 'Ag. Validar' });
    this.tipoOptions.push( {label: 'Ag. Assinar', value: 'Ag. Assinar' });
    this.tipoOptions.push( {label: 'Assinado', value: 'Assinado' });
    this.tipoOptions.push( {label: 'Arquivado', value: 'Arquivado' });
  }

  /**
   * Submete as opções escolhidos no filtro e preenche a grid com os registros recuperados baseados
   * na opções escolhidas no filtro.
   */
  Submit(): void {
    let path = '/eventos/documentosarguardando?tecnico=' + this.sessao.tecnico;
    
    if(this.form.value.projeto){
      path += '&projeto=' + this.form.value.projeto;
    }
    
    if(this.form.value.status){
      path += '&status=' + this.form.value.status;
    }
    this.getDocumentosAg(path);
  }

  /**
   * Abre o link vinculado ao documento de uma linha determinada.
   * @param linha Linha que vai abrir o link.
   */
  abrilink(linha) {
    window.open(linha.link, '_blank');
  }

  /**
   * Altera o status do documento.
   * @param linha Linha da grid na qual o documento que vai ter seu status alterado pertence.
   */
  setStatus(linha: any): void {
    this.http.post('/eventos/'+ this.sessao.tecnico + '/?projeto=' + linha.projeto + '&codigo='+ linha.codigo, linha).subscribe(
      data => {
        const ret: any = data;
        if( ret.type === 'error' ){
          this.notificacao.error(ret.detail);
        }else{
          this.notificacao.success('Status do documento ' + linha.codigo + ' alterado.');
          this.http.post('/geral/gerarnotificacao?tecnico=' + this.sessao.tecnico,
          {
            "descricao": "O status do documento '" + linha.descricao + "' foi alterado para '"+ linha.status+"' por " + this.sessao.nameuser + "."
          }).subscribe(
            not =>{
              const response: any = not;
              if( response.code === '401'){
                this.notificacao.warning('Cadastrar notificação do analista falhou: '+ response.message);
              }
            },
            error => {
              this.notificacao.error('Erro ao tentar notificar o analista '+ this.sessao.tecnico);
            }
          );
        }
      },
      erro => {
        this.notificacao.success('Errro durante o processo de alteração do documento ' + linha.codigo);
      }
    );
  } 
}
