import { $, element } from 'protractor';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PoPageAction, PoTableAction, PoTableColumn, PoModalComponent, PoModalAction, PoRadioGroupOption, PoTableComponent, PoButtonGroupItem, PoTextareaComponent } from '@po-ui/ng-components';
import { PoNotificationService } from '@po-ui/ng-components';
import { HttpService } from '../services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { NgForm } from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AudioCaptureComponent } from '../audio-capture/audio-capture.component';

@Component({
  selector: 'app-agendamentos',
  templateUrl: './agendamentos.component.html',
  styleUrls: ['./agendamentos.component.css']
})

export class AgendamentosComponent extends BaseComponent implements OnInit, OnDestroy {

  carregandoModal: boolean = false;

  @ViewChild('poModalEmail') poModalEmail: PoModalComponent;
  @ViewChild('poModalOs') poModalOs: PoModalComponent;
  @ViewChild('laudotecnico') laudotecnico: PoTextareaComponent;

  @ViewChild(PoModalComponent, { static: true }) poModal: PoModalComponent;
  @ViewChild('optionsForm', { static: true }) form: NgForm;
  @ViewChild(PoModalComponent, { static: true }) poTable: PoTableComponent;


  constructor(
    private notitificacao: PoNotificationService,
    public httpService: HttpService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private router: Router) {
    super(httpService, '/agendasagisrn/', '');
  }
  gravandoAudio = false
  carregandoIa = false
  resumo;
  projetosel;
  clientesel;
  listaprojetos = [];
  linha: any;
  cliente: string;
  data;
  horainicial: string;
  horafinal: string;
  horaintervalo: string;
  horatranslado: string;
  laudo: string;
  laudointerno: string;
  avulso: boolean;
  listaclientes = [];
  audio: AudioCaptureComponent = new AudioCaptureComponent()
  tiposagendamento: Array<PoRadioGroupOption> = [{ label: 'Remoto', value: '3' }, { label: 'Presencial', value: '1' }];
  tipoagendamento: string;
  gerandoos = false;
  lancaavulso;
  listamotivos = [];
  motivosel;
  filters;
  pinicial = '00:00';
  pfinal = '00:00';

  tempototal = '00:00';
  tmpIniAux;
  tmpFinAux;

  ponto = {
    hora1e: '',
    hora1s: '',
    hora2e: '',
    hora2s: ''
  };
  email = {
    assunto: "",
    to: "",
    cc: "",
    msg: "",
    chamado: ""
  }

  readonly = false;

  enviar: PoModalAction = {
    action: async () => {
      this.enviar.loading = true
      const ret = await this.enviarEmail(this.email.chamado)
      this.enviar.loading = false

      if (ret) {
        this.notitificacao.success('Email enviado com sucesso.')
        this.poModalEmail.close();
      }
    },
    label: 'Enviar',
  };

  close: PoModalAction = {
    action: () => {
      this.poModalEmail.close();
      this.poModalOs.close()
    },
    label: 'Cancelar'
  };

  confirm: PoModalAction = {
    action: () => {
      this.gravaros();
    },
    label: 'Confirmar'
  };

  status = [
    { value: 'todas', label: 'Todas' },
    { value: 'pendentes', label: 'Pendentes' },
  ];

  pageactions: Array<PoPageAction> = [

  ];

  tableActions: Array<PoTableAction> = [
    { label: 'Excluir', action: this.cancelaragenda.bind(this), icon: 'po-icon po-icon-delete' },
    { label: 'Meet', action: this.abrirMeet.bind(this), icon: 'po-icon po-icon-link' },
    { label: 'Enviar OS', action: this.preparaEmail.bind(this), icon: 'po-icon po-icon-mail', disabled: (row) => { return !row.chamado || !row.chamado.nrchamado } },
  ];
   ngOnDestroy(): void {
    this.gravandoAudio = false
    if(this.audio.audioRecorder) this.audio.stopRecording(true)
  }
  async ngOnInit() {
    // this.iniciarAudio()
    this.filters = [];
    let auxfiltros;
    const dtbase = new Date();
    const datainicio = new Date(dtbase.getFullYear(), dtbase.getMonth(), 1);
    const datafinal = new Date(dtbase.getFullYear(), dtbase.getMonth() + 1, 0);
    let statusfiltro = 'todas';

    let datainiciostr = datainicio.toISOString().slice(0, 10);
    let datafinalstr = datafinal.toISOString().slice(0, 10);



    if (localStorage.getItem('filtrosagenda')) {
      auxfiltros = JSON.parse(localStorage.getItem('filtrosagenda'));
      auxfiltros.forEach(element => {
        if (element.property === 'datade') {
          datainiciostr = element.value;
        } else if (element.property === 'dataate') {
          datafinalstr = element.value;
        } else if (element.property === 'status') {
          statusfiltro = element.value;
        }
      });

    }

    this.route
      .queryParams
      .subscribe(params => {
        if (params.status) {
          this.filters.push({ property: 'status', label: 'Situação', gridColumns: 4, options: this.status, initValue: params.status });
          this.filters.push({ property: 'datade', label: 'Data inicial', gridColumns: 4, type: 'date' });
          this.filters.push({ property: 'dataate', label: 'Data final', gridColumns: 4, type: 'date' });
        } else {
          this.filters.push({ property: 'status', label: 'Situação', gridColumns: 4, options: this.status, initValue: statusfiltro });
          this.filters.push({
            property: 'datade', label: 'Data inicial', gridColumns: 4, type: 'date',
            initValue: datainiciostr
          });
          this.filters.push({
            property: 'dataate', label: 'Data final', gridColumns: 4, type: 'date',
            initValue: datafinalstr
          });
        }
      });

    // this.get();
    this.colunas = this.getColumns();
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));

    this.pageactions = [];

    // if (this.sessao.acessos.avulso && !this.sessao.acessos.terceiro) {
    this.pageactions.push({ label: 'Incluir Avulso', action: this.incluir.bind(this) });
    // }

    await this.getClientes();
    await this.getMotivos();

  }
  textButtons: Array<PoButtonGroupItem> = [
    { label: 'Com marcadores', action: this.getTextByIa.bind(this,'1') },
    { label: 'Expandir texto', action: this.getTextByIa.bind(this,'2') },
    { label: 'Casual', action: this.getTextByIa.bind(this,'3') },
    { label: 'Formal', action: this.getTextByIa.bind(this,'4') },
    { label: 'Corrigir ortografia', action: this.getTextByIa.bind(this,'5') }
  ];

  getFormatedDate(date: string) {
    return date && date.slice(0, 10);
  }

  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'data', label: 'Data' },
      {
        property: 'status',
        type: 'label',
        width: '8%',
        labels: [
          { value: 'Pendente', color: 'color-07', label: 'Pendente' },
          { value: 'Lancado', color: 'color-10', label: 'Lançado' },
          { value: 'Avulso', color: 'color-05', label: 'Lançado' },
          { value: 'Confirmado', color: 'color-01', label: 'Confirmado' },
          { value: 'NaoConfirmado', color: 'color-08', label: 'Confirmar' },
          { value: 'Ausente', color: 'color-09', label: 'Ausente' },
        ]
      },
      { property: 'details', label: 'Details', type: 'detail', detail: { columns: [{ label: 'Observação', property: 'observacao' }] } },
      { property: 'hrinicio', label: 'Inicio' },
      { property: 'hrfinal', label: 'Fim' },
      { property: 'resumo', label: 'Resumo' },
      { property: 'nomecliente', label: 'Cliente' },
      { property: 'projeto', label: 'Projeto' },
      {
        property: 'status',
        label: 'Laudo',
        type: 'icon',
        width: '80px',
        icons: [
          {
            action: this.lancamento.bind(this),
            icon: 'po-icon po-icon-edit',
            tooltip: 'Clique para Lançar',
            value: 'Pendente'
          },
          {
            action: this.lancamento.bind(this),
            icon: 'po-icon po-icon-edit',
            tooltip: 'Clique para Lançar',
            value: 'Confirmado'
          },
          {
            action: this.lancamento.bind(this),
            icon: 'po-icon po-icon-edit',
            tooltip: 'Clique para Lançar',
            value: 'NaoConfirmado'
          },
          {
            action: (value, row) => {
              this.lancamento(value);
            },
            icon: 'po-icon po-icon-edit',
            tooltip: 'Clique para Editar',
            value: 'Lancado'
          },
          {
            action: (value, row) => {
              this.lancamento(value);
            },
            icon: 'po-icon po-icon-edit',
            tooltip: 'Clique para Editar',
            value: 'Avulso'
          }
        ],
      },
      {
        property: 'status',
        label: 'Imprimir',
        type: 'icon',
        width: '80px',
        icons: [
          {
            action: this.imprimir.bind(this),
            icon: 'po-icon po-icon-download',
            tooltip: 'Imprimir',
            value: 'Lancado'
          },
        ],
      },
      { property: 'tipo', label: 'Tipo' }
    ];
  }

  imprimir(row) {

    if (row.chamado) {

      this.gerandoos = true;

      this.httpService.get('/impressaoagisrn?chamado=' + row.chamado.nrchamado).subscribe(
        data => {

          this.gerandoos = false;
          window.open(data.link, '_blank');


        },
        error => {
          this.gerandoos = false;
        },
        () => {
          this.gerandoos = false;
        }
      );
    }

  };

  abrirMeet(row) {
    window.open(row.link, '_blank');
  };

  buscaavancada(filter) {
    let disclaimer = [];



    if (filter.datade) {
      disclaimer.push({ property: 'datade', value: filter.datade });
    }
    if (filter.dataate) {
      disclaimer.push({ property: 'dataate', value: filter.dataate });
    }
    if (filter.status) {
      disclaimer.push({ property: 'status', value: filter.status });
    }

    this.changeFilter(disclaimer);
  }

  changeFilter(disclaimer) {

    let filtroaux = '';
    let valor;


    disclaimer.forEach(fil => {
      if (Array.isArray(fil.value)) {
        valor = '';
        fil.value.forEach(e => {
          valor += e + ',';
        });
      } else {
        valor = fil.value;
      }
      filtroaux += '&' + fil.property + '=' + valor;
    });

    localStorage.setItem('filtrosagenda', JSON.stringify(disclaimer));

    this.filtros = filtroaux;
    this.filtros = this.filtros.replace(/-/g, '');
    this.get();
  }

  lancamento(row) {

    this.linha = row;
    this.cliente = row.nomecliente;

    this.resumo = row.resumo
    this.motivosel = row.motivo

    if (row.remoto) this.tipoagendamento = '3'
    if (row.codcliente) {
      this.clientesel = row.codcliente + '-' + row.lojacliente
      this.getprojetoscliente(this.clientesel)
    }

    this.projetosel = row.codprojeto;

    if (this.linha.pontos) {
      this.ponto.hora1e = this.linha.pontos['1E'] ? '1E: ' + this.linha.pontos['1E'] : '';
      this.ponto.hora1s = this.linha.pontos['1S'] ? '1S: ' + this.linha.pontos['1S'] : '';
      this.ponto.hora2e = this.linha.pontos['2E'] ? '2E: ' + this.linha.pontos['2E'] : '';
      this.ponto.hora2s = this.linha.pontos['2S'] ? '2S: ' + this.linha.pontos['2S'] : '';
    } else {
      this.ponto.hora1e = '';
      this.ponto.hora1s = '';
      this.ponto.hora2e = '';
      this.ponto.hora2s = '';
    }

    const dtaux = this.linha.data.split('/');
    const dt = new Date();
    dt.setFullYear(Number('20' + dtaux[2]), Number(dtaux[1] - 1), Number(dtaux[0]));

    if (this.linha.chamado) {
      // this.readonly = true;
      this.horainicial = this.linha.chamado.horainicial;
      this.horafinal = this.linha.chamado.horafinal;
      this.horaintervalo = this.linha.chamado.horaintervalo;
      this.horatranslado = this.linha.chamado.horatranslado;
      this.laudo = this.linha.chamado.laudo;
      this.laudointerno = this.linha.chamado.laudointerno;
      this.data = dt;

      this.tempototal = this.linha.chamado.tempototal;
    } else {
      this.readonly = false;
      this.pinicial = row.hrinicio
      this.pfinal = row.hrfinal
      this.data = dt;
      this.horainicial = row.hrinicio;
      this.horafinal =  row.hrfinal;
      this.horaintervalo = '00:00';
      this.horatranslado = '';
      this.laudo = '';
      this.laudointerno = '';
      this.tempototal = '';
      this.calcularhoratotal()
      if (this.linha && this.linha.details && this.linha.details.length > 0) {
        this.laudo = this.linha.details[0].observacao
      }
    }
    this.iniciarAudio()
    this.poModal.open();
  }

  private gravaros() {
    let idOS = 0;

    if (this.clientesel.trim() == '-') {
      this.notitificacao.error('O cliente deve ser preenchido');
      return
    }

    if (this.projetosel.trim() == '') {
      this.notitificacao.error('O projeto deve ser preenchido');
      return
    }

    if (this.form.invalid) {
      this.notitificacao.error('Preencha os dados corretamente.');
    } else {

      if (this.linha && this.linha.id) {
        idOS = this.linha.id;
      }


      this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
      this.confirm.loading = true;

      this.httpService.post(this.endpoint + '/' + this.sessao.tecnico + '/' + idOS, this.form.value).subscribe(
        data => {
          this.confirm.loading = false;

          if (data['code'] != '201') {
            this.notitificacao.error(data['detail']);
          } else {
            this.form.reset();
            this.poModal.close();
            this.get();
          }
        },
        error => {
          this.confirm.loading = false;
          this.notitificacao.error('Erro ao tentar gravar laudo');
        }
      );
    }
  }

  incluir() {
    this.linha = {}
    this.readonly = false
    this.resumo = 'Inclusão de atendimento sem agendamento'
    this.pinicial = '00:00';
    this.pfinal = '00:00'
    this.tipoagendamento = '3';
    this.horainicial = '';
    this.horafinal = '';
    this.horaintervalo = '00:00';
    this.horatranslado = '';
    this.laudo = '';
    this.laudointerno = '';
    this.avulso = true;
    this.motivosel = '';
    this.projetosel = '';
    this.listaprojetos = [];
    this.clientesel = '';
    this.data = '';
    
    this.gravandoAudio = false
    if(this.audio.audioRecorder) this.audio.stopRecording(true)
    this.iniciarAudio()
    this.poModal.open();

  }

  calcularhoratotal() {
    const [horaInicio, minutoInicio] = this.horainicial.split(':').map(Number);
    const [horaFim, minutoFim] = this.horafinal.split(':').map(Number);
    const [horaIntervalo, minutoIntervalo] = this.horaintervalo.split(':').map(Number);

    let totalMinutosInicio = (horaInicio * 60) + minutoInicio;
    let totalMinutosFim = (horaFim * 60) + minutoFim;
    let totalMinutosIntervalo = (horaIntervalo * 60) + minutoIntervalo;

    // Ajustar para o caso de 24:00 que é o mesmo que 00:00
    if (horaFim === 24 && minutoFim === 0) {
      totalMinutosFim = 0;
    }

    let totalMinutosTrabalhados = totalMinutosFim - totalMinutosInicio - totalMinutosIntervalo;

    // Ajustar se totalMinutosTrabalhados for negativo (horaFinal passou pela meia-noite)
    if (totalMinutosTrabalhados < 0) {
      totalMinutosTrabalhados += 24 * 60;
    }

    let horasTrabalhadas = Math.floor(totalMinutosTrabalhados / 60);
    let minutosTrabalhados = totalMinutosTrabalhados % 60;

    this.tempototal = `${horasTrabalhadas.toString().padStart(2, '0')}:${minutosTrabalhados.toString().padStart(2, '0')}`;

  }

  cancelaragenda(linha) {

    const body = { id: linha.id };

    this.httpService.post(this.endpoint + 'cancelar', body).subscribe(
      data => {
        const ret: any = data;
        if (ret.code === '400') {
          this.notitificacao.error('Erro ao tentar cancelar agenda');
        } else {
          this.notitificacao.success('Agenda cancelada com sucesso.');
          this.get();
        }
      },
      error => {
        this.notitificacao.error('Erro ao tentar cancelar agenda');
      },
      () => {
        this.confirm.loading = false;
        this.form.reset();
      }
    );

  }

  onExpandDetail(linha) {
    this.poTable.collapse(linha);
  }

  async getprojetoscliente(cliente) {

    this.carregandoModal = true;
    this.updateActions();

    this.listaprojetos = [];
    this.projetosel = '';

    this.httpService.get('/projetos/projetoscliente/' + this.sessao.tecnico + '/projetoscliente?cliente=' + cliente).subscribe(
      data => {
        this.listaprojetos = [];
        data.items.forEach(element => {
          this.listaprojetos.push({ label: element.codigo + '-' + element.descricao, value: element.codigo, motivo: element.motivo });
        });
      },
      error => {
        this.carregando = false;
        this.carregandoModal = false;
        this.updateActions();
      },
      () => {
        this.carregando = false;
        this.carregandoModal = false;
        this.updateActions();
        // this.poModal1.open();
      }
    );
  }


  async getClientes() {

    return new Promise((resolve, reject) => {

      this.getaux('clientes').subscribe(
        data => {
          this.listaclientes = [];
          this.dadosAux = data.items;
          for (const element of this.dadosAux) {
            this.listaclientes.push({ label: element.nomecliente, value: element.codigo + '-' + element.loja });
          }
          resolve(true);
        },
        error => {
          this.dadosAux = [];
          this.carregando = false;
          reject(false);
        })
    });

  }
  async getMotivos() {

    return new Promise((resolve, reject) => {

      this.getaux('motivos').subscribe(
        data => {
          this.listamotivos = [];
          for (const element of data.items) {
            this.listamotivos.push({ label: element.descricao, value: element.codigo.trim() });
          }
          resolve(true);
          this.carregando = false;
        },
      );
    });
  }

  setMotivo(event) {
    let projeto = this.listaprojetos.find(el => el.value == event)
    if (projeto) this.motivosel = projeto.motivo
  }

  updateActions() {
    this.confirm.disabled = this.carregandoModal;
    this.close.disabled = this.carregandoModal;
  }

  async preparaEmail(row) {

    this.email.assunto = '[UF][ORDEM DE SERVIÇO]' + row.resumo.trim()
    this.email.msg = 'Segue em anexo OS do dia ' + row.data + '.'
    this.email.chamado = row.chamado.nrchamado;

    const ret = await this.getDadosEmail(row.chamado.nrchamado)

    if (ret) {
      this.poModalEmail.open()
    }

  }


  async getDadosEmail(chamado) {

    this.gerandoos = true;

    return new Promise((resolve, reject) => {

      this.httpService.get('/impressaoagisrn?chamado=' + chamado + '&mail=true&tecnico=' + this.sessao.tecnico).subscribe(
        data => {
          this.email.cc = data.cc
          this.email.to = data.to
          resolve(true);
        },
        error => {
          resolve(false);
          this.gerandoos = false;
          this.notitificacao.error('Erro ao tentar carregar dados da OS. ')
        },
        () => {
          this.gerandoos = false;
        }
      );
    });

  }


  async enviarEmail(chamado) {

    return new Promise((resolve, reject) => {

      this.httpService.post('/impressaoagisrn?chamado=' + chamado, this.email).subscribe(
        data => {
          resolve(true);
        },
        error => {
          resolve(false);
          this.notitificacao.error('Erro ao tentar enviar email. ')
        },
        () => {
        }
      );
    });
  }
  
  async getTextByIa(tipo:string, file:any = '') {
    const url = environment.urlIa
    let headers = new HttpHeaders()
    this.carregandoIa = true
    this.cdr.detectChanges();
    headers.set('Accept', '*/*');
    headers.set('Accept-Encoding', 'gzip, deflate, br');
    headers.set('Connection', 'keep-alive');
    headers.set('Content-Type', 'multipart/form-data');
    
    // headers.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('opt', tipo)
    
    if (file && file.type ) {
      formData.append('file', file)
      
    } else {
      formData.append('text', this.laudo)
    }
    formData.append('model', 'gpt-4o-2024-11-20')
    
    this.http.post(url, formData, { headers: headers }).subscribe(
      (resposta:any) => {
        if (resposta && resposta.data) {
          this.laudo = resposta.data.result
        }
        this.carregandoIa = false
        this.cdr.detectChanges();
      },
      (erro) => {
        console.error('Erro ao formatar o texto.', erro);
        this.carregandoIa = false
        this.cdr.detectChanges();
      }
    );
  }

  gravarAudio() {
    if (!this.gravandoAudio) {
      this.gravandoAudio =true
      this.audio.startRecording()
    } else {
      this.audio.stopRecording()
      this.laudotecnico.focus()
      this.gravandoAudio =false
    }
  }

  async iniciarAudio() {
    delete this.audio
    this.gravandoAudio = false
    this.audio = new AudioCaptureComponent()
    this.audio.retornarAudio.subscribe( (dados:any) => {
        const audioBlob = this.audio.audioBlob
        const audioFile = new File([audioBlob], 'audio-gravado.wav', {
          type: 'audio/wav',
        });
        this.cdr.detectChanges();
        this.getTextByIa('5', audioFile)
    })
  }
}


