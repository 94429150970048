import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import dadosaux from '../dadosaux/dados.json';
import { PoSlideItem } from '@po-ui/ng-components';


@Component({
  selector: 'app-detalhes',
  templateUrl: './detalhes.component.html',
  styleUrls: ['./detalhes.component.css']
})

export class DetalhesComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer) { }

  produto: string;
  urlvideo: any;
  titulo: any;
  definicao: [];
  funcionalidades: [];
  entrega: [];
  comousar: [];
  informacoes: [];
  links: [];
  dados: any;
  imagens: [];
  oquetestar: any;
  ajuda: any;
  mostraimagem: boolean;

  ngOnInit() {


    this.route.paramMap.subscribe(parameters => {
      this.produto = parameters.get('produto');
      this.dados = dadosaux.dados.find(e => e.nome.toUpperCase() === this.produto.toUpperCase());
      this.titulo = this.dados.titulo;
      this.definicao = this.dados.definicao;
      this.funcionalidades = this.dados.funcionalidades;
      this.informacoes = this.dados.informacoes;
      this.entrega = this.dados.entrega;
      this.comousar = this.dados.comousar;
      this.oquetestar = this.dados.oquetestar;
      this.ajuda = this.dados.ajuda;
      this.urlvideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.dados.urlvideo);
      this.links = this.dados.links;
      this.mostraimagem = this.dados.mostraimagem;
      if (this.mostraimagem) {
        this.imagens = this.dados.imagens;
      }
    });
  }

}
