<div class="po-wrapper">
    <po-toolbar p-title="Visão geral dos Projetos"></po-toolbar>  

    <po-page-default p-title=""> 

        <div class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12">
            <po-divider class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" p-label="Opções de filtro" ></po-divider>            
            <po-multiselect (p-change)="getProjetos()" p-required="false" class="po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4" name="status" p-label="Status" [p-options]="status" [(ngModel)]="statussel">  </po-multiselect>
            <po-select p-required="true" p-placeholder="Selecione o Cliente" class="po-xl-4 po-lg-4 po-md-4 po-sm-4" name="cliente"  
                (p-change)="getProjetos()" p-label="Cliente" [p-options]="listaclientes" [(ngModel)]="clientesel" ></po-select>

            <po-select p-placeholder="Selecionar Analista" p-auto-focus="true" p-required="false" class="po-md-4 po-sm-mb-4 po-mb-4 po-lg-mb-4" name="analistassel" p-label="Analistas" 
            (p-change)="getProjetos()" [p-options]="analistas" [(ngModel)]="analistasel">  </po-select>                
        </div>
                
        <div class="po-m-2 po-font-text-large">
            {{mensagem}}
        </div>

        <po-container [hidden]="tasks.length==0" class="grafico" p-no-border="true">
            <svg id="ganttChart"></svg>           
        </po-container>

        <po-button-group *ngIf="tasks.length!==0" class="po-md-12" [p-buttons]="tipovisualizacao"> </po-button-group>

    </po-page-default>

</div>
