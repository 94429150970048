<div class="po-wrapper">

    <po-page-default
    p-title="Portifólio de Produtos">

    <po-container>
    <div class="po-font-subtitle po-text-center">Bases Demonstração</div>
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="false"
            p-primary-label="Detalhes"
            p-title="Base"
            (p-primary-action)="detalhes('PROTHEUS')">
            <div class="po-font-subtitle po-text-center">PROTHEUS</div>
            <div class="po-text-center">Release 25</div>
        </po-widget>
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="false"
            p-primary-label="Detalhes"  
            p-title="Base"
            (p-primary-action)="detalhes('RM')">
            <div class="po-font-subtitle po-text-center">RM</div>
            <div class="po-text-center">RM Windows</div>
        </po-widget>
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="false"
            p-primary-label="Detalhes"
            p-title="Base"
            (p-primary-action)="detalhes('PC SISTEMAS')">
            <div class="po-font-subtitle po-text-center">PC SISTEMAS</div>
            <div class="po-text-center">Whintor</div>
        </po-widget>

        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="true"
            p-primary-label="Detalhes"
            p-title="Portal + App"
            (p-primary-action)="detalhes('MEU RH')">
            <div class="po-font-subtitle po-text-center">MEU RH</div>
            <div class="po-text-center">Portal + App - Padrão</div>
        </po-widget>
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="true"
            p-primary-label="Detalhes"  
            p-title="Portal"
            (p-primary-action)="detalhes('GCH')">
            <div class="po-font-subtitle po-text-center">GCH</div>
            <div class="po-text-center">Portal Protheus Gestão de Capital Humano</div>
        </po-widget>
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="true"
            p-primary-label="Detalhes"  
            p-title="Portal"
            (p-primary-action)="detalhes('BIGD')">
            <div class="po-font-subtitle po-text-center">BI GoodData</div>
            <div class="po-text-center">Portal Demonstração BI GoodData</div>
        </po-widget>
        
    </po-container>

    <hr>


    <po-container>
        <div class="po-font-subtitle po-text-center">Portais Customizados</div>  
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="false"
            p-primary-label="Detalhes"
            p-title="Portais"
            (p-primary-action)="detalhes('PortalClientes')">
            <div class="po-font-subtitle po-text-center">Portal do Cliente</div>
            <div class="po-text-center">Portal de canal de serviços com o cliente</div>
        </po-widget>
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="false"
            p-primary-label="Detalhes"  
            p-title="Portais"
            (p-primary-action)="detalhes('PortalFornecedor')">
            <div class="po-font-subtitle po-text-center">Portal do Fornecedor</div>
            <div class="po-text-center">Portal de canal de serviços com o fornecedor</div>
        </po-widget>
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="false"
            p-primary-label="Detalhes"
            p-title="Portais"
            (p-primary-action)="detalhes('PortalMultas')">
            <div class="po-font-subtitle po-text-center">Controle de Multas</div>
            <div class="po-text-center">Portal de Gerenciamento de Multas - RN</div>
        </po-widget>   
    </po-container>

    <po-container>

        <div class="po-font-subtitle po-text-center">Aplicativos</div>
        
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="true"
            p-primary-label="Detalhes"
            p-title="Aplicativo"
            (p-primary-action)="detalhes('Gskpi')">
            <div class="po-font-subtitle po-text-center">Gskpi</div>
            <div class="po-text-center">Aplicativo para Criação de Indicadores</div>
        </po-widget>
        
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="true"
            p-primary-label="Detalhes"  
            p-title="Aplicativo"
            (p-primary-action)="detalhes('App Veiculos')">
            <div class="po-font-subtitle po-text-center">App veículos</div>
            <div class="po-text-center">Aplicativo controle de veículos</div>
        </po-widget>
        
        <po-widget
            class="po-lg-4 po-mt-2"
            p-height="200"
            p-primary="true"
            p-primary-label="Detalhes"
            p-title="Aplicativo"
            (p-primary-action)="detalhes('App Pedidos')">
            <div class="po-font-subtitle po-text-center">App Pedidos</div>
            <div class="po-text-center">Aplicativo de pedidos Protheus</div>
        </po-widget>
        
    </po-container>
    
    </po-page-default>

</div>