<div class="po-wrapper">
    <po-page-default    
        p-title="Minhas Tarefas">
    
        <po-tabs p-small="true">
            <po-tab p-label="Detalhes" [p-active]="true">
                <po-container class="box po-xl-12 po-lg-12 po-md-12 po-sm-12" p-no-padding="true" p-no-border="true" p-no-shadow="true" >
                    <po-divider class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" [p-label]="titulo"></po-divider>
                     
                    <po-container *ngIf="descricaotarefa" class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  p-no-border="true" p-no-shadow="true">
                         {{descricaotarefa}}
                    </po-container> 
             
                    <po-container class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  p-no-padding="true" p-no-border="true" p-no-shadow="true">
    
                    <po-rich-text class="po-xl-10 po-lg-10 po-md-10 po-sm-10"  p-clean p-placeholder="Digite um comentário" name="observacao" [(ngModel)]="observacao" p-height="200"> </po-rich-text>                     
             
                    <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2">

                        <div style="height: 150px;"></div>
                        <po-checkbox [(ngModel)]="pendencia" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="pendencia" p-label="Salvar como Pendência"> </po-checkbox>
                        <po-datepicker *ngIf="pendencia" p-help="Data Criação" p-required="false" class="po-mt-5 po-xl-12 po-lg-12 po-md-12 po-sm-12" name="datafim" [(ngModel)]="datacriacao"></po-datepicker>                                                  
                        <po-checkbox [(ngModel)]="topo" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" name="topo" p-label="Manter no topo"> </po-checkbox>
                        <po-button [p-disabled]="carregando" class="po-xl-12 po-lg-12 po-md-12 po-sm-12" 
                            p-icon="po-icon po-icon-ok"  p-small="true" p-label="Salvar" (p-click)="salvacomentario()"> </po-button>
                    </div>
                    
                    </po-container> 
             
                 </po-container>                

                 <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12" p-label="Histórico da tarefa"></po-divider>

                 <po-container class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  p-no-border="true" p-no-shadow="true">
                     
                     <div *ngFor="let item of historico; let i=index" >
                         <div class="po-row">
                             <div class="po-font-text-bold po-xl-1 po-lg-1 po-md-1 po-sm-1">
                                 <div class="po-font-text-small-bold po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                    {{item.nometec}}
                                </div> 
                                 <div class="po-font-text-small-bold po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                    {{item.dtgrava + ' ' + item.hrgrava}}
                                </div> 
                                
                                <!-- <po-button class="po-font-text-small-bold po-xl-12 po-lg-12 po-md-12 po-sm-12"
                                    (p-click)="deletarhistorico(item)"                                    
                                    p-icon="po-icon po-icon-delete"                                    
                                    p-small="false"
                                    p-type="link" >
                                </po-button>                                 -->

                            </div>                           

                            <div class="po-font-text po-xl-11 po-lg-11 po-md-11 po-sm-11">
                                 
                                 <div *ngIf="item.statusde !== item.statuspara" class="po-font-text po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                     {{' Moveu de ' + item.statusde + ' para ' + item.statuspara }}                
                                 </div>
             
                                 <div *ngIf="item.tipo==='1'" class="po-font-text po-xl-12 po-lg-12 po-md-12 po-sm-12">                        
                                     <div class="po-font-text-bold">
                                         Execução de tarefa
                                     </div>
                                     <div class="po-font-text">
                                         <b>Data: </b>
                                         {{item.data}}
                                         <b>Duração: </b>
                                         {{item.duracao}}
                                     </div>                        
                                 </div>
             
                                 <po-container                              
                                    p-no-border="true"
                                    p-no-padding="true"
                                    p-no-shadow="true"
                                    *ngIf="item.observacao" class="po-font-text po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                    <div [innerHtml]="item.observacao">
                                    </div>
                                </po-container>
    
                             </div>

                         </div>
                         <po-divider class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12"></po-divider>            
                     </div>
                 
                 </po-container>  
    
            </po-tab>
            <po-tab p-label="Anexos">
                <app-anexos 
                    [chave]="codigo" [arquivos]="listaanexos" diretorio="tarefas" [pathanexos]="pathanexos" [anexoativo]="true">
                </app-anexos>
            </po-tab>
        </po-tabs>
    
        <po-loading-overlay
            [hidden]="!carregando"            
            p-screen-lock="true"
            p-text="Carregando" >
        </po-loading-overlay>   

    </po-page-default>

</div>