<div class="po-wrapper">
    <po-page-default    
        p-title="Minhas Tarefas" [p-actions]="pageactions">  

        <po-loading-overlay
            [hidden]="!carregando"            
            p-screen-lock="true"
            p-text="Carregando" >
        </po-loading-overlay>   

        <div class="po-row">
            <po-disclaimer-group class="po-xl-12 po-lg-12 po-md-12 po-sm-12"
                p-title="Filtros ativos"
                (p-change)="removefiltro($event)"
                [p-disclaimers]="listafiltros">            
            </po-disclaimer-group>
        </div>

        <po-divider></po-divider>
        
        <div cdkDropListGroup>

            <po-widget            
                class="widget po-xl-3 po-lg-3 po-md-3 po-sm-3"
                p-title="Aguardando">                                  
                <div id="0" class="lista" cdkDropList [cdkDropListData]="aguardando" (cdkDropListDropped)="drop($event)">
                    <div (click)="detalhetarefa(item)" class="boxtarefa po-xl-12 po-lg-12 po-md-12 po-sm-12"  *ngFor="let item of aguardando; let i=index" cdkDrag>                
                        <po-container  p-no-padding="true" p-no-border="true" class="box po-xl-12 po-lg-12 po-md-12 po-sm-12">                       
                            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <po-tag p-color="color-01" p-value="{{item.tituloprojeto}}"> </po-tag>                       
                                <po-divider></po-divider>
                                <div class="ordem po-font-text-small-bold">
                                    {{item.ordem + ' - ' + item.titulo}}
                                </div>                        
                                <div class="po-font-text-small">
                                    {{item.descricao.substring(0,150) + ((item.descricao.length > 150) ? '...' : '' )}}                       
                                </div>     
                            </div>
                            <div *ngIf="item.tecnico" class="tagtecnico po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <po-tag p-color="color-01" p-value="{{item.tecnico}}" p-inverse></po-tag>                                               
                            </div>
                        </po-container>
                    </div>
                </div>                                    
            </po-widget>
            
            <po-widget class="widget po-xl-3 po-lg-3 po-md-3 po-sm-3"
                p-title="Fazer">                              
                <div id="1"  class="lista" cdkDropList [cdkDropListData]="afazer" (cdkDropListDropped)="drop($event)">
                    <div (click)="detalhetarefa(item)" class="boxtarefa po-xl-12 po-lg-12 po-md-12 po-sm-12"  *ngFor="let item of afazer; let i=index" cdkDrag>                
                        <po-container p-no-padding="true" p-no-border="true" class="box po-xl-12 po-lg-12 po-md-12 po-sm-12">                       
                            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <po-tag p-color="color-01" p-value="{{item.tituloprojeto}}"> </po-tag>                       
                                <po-divider></po-divider>
                                <div class="ordem po-font-text-small-bold">
                                    {{item.ordem + ' - ' + item.titulo}}
                                </div>                        
                                <div class="po-font-text-small">
                                    {{item.descricao.substring(0,150) + ((item.descricao.length > 150) ? '...' : '' )}}                       
                                </div>     
                            </div>
                            <div *ngIf="item.tecnico" class="tagtecnico po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <po-tag p-color="color-01" p-value="{{item.tecnico}}" p-inverse></po-tag>                                               
                            </div>
                        </po-container>
                    </div>
                </div>                                    
            </po-widget>
            <po-widget class="widget po-xl-3 po-lg-3 po-md-3 po-sm-3"
                p-title="Fazendo">                              
                <div id="2" class="lista" cdkDropList [cdkDropListData]="fazendo" (cdkDropListDropped)="drop($event)">
                    <div (click)="detalhetarefa(item)" class="boxtarefa po-xl-12 po-lg-12 po-md-12 po-sm-12"  *ngFor="let item of fazendo; let i=index" cdkDrag>                
                        <po-container p-no-padding="true" p-no-border="true" class="box po-xl-12 po-lg-12 po-md-12 po-sm-12">                       
                            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <po-tag p-color="color-01" p-value="{{item.tituloprojeto}}"> </po-tag>                       
                                <po-divider></po-divider>
                                <div class="ordem po-font-text-small-bold">
                                    {{item.ordem + ' - ' + item.titulo}}
                                </div>                        
                                <div class="po-font-text-small">
                                    {{item.descricao.substring(0,150) + ((item.descricao.length > 150) ? '...' : '' )}}                       
                                </div>     
                            </div>
                            <div *ngIf="item.tecnico" class="tagtecnico po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <po-tag p-color="color-01" p-value="{{item.tecnico}}" p-inverse></po-tag>                                               
                            </div>
                        </po-container>
                    </div>
                </div>                                    
            </po-widget>
            <po-widget class="widget po-xl-3 po-lg-3 po-md-3 po-sm-3"
                p-title="Feito">                              
                <div id="3" class="lista" cdkDropList [cdkDropListData]="feito" (cdkDropListDropped)="drop($event)">
                    <div (click)="detalhetarefa(item)" class="boxtarefa po-xl-12 po-lg-12 po-md-12 po-sm-12"  *ngFor="let item of feito; let i=index" cdkDrag>                
                        <po-container p-no-padding="true" p-no-border="true" class="box po-xl-12 po-lg-12 po-md-12 po-sm-12">                       
                            <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <po-tag p-color="color-01" p-value="{{item.tituloprojeto}}"> </po-tag>                       
                                <po-divider></po-divider>
                                <div class="ordem po-font-text-small-bold">
                                    {{item.ordem + ' - ' + item.titulo}}
                                </div>                        
                                <div class="po-font-text-small">
                                    {{item.descricao.substring(0,150) + ((item.descricao.length > 150) ? '...' : '' )}}                       
                                </div>     
                            </div>
                            <div *ngIf="item.tecnico" class="tagtecnico po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <po-tag p-color="color-01" p-value="{{item.tecnico}}" p-inverse></po-tag>                                               
                            </div>
                        </po-container>
                    </div>
                </div>                                    
            </po-widget>
     </div>
    </po-page-default>    
</div>    

<po-modal #poModal1 p-title="Dados de Execução da Tarefa" [p-primary-action]="confirm" [p-secondary-action]="close" p-hide-close="false">
    
    <div class="po-xl-6 po-lg6 po-md6 po-sm-6 po-font-text-small-bold">
        Início da Tarefa: {{datahrinicio}}
    </div>
    
    <po-divider class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12"></po-divider>
    
    <po-divider class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" [p-label]="descricaotarefa"></po-divider>
    
    <po-input  p-auto-focus="true" p-mask-format-model="true" p-placeholder="00:00" p-required="true" p-error-pattern="Hora Inválida" 
        p-mask="99:99" p-maxlength=5 p-minlength=5 class="po-xl-6 po-lg-6 po-md-6 po-sm-6" name="duracao"  [(ngModel)]="duracao" p-label="Duração da Tarefa"></po-input>    

    <po-datepicker p-required="true" class="po-xl-6 po-lg-6 po-md-6 po-sm-6"  name="data" p-label="Data" [(ngModel)]="data"></po-datepicker>                                 

    <po-textarea  p-placeholder="Preecha uma observação caso necessário" p-required="false" [(ngModel)]="observacao" 
        class="po-md-12" name="observacao"  p-label="Observação" p-rows="6"></po-textarea>

</po-modal>

<po-modal #poModal2 p-title="Detalhes da Tarefa" >

    <po-tabs p-small="true">
        <po-tab p-label="Histórico" [p-active]="true">
            <po-container class="box po-xl-12 po-lg-12 po-md-12 po-sm-12" p-no-padding="true" p-no-border="true" p-no-shadow="true" >
                <po-divider class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" [p-label]="titulo"></po-divider>
                 
                <po-container *ngIf="descricaotarefa" class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  p-no-border="true" p-no-shadow="true">
                     {{descricaotarefa}}
                </po-container> 
         
                <po-container class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  p-no-padding="true" p-no-border="true" p-no-shadow="true">

                <po-rich-text p-placeholder="Digite um comentário" name="observacao" [(ngModel)]="comentario" p-height="262"> </po-rich-text>                     
         
                 <po-checkbox [(ngModel)]="pendencia" class="po-xl-6 po-lg-6 po-md-6 po-sm-6" name="pendencia" p-label="Salvar como Pendência"> </po-checkbox>
                 
                 <po-button [p-disabled]="carregando" class="po-offset-xl-4 po-offset-sm-4 po-offset-md-4 po-offset-lg-4 po-xl-2 po-lg-2 po-md-2 po-sm-2" 
                 p-icon="po-icon po-icon-ok"  p-small="true" p-label="Salvar" (p-click)="salvacomentario()"> </po-button>
                 
                 <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12"></div>
                 <po-datepicker *ngIf="pendencia" p-help="Data Criação" p-required="false" class="po-xl-3 po-lg-3 po-md-3 po-sm-3" name="datafim" [(ngModel)]="datacriacao"></po-datepicker>                                                  
         
                
                </po-container> 
         
             </po-container>                
         
             
             <po-container class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  p-no-border="true" p-no-shadow="true">
                 
                 <div *ngFor="let item of historico; let i=index" >
                     <div class="po-row">
                         <div class="po-font-text-bold po-xl-2 po-lg-2 po-md-2 po-sm-2">
                             {{item.nometec}}
                         </div>
                         <div class="po-font-text po-xl-8 po-lg-8 po-md-8 po-sm-8">
                             
                             <div *ngIf="item.statusde !== item.statuspara" class="po-font-text po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                 {{' Moveu de ' + item.statusde + ' para ' + item.statuspara }}                
                             </div>
         
                             <div *ngIf="item.tipo==='1'" class="po-font-text po-xl-12 po-lg-12 po-md-12 po-sm-12">                        
                                 <div class="po-font-text-bold">
                                     Execução de tarefa
                                 </div>
                                 <div class="po-font-text">
                                     <b>Data: </b>
                                     {{item.data}}
                                     <b>Duração: </b>
                                     {{item.duracao}}
                                 </div>                        
                             </div>
         
                             <po-container                              
                                p-no-border="true"
                                p-no-padding="true"
                                p-no-shadow="true"
                                *ngIf="item.observacao" class="po-font-text po-xl-12 po-lg-12 po-md-12 po-sm-12">
                                <div [innerHtml]="item.observacao">
                                </div>
                            </po-container>

                         </div>
                         <div class="po-font-text-small-bold po-xl-2 po-lg-2 po-md-2 po-sm-2">
                             {{item.dtgrava + ' ' + item.hrgrava}}
                         </div>
                     </div>
                     <po-divider class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12"></po-divider>            
                 </div>
             
             </po-container>  

        </po-tab>
        <po-tab p-label="Anexos">
            <app-anexos 
                [chave]="codigo" [arquivos]="listaanexos" diretorio="tarefas" [pathanexos]="pathanexos" [anexoativo]="true">
            </app-anexos>
        </po-tab>
    </po-tabs>

</po-modal>

<po-modal #poModal3 p-title="Filtrar" [p-primary-action]="confirmarfiltro" [p-secondary-action]="close" p-hide-close="false">
    <po-select p-placeholder="Selecione o Analista" p-optional="true" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" name="analista"  p-label="Analista"  [(ngModel)]="analistasel"  [p-options]="analistas"></po-select>                    
    <po-select p-placeholder="Selecione o Cliente" p-optional="true" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" name="cliente"   p-label="Cliente"  [(ngModel)]="clientesel"  [p-options]="clientes"></po-select>                    
    <po-select p-placeholder="Selecione o Projeto" p-optional="true" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12" name="analista"   p-label="Projeto"  [(ngModel)]="projetosel"  [p-options]="projetos"></po-select>                    
</po-modal>