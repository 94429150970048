import { OrdensServicoComponent } from './ordens-servico/ordens-servico.component';
import { NovoTicketComponent } from './novo-ticket/novo-ticket.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TarefasDetalhesComponent } from './tarefas-detalhes/tarefas-detalhes.component';
import { TarefasAnalistaComponent } from './tarefas-analista/tarefas-analista.component';
import { StatusreportComponent } from './statusreport/statusreport.component';
import { EventosComponent } from './eventos/eventos.component';
import { TarefaOrdemComponent } from './tarefa-ordem/tarefa-ordem.component';
import { cronogramaComponent } from './cronograma/cronograma.component';
import { BcComponent } from './bc/bc.component';
import { AgendamentosComponent } from './agendamentos/agendamentos.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortifolioComponent } from './portifolio/portifolio.component';
import { DetalhesComponent } from './detalhes/detalhes.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth.guard';
import { environment } from 'src/environments/environment';
import { ProjetosComponent } from './projetos/projetos.component';
import { TarefasComponent } from './tarefas/tarefas.component';
import { ProjetosVisaogeralComponent } from './projetos-visaogeral/projetos-visaogeral.component';
import { AgendamentosConsultaComponent } from './agendamentos-consulta/agendamentos-consulta.component';
import { DetalheTicketComponent } from './detalhe-ticket/detalhe-ticket.component';
import { PainelRedmineComponent } from './painel-redmine/painel-redmine.component';
import { UltimasTarefasComponent } from './ultimas-tarefas/ultimas-tarefas.component';
import { DocumentosAguardoComponent } from './documentos-aguardo/documentos-aguardo.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard]  },
  { path: 'login', component: LoginComponent, data: {
      // serviceApi: environment.urlApi + '/authagisrn/login',
      // environment: 'User Function',
  } },
  { path: 'agendamentos'        , component: AgendamentosComponent, canActivate: [AuthGuard] },
  { path: 'portifolio'          , component: PortifolioComponent, canActivate: [AuthGuard] },
  { path: 'bc'                  , component: BcComponent, canActivate: [AuthGuard] },
  { path: 'detalhes/:produto'   , component: DetalhesComponent, canActivate: [AuthGuard] },
  { path: 'projetos'            , component: ProjetosComponent, canActivate: [AuthGuard] },
  { path: 'ordens'              , component: OrdensServicoComponent, canActivate: [AuthGuard] },
  { path: 'cronograma/:projeto' , component: cronogramaComponent, canActivate: [AuthGuard] },
  { path: 'tarefas/:projeto'    , component: TarefasComponent, canActivate: [AuthGuard] },
  { path: 'ordem'               , component: TarefaOrdemComponent, canActivate: [AuthGuard]},
  { path: 'eventos/:projeto'    , component: EventosComponent, canActivate: [AuthGuard]},
  { path: 'statusreport/:projeto', component: StatusreportComponent, canActivate: [AuthGuard]},
  { path: 'tarefasanalista'     , component: TarefasAnalistaComponent, canActivate: [AuthGuard]},
  { path: 'tarefasanalista/:tarefa', component: TarefasDetalhesComponent, canActivate: [AuthGuard]},
  { path: 'visaogeral'          , component: ProjetosVisaogeralComponent, canActivate: [AuthGuard]},
  { path: 'agendaconsulta'      , component: AgendamentosConsultaComponent, canActivate: [AuthGuard]},
  { path: 'tickets'             , component: TicketsComponent, canActivate: [AuthGuard]},
  { path: 'consulta/:ticket'    , component: DetalheTicketComponent, canActivate: [AuthGuard] },
  { path: 'ticket'              , component: NovoTicketComponent, canActivate: [AuthGuard] },
  { path: 'painel-redmine'      , component: PainelRedmineComponent, canActivate: [AuthGuard] },
  { path: 'ultimas-tarefas'     , component: UltimasTarefasComponent, canActivate: [AuthGuard] },
  { path: 'documentos-aguardo'     , component: DocumentosAguardoComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
