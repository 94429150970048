import { DetalheTicketComponent } from './detalhe-ticket/detalhe-ticket.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PoModule  } from '@po-ui/ng-components';
import { RouterModule } from '@angular/router';
import { PortifolioComponent } from './portifolio/portifolio.component';
import { DetalhesComponent } from './detalhes/detalhes.component';
import { LoginComponent } from './login/login.component';
import { PoPageLoginModule, PoModalPasswordRecoveryModule, PoPageDynamicTableModule,
  PoPageDynamicSearchModule, PoPageDynamicDetailModule   } from '@po-ui/ng-templates';
import { AuthGuard } from './services/auth.guard';
import { HomeComponent } from './home/home.component';
import { AgendamentosComponent } from './agendamentos/agendamentos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BcComponent } from './bc/bc.component';
import { cronogramaComponent } from './cronograma/cronograma.component';
import { AgGridModule } from 'ag-grid-angular';
import { ProjetosComponent } from './projetos/projetos.component';
import { TarefasComponent } from './tarefas/tarefas.component';
import { TarefaOrdemComponent } from './tarefa-ordem/tarefa-ordem.component';
import { DragDropModule  } from '@angular/cdk/drag-drop';
import { EventosComponent } from './eventos/eventos.component';
import { StatusreportComponent } from './statusreport/statusreport.component';
import { ChartsModule } from 'ng2-charts';
import { TarefasAnalistaComponent } from './tarefas-analista/tarefas-analista.component';
import { ProjetosVisaogeralComponent } from './projetos-visaogeral/projetos-visaogeral.component';
import { AnexosComponent } from './anexos/anexos.component';
import { TarefasDetalhesComponent } from './tarefas-detalhes/tarefas-detalhes.component';
import { AgendamentosConsultaComponent } from './agendamentos-consulta/agendamentos-consulta.component';
import { PoStorageModule } from '@po-ui/ng-storage';
import { TicketsComponent } from './tickets/tickets.component';
import { NovoTicketComponent } from './novo-ticket/novo-ticket.component';
import { PainelRedmineComponent } from './painel-redmine/painel-redmine.component';
import { UltimasTarefasComponent } from './ultimas-tarefas/ultimas-tarefas.component';
import { DocumentosAguardoComponent } from './documentos-aguardo/documentos-aguardo.component';
import { OrdensServicoComponent } from './ordens-servico/ordens-servico.component';
import { InterceptorModule } from './interceptors/interceptor.module';
import { AudioCaptureComponent } from './audio-capture/audio-capture.component';

@NgModule({
  declarations: [
    AppComponent,
    PortifolioComponent,
    DetalhesComponent,
    LoginComponent,
    HomeComponent,
    AgendamentosComponent,
    BcComponent,
    cronogramaComponent,
    ProjetosComponent,
    TarefasComponent,
    TarefaOrdemComponent,
    EventosComponent,
    StatusreportComponent,
    TarefasAnalistaComponent,
    ProjetosVisaogeralComponent,
    AnexosComponent,
    TarefasDetalhesComponent,
    AgendamentosConsultaComponent,
    TicketsComponent,
    DetalheTicketComponent,
    NovoTicketComponent,
    PainelRedmineComponent,
    UltimasTarefasComponent,
    DocumentosAguardoComponent,
    OrdensServicoComponent,
    AudioCaptureComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PoModule,
    BrowserAnimationsModule,
    PoPageLoginModule,
    PoModalPasswordRecoveryModule,
    PoPageDynamicTableModule,
    PoPageDynamicSearchModule,
    PoPageDynamicDetailModule,
    FormsModule,
    InterceptorModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    DragDropModule,
    ChartsModule,
    PoStorageModule.forRoot({
      name: 'mystorage',
      storeName: '_mystore',
      driverOrder: ['localstorage']
    })
  ],
  providers: [AuthGuard, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
