import { PoModalAction, PoModalComponent, PoMultiselectOption, PoNotificationService, PoSelectOption } from '@po-ui/ng-components';
import { HostListener } from "@angular/core";
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-detalhe-ticket',
  templateUrl: './detalhe-ticket.component.html',
  styleUrls: ['./detalhe-ticket.component.css']
})
export class DetalheTicketComponent implements OnInit {

  @ViewChild('poModalAnexo') poModalAnexo: PoModalComponent;
  @ViewChild('poModalDuracao') poModalDuracao: PoModalComponent;
  @ViewChild('poModalAceite') poModalAceite: PoModalComponent;

  screenHeight: number;
  screenWidth: number;

  constructor(
    private rota: ActivatedRoute, 
    private http: HttpService,
    private notifica: PoNotificationService
    ) {
      this.getScreenSize();
      this.showMe = (this.screenWidth < 481) ? false : true;
    }

  ticket;
  corstatus = '';
  comentario = '';
  carregando;
  interno = false;
  showMe = true;

  detalheticket = {
    anexos: [],
    assunto: "-",
    atualizado: "-",
    inicio: "-",
    pathanexos: "",
    status: "-",
    ticket: "-",
    descricao: "",
    interno: false,
    codigo: '',
    statusinterno: '0',
    classificacao: '-',
    projeto: '-',
    usuario: '-',
    nomecliente: '-',
    analista: '',    
    tipo: '',
    coduser: ''    
  }

  sessao;

  sequencias = [];

  statusinterno;
  classifica;
  mudarnivel = false;

  contatos = []
  contatossel;
 
  tipos: Array<PoSelectOption> = [
    { value: '1', label: 'Dúvida' },
    { value: '2', label: 'Erro Padrão' },
    { value: '3', label: 'Erro Custom' },
    { value: '4', label: 'Melhoria' },
    { value: '5', label: 'Manutençao de Custom' },
    { value: '6', label: 'Cloud' },
    { value: '7', label: 'Desconhecimento da Rotina' },
    { value: '8', label: 'Input Errado' },
    { value: '9', label: 'Sistemas Integrados' },
    { value: 'A', label: 'Solicitação' },
  ];


  classificacao: Array<PoSelectOption> = [
    { value: 'N'  , label: 'Normal'},
    { value: 'U'  , label: 'Urgente'},
    { value: 'A'  , label: 'Alto'},
    { value: 'B'  , label: 'Baixo'}
  ];

  listastatus: Array<PoSelectOption> = [
    { value: '2'  , label: 'Em Atendimento'},
    { value: '3'  , label: 'Aguardando Cliente'},
    { value: '4'  , label: 'Finalizado'}
  ];

  status;
  projeto;  
  listaanalistas = [];
  analista;
  duracao;
  tipo;
  textoaceite;
  geraros = true;
  atividade;
  subatividade;
  inicio;
  final;
  laudo;
  intervalo;
  dataos;

  listaprojetos: Array<PoSelectOption> = [
  ];

  toogleDetalhes() {
    this.showMe = !this.showMe;
  }
 
  close: PoModalAction = {
    action: () => {
        this.poModalDuracao.close();
    },
    label: 'Cancelar',
  };

  confirm: PoModalAction = {
    action: () => {
      
      let erro = '';
      
      if (this.geraros) {
        if (!this.atividade) {
          erro = 'A Atividade deve ser informada.';
        }
        if (!this.subatividade) {
          erro = 'A Subatividade deve ser informada.';
        }
        if (!this.inicio) {
          erro = 'A hora inicial deve ser informada.';
        }
        if (!this.final) {
          erro = 'A hora final deve ser informada.';
        }
        if (!this.intervalo) {
          erro = 'O intervalo deve ser informado, caso não exista, preencha com 00:00.';
        }
        if (!this.laudo) {
          erro = 'O Laudo deve ser informado';
        }
      } else {
        if (!this.duracao) {
          erro = 'A duração deve ser informada';
        }
      }
      
      if (!erro) {
        this.salvarSequencia();
        this.salvaralteracao(false);
        this.poModalDuracao.close();
      } else {
        this.notifica.error(erro)
      }      
    },
    label: 'Confirmar'
  };

  closeAceite: PoModalAction = {
    action: () => {
        this.poModalAceite.close();
    },
    label: 'Cancelar',
  };

  confirmAceite: PoModalAction = {
    action: () => {     

      this.comentario = '[Solicitação de Aceite Comercial] </br></br>' + this.textoaceite 
      this.interno = true;     
      this.salvarSequencia();
      this.interno = false;
      this.comentario = '';

      this.poModalAceite.close();
    },
    label: 'Confirmar'
  };

  url = environment.urlHttp;

  async ngOnInit(): Promise<void> {
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    this.rota.paramMap.subscribe(parameters => {
      this.ticket = parameters.get('ticket');
    });
    this.carregando = true;
    await this.getAnalistas();
    await this.getSequencias();
    await this.getProjetos();
    await this.getContatos();

    this.carregando = false;
  }

  async getProjetos() {

    return new Promise((resolve, reject) => {
      this.http.get('/projetos/projetoscliente/' + this.sessao.tecnico + '/projetoscliente?cliente='+ this.detalheticket.codigo).subscribe(
        data => {
          const ret: any = data;
          ret.items.forEach(element => {
            this.listaprojetos.push({label: element.descricao, value: element.codigo});
          });
          this.projeto = this.detalheticket.projeto;
          resolve(true);
        },
        error => {
          this.notifica.error('Erro ao tentar listar projetos do cliente.')
          reject(true);
        }
      )
    });  

  }

  async getAnalistas() {

    return new Promise((resolve, reject) => {

      this.http.get('/projetos/tarefas/' + this.sessao.tecnico + '/analistas').subscribe(
        data => {
          data.items.forEach(element => {
            this.listaanalistas.push({label: element.nome, value: element.codigo});
          });
          resolve(true);
        },
        error => {
          reject(false);
        }
      );
  
    });  

  }

  async getSequencias() {
    
    return new Promise((resolve, reject) => {
      this.http.get('/atendimento/sequencia?ticket=' + this.ticket).subscribe(
        data => {
          const ret: any = data;
          this.sequencias = ret.items;
          this.detalheticket = ret.ticket;
          this.classifica = this.detalheticket.classificacao.substring(0,1)
          this.analista = this.detalheticket.analista;
          this.tipo = this.detalheticket.tipo;
          this.statusinterno = this.detalheticket.statusinterno;

          if (this.detalheticket.status === 'Novo') {
            this.corstatus =  'color-10';
            this.status = '1'
          } 
          if (this.detalheticket.status === 'Em Atendimento') {
            this.corstatus =  'color-02';
            this.status = '2'
          }
          if (this.detalheticket.status === 'Aguardando Cliente') {
            this.corstatus =  'color-07';
            this.status = '3'
          }
          if (this.detalheticket.status === 'Finalizado') {
            this.corstatus =  'color-08';
            this.status = '4'
          }
          
          this.contatossel = ret.ticket.contatos.map(el => el.value)

          resolve(true);
        },
        error => {
          this.notifica.error('Erro ao tentar listar sequências.')
          reject(true);
        }
      )
    });  
  }

  salvaralteracao(savecontatos: boolean): void {

    const body = {
      classifica: this.classifica,
      projeto: this.projeto,
      statusinterno: this.statusinterno,
      status: this.status,
      analista: this.analista,
      tipo: this.tipo,
      contatossel: null
    }

    console.log(this.contatossel);

    if (savecontatos && this.contatossel.length > 0 ) {
      body.contatossel = this.contatossel;
    }

    this.carregando = true;
    this.http.post('/atendimento/ticket?analista='+this.sessao.tecnico + '&ticket=' + this.ticket, body).subscribe(
      data => {
        this.getSequencias();
        this.comentario = '';
        this.carregando = false;
      },
      error => {
        this.carregando = false;
        this.notifica.error('Erro ao tentar gravar dados do ticket.')
      }
    )

  }

  
  async getContatos() {

    console.log(this.detalheticket.coduser);

    return new Promise((resolve, reject) => {

      this.http.get('/atendimento/usuarios?cliente='+this.detalheticket.codigo).subscribe(
        data => {
          this.contatos = [];
          for (const contato of data.items ) {
            if (this.detalheticket.coduser != contato.id)
              this.contatos.push({label: contato.nome.trim(), value: contato.id});
          }
          resolve(true);
          this.carregando = false;
        },
        error => {
          this.carregando = false;
          const ret: any = error;
          this.notifica.error(error.message);
          reject(false);
        })
      });  

  }


  novoAnexo(): void {
    this.poModalAnexo.open();
  }

  salvarSequencia(): void {

    const body = {
        ticket: this.ticket,
        descricao: this.comentario,
        interno: this.interno,
        duracao: this.duracao,
        os: {
          geraros: this.geraros,
          inicio: this.inicio,
          final: this.final,
          atividade: this.atividade,
          subatividade: this.subatividade,
          laudo: this.comentario,
          dataos: this.dataos,
          intervalo: this.intervalo
        }
    }

    this.http.post('/atendimento/sequencia?analista='+this.sessao.tecnico, body).subscribe(
      data => {
        let ret = data;
        if (ret['code'] == '401') {
          this.notifica.error(ret['message'])
        }
        this.getSequencias();
        this.comentario = '';
      },
      error => {
        this.notifica.error('Erro ao tentar gravar nova sequência.')
      }
    )

  }

  setDuracao(): void {
    this.dataos = new Date(),
    this.geraros = true;
    this.duracao = '';
    this.laudo = 'Atendimento do ticket #' + this.ticket;
    this.atividade = ''; 
    this.subatividade = '';
    this.inicio = '';
    this.final = '';
    this.intervalo = '00:00';
    this.poModalDuracao.open();
  }

  aceiteComercial(): void {
    this.poModalAceite.open();
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
          console.log(this.screenHeight, this.screenWidth);
    }

} 
