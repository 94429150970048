<div class="po-wrapper">
    <po-toolbar [p-title]="titulo"></po-toolbar>      
    <po-page-default    
    p-title="Ordem das Tarefas"> 
        <po-widget class="po-xl-12 po-lg-12 po-md-12 po-sm-12"
        p-title="Reordenação de Tarefas"            
        p-primary-label="Confirmar"          
            p-secondary-label="Cancelar"              
            (p-primary-action)="confirmar()"
            (p-secondary-action)="cancelar()">        
            <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2">
                <div class="lista">
                    <div [ngClass]="{'box1': i % 2 === 0, 'box2': i % 2 !== 0 }"  *ngFor="let ordem of ordens; let i=index" >
                        <po-tag p-color="{{tipos[i] === 'S' ? 'color-02' : 'color-01'}}" p-value="{{tipos[i] === 'S' ? 'Sintético' : 'Analítico'}} "> </po-tag>
                    {{ordem}}
                    </div>
                </div>
            </div>       
            <div class="po-xl-10 po-lg-10 po-md-10 po-sm-10">
                <div cdkDropList class="lista" (cdkDropListDropped)="drop($event)">
                    <div [ngClass]="{'box1': i % 2 === 0, 'box2': i % 2 !== 0}" *ngFor="let tarefa of tarefas; let i=index" cdkDrag>
                    <div class="placeholder" *cdkDragPlaceholder></div>
                    {{tarefa}}
                    </div>
                </div>
            </div>        
        </po-widget>
    </po-page-default>    
</div>    
