import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { HttpService } from '../services/http.service';
import { ActivatedRoute } from '@angular/router';
import { PoTableColumn } from '@po-ui/ng-components';


@Component({
  selector: 'app-statusreport',
  templateUrl: './statusreport.component.html',
  styleUrls: ['./statusreport.component.css']
})
export class StatusreportComponent implements OnInit {

  idprojeto;
  spi;

  optionsChart: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };

  typeChart: ChartType = 'horizontalBar';
  legendChart = true;

  tarefasqtdData: ChartDataSets[] = [
    { data: [0], label: 'Realizadas' , stack: 'a' },
    { data: [0] , label: 'Pendentes'  , stack: 'a' },
  ];

  tarefashrsData: ChartDataSets[] = [
    { data: [0], label: 'Realizadas' , stack: 'a' },
    { data: [0] , label: 'Pendentes'  , stack: 'a' },
  ];

  labelsChart: string[] = ['Tarefas'];

  pendencias = [];
  riscos = [];
  documentos = [];
  andamento = [];
  proximas = [];
  totalquantidade;
  totalhoras;
  titulo;

  colpendencias: Array<PoTableColumn> = [
    { property: 'descricao', label: 'Descrição'},
    { property: 'data1', label: 'Inicio' },
    { property: 'data2', label: 'Fim' },
    {
      property: 'status',
      type: 'label',
      width: '10%',
      labels: [
        { value: 'Pendente'   , color: 'color-07', label: 'Pendente'  },
        { value: 'Finalizada' , color: 'color-10', label: 'Finalizada'},
      ]
    },
   ];

   colriscos: Array<PoTableColumn> = [
    { property: 'descricao', label: 'Descrição'},
    { property: 'acao', label: 'Açao' },
    {
      property: 'status',
      type: 'label',
      width: '10%',
      labels: [
        { value: 'Alto'   , color: 'color-07', label: 'Alto'},
        { value: 'Baixo' , color: 'color-10', label: 'Baixo'},
        { value: 'Médio' , color: 'color-08', label: 'Médio'},
      ]
    },
   ];

   coldocumentos: Array<PoTableColumn> = [
    { property: 'descricao', label: 'Descrição', type: 'link', action: (link, linha) => this.abrilink(link, linha)},
    { property: 'data1', label: 'Criaçao' },
    { property: 'data2', label: 'Assinatura' },
    {
      property: 'status',
      type: 'label',
      width: '10%',
      labels: [
        { value: 'Elaborar'   , color: 'color-08', label: 'Elaborar' },
        { value: 'Ag. Validar', color: 'color-07', label: 'Ag.Validar' },
        { value: 'Ag. Assinar' , color: 'color-07', label: 'Ag.Assinar' },
        { value: 'Assinado'   , color: 'color-10', label: 'Assinado' },
      ]
    },
   ];

   colandamento: Array<PoTableColumn> = [
    { property: 'titulo', label: 'Tarefa'},
    { property: 'dtprevistofim', label: 'Prazo' },
   ];

   colproximas: Array<PoTableColumn> = [
    { property: 'titulo', label: 'Tarefa'},
    { property: 'dtprevistoini', label: 'Início' },
   ];

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(parameters => {
      this.idprojeto = parameters.get('projeto');
      this.getDados();
    });
  }

  abrilink(link, linha) {
    console.log(link);
    console.log(linha);
    window.open(linha.link, '_blank');
  }

  getDados() {
    this.httpService.get('/statusreport?projeto=' + this.idprojeto).subscribe(
      data => {
          this.titulo = data.titulo;
          this.tarefasqtdData[0].data[0] = data.tarefasqtd.realizadas;
          this.tarefasqtdData[1].data[0] = data.tarefasqtd.pendentes;
          this.totalquantidade = data.tarefasqtd.realizadas + data.tarefasqtd.pendentes ;
          this.spi = data.tarefasqtd.spi;
          this.tarefashrsData[0].data[0] = data.tarefashrs.realizadas;
          this.tarefashrsData[1].data[0] = data.tarefashrs.pendentes;
          this.totalhoras = data.tarefashrs.realizadas + data.tarefashrs.pendentes;
          this.pendencias = data.pendencias;
          this.riscos = data.riscos;
          this.documentos = data.documentos;
          this.andamento = data.tarefasandamento;
          this.proximas = data.proximastarefas;
      },
      error => {
      },
      () => {
      }
    );
  }

  acaowidget(tipo){
    console.log(tipo);
  }

}
