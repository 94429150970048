import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PoNotificationService, PoModalComponent, PoModalAction, PoPageAction, PoSelectOption, PoDisclaimer } from '@po-ui/ng-components';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-tarefas-analista',
  templateUrl: './tarefas-analista.component.html',
  styleUrls: ['./tarefas-analista.component.css']
})
export class TarefasAnalistaComponent implements OnInit {

  @ViewChild('poModal1') poModal1: PoModalComponent;
  @ViewChild('poModal2') poModal2: PoModalComponent;
  @ViewChild('poModal3') poModal3: PoModalComponent;

  afazer = [];
  fazendo = [];
  aguardando = [];
  feito = [];
  descricaotarefa;
  observacao;
  data;
  duracao;
  sessao;
  eventatual;
  historico;
  titulo;
  filtro;
  listafiltros: Array<PoDisclaimer> = [];
  carregando;
  comentario;
  tarefaatual;
  clientesel;
  projetosel;
  analistasel;
  datahrinicio;
  datacriacao;
  pendencia;
  listaanexos = [];
  pathanexos;
  codigo;
  ativaanexo = false;
  nomecliente;
  nomeanalista;
  nomeprojeto;

  clientes: Array<PoSelectOption> = [];
  analistas: Array<PoSelectOption> = [];
  projetos: Array<PoSelectOption> = [];

  pageactions: Array<PoPageAction> = [
    {label: 'Filtrar', action: this.filtrar.bind(this), icon: 'po-icon po-icon-filter'},
  ];

  close: PoModalAction = {
    action: () => {
      this.poModal1.close();
      this.poModal3.close();
    },
    label: 'Cancelar',
  };

  confirm: PoModalAction = {
    action: () => {
      this.poModal1.close();
      let body = {};
      body = {
        data: this.data,
        duracao: this.duracao,
        observacao: this.observacao,
        tecnico: this.sessao.tecnico
      };
      this.setStatus(body);
    },
    label: 'Confirmar'
  };

  confirmarfiltro: PoModalAction = {
    action: () => {
      this.confirmaFiltro();
      this.poModal3.close();
    },
    label: 'Filtrar'
  };

  constructor(
    private notitificacao: PoNotificationService,
    public httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    
    this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    
    if (localStorage.getItem('filtrostarefasanalistas')) {
      const auxfiltros = JSON.parse(localStorage.getItem('filtrostarefasanalistas'));
      auxfiltros.forEach(element => {

        if (element.property === 'cliente' ) {
          this.clientesel = element.value;
          this.nomecliente = element.label;
        }
        if (element.property === 'projeto' ) {
          this.projetosel = element.value;
          this.nomeprojeto = element.label;
        }
        if (element.property === 'tecnico' ) {
          this.analistasel = element.value;
          this.nomeanalista = element.label;
        }
      });
    }

    this.confirmaFiltro();

    this.route
    .queryParams
    .subscribe(params => {
      if (params.status) {
        this.listafiltros.push({label: 'Atrasadas', value: 'atrasadas', property: 'filtroextra' });
      }
    });
  }

  confirmaFiltro() {

    this.listafiltros = [];
    this.filtro = '';

    if (this.clientesel && this.clientesel !== '0') {
      this.filtro += 'cliente='  + this.clientesel + '&';
      this.clientes.findIndex((item) => {
        if (item.value === this.clientesel) {
          this.nomecliente = item.label;
        }
      });

      this.listafiltros.push({label: this.nomecliente, value: this.clientesel, property: 'cliente' });
    }
    if (this.projetosel  && this.projetosel !== '0') {
      this.filtro += 'projeto='  + this.projetosel + '&';
      this.projetos.findIndex((item) => {
        if (item.value === this.projetosel) {
          this.nomeprojeto = item.label;
        }
      });
      this.listafiltros.push({label: this.nomeprojeto, value: this.projetosel, property: 'projeto' });
    }
    if (this.analistasel  && this.analistasel !== '0') {
      this.filtro += 'tecnico='  + this.analistasel + '&';
      this.analistas.findIndex((item) => {
        if (item.value === this.analistasel) {
          this.nomeanalista = item.label;
        }
      });
      this.listafiltros.push({label: this.nomeanalista, value: this.analistasel, property: 'tecnico' });
    }

    if (!this.filtro) {      
      this.listafiltros = [];
      this.listafiltros.push({label: this.sessao.nameuser, value: this.sessao.tecnico, property: 'tecnico' });
    } else {
      this.filtro = '?' + this.filtro;
    }

    this.executaGets();
   
  }

  executaGets() {

    if (!this.filtro) {
      this.filtro = '?tecnico=' + this.sessao.tecnico;
    }

    this.getAguardando();
    this.getAfazer();
    this.getFazendo();
    this.getFeito();
    this.getClientes();
    this.getAnalistas();
    this.getProjetos();
  }

  getAguardando() {
    this.carregando = true;
    this.httpService.get('/tarefas/aguardando' + this.filtro).subscribe(
      data => {
          this.aguardando =  data.items;
          this.carregando = false;
      },
      error => {
        this.carregando = false;
      }
    );
  }
  getAfazer() {
    this.carregando = true;
    this.httpService.get('/tarefas/afazer' + this.filtro).subscribe(
      data => {
          this.afazer =  data.items;
          this.carregando = false;

        },
      error => {
        this.carregando = false;
      }
    );
  }
  getFazendo() {
    this.carregando = true;
    this.httpService.get('/tarefas/fazendo' + this.filtro).subscribe(
      data => {
          this.fazendo =  data.items;
          this.carregando = false;
      },
      error => {
        this.carregando = false;
      }
    );
  }

  getFeito() {
    this.carregando = true;
    this.httpService.get('/tarefas/feito' + this.filtro).subscribe(
      data => {
          this.feito =  data.items;
          this.carregando = false;
      },
      error => {
        this.carregando = false;
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {

    this.eventatual = event;
    const statusatual = this.eventatual.previousContainer.data[this.eventatual.previousIndex]['status'];
    this.descricaotarefa = this.eventatual.previousContainer.data[this.eventatual.previousIndex]['ordem'] + ' - ' +
                                this.eventatual.previousContainer.data[this.eventatual.previousIndex]['titulo'];


    const statusfinal = this.eventatual.container.id;

    if (statusfinal === '3' || statusatual === '2') {
      this.datahrinicio = '';
      if (this.eventatual.previousContainer.data[this.eventatual.previousIndex]['historico'][0]) {
        this.datahrinicio = this.eventatual.previousContainer.data[this.eventatual.previousIndex]['historico'][0]['dtgrava'] + ' - ' +
        this.eventatual.previousContainer.data[this.eventatual.previousIndex]['historico'][0]['hrgrava']
      }
      this.data = new Date();
      this.duracao = '';
      this.observacao = '';
      this.poModal1.open();
    } else {
      const body = {tecnico: this.sessao.tecnico};
      this.setStatus(body);
    }
  }

  setStatus(body) {

    const tarefa = this.eventatual.previousContainer.data[this.eventatual.previousIndex]['codigo'];
    const projeto = this.eventatual.previousContainer.data[this.eventatual.previousIndex]['projeto'];
    const status = this.eventatual.container.id;
    const statusatual = this.eventatual.previousContainer.data[this.eventatual.previousIndex]['status'];

    if (statusatual === status) {
      return;
    }

    this.httpService.post('/tarefas/setstatus?tarefa=' + tarefa  + '&status=' + status + '&projeto=' + projeto, body).subscribe(
      data => {
          transferArrayItem(this.eventatual.previousContainer.data,
            this.eventatual.container.data,
            this.eventatual.previousIndex,
            this.eventatual.currentIndex);
            this.atualizaLista(status);
      },
      error => {
        this.notitificacao.error('Não foi possível mudar status da tarefa, por favor tente novamente. ');
      }
    );
  }

  atualizaLista(status) {
    switch (status) {
      case '0':
        this.getAguardando();
        break;
      case '1':
        this.getAfazer();
        break;
      case '2':
        this.getFazendo();
        break;
      case '3':
        this.getFeito();
        break;
    }
  }

  detalhetarefa(item) {
    this.router.navigate(['/tarefasanalista', item.codigo]);
  }

  filtrar() {
    if (!this.analistasel) {
      this.analistasel = this.sessao.tecnico;
    }
    this.poModal3.open();
  }

  getClientes() {
    this.httpService.get('/agendasagisrn/' + this.sessao.tecnico + '/clientes').subscribe(
      data => {
        this.clientes.push({label: 'Todos', value: '0'});
        data.items.forEach(element => {
          this.clientes.push({label: element.nomecliente, value: element.codigo + element.loja});
        });
      },
    );
  }
  getAnalistas() {
    this.httpService.get('/projetos/tarefas/' + this.sessao.tecnico + '/analistas').subscribe(
      data => {
        this.analistas.push({label: 'Todos', value: '0'});
        data.items.forEach(element => {
          this.analistas.push({label: element.nome, value: element.codigo});
        });
      },
    );
  }
  getProjetos() {
    this.httpService.get('/projetos').subscribe(
      data => {
        this.projetos.push({label: 'Todos', value: '0'});
        data.items.forEach(element => {
          this.projetos.push({label: element.descricao, value: element.codigo});
        });
      },
    );
  }

  removefiltro(filtro) {

    this.filtro = '?';
    filtro.forEach(element => {
      if (element.value !== '0') {
        this.filtro +=  element.property + '='  + element.value + '&';
      }
    });
    localStorage.setItem('filtrostarefasanalistas', JSON.stringify(filtro));
    this.executaGets();
  }

  salvacomentario() {

      const body = {
        tecnico: this.sessao.tecnico,
        observacao: this.comentario,
        pendencia: this.pendencia,
        datacriacao: this.datacriacao
      };
      this.carregando = true;
      this.httpService.post('/tarefas/setstatus?tarefa=' + this.tarefaatual.codigo  + '&status=' +
        this.tarefaatual.status , body).subscribe(
        data => {
          this.carregando = false;
          this.atualizaLista(this.tarefaatual.status);
          this.comentario = '';
        },
        error => {
          this.carregando = false;
          this.notitificacao.error('Não foi possível gravar comentário, por favor tente novamente. ');
        }
      );
      this.poModal2.close();
  }

}
