import { PoNotificationService } from '@po-ui/ng-components';
import { HttpService } from 'src/app/services/http.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PoPageLoginLiterals } from '@po-ui/ng-templates';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  customLiterals: PoPageLoginLiterals = {
    loginPlaceholder: 'Insira seu usuário',
    passwordPlaceholder: 'Insira sua senha',
    submitLabel: 'Entrar',
    rememberUser: 'Lembrar usuário',
    rememberUserHint: 'Mantém o usuário logado durante a sessão',
    // title: 'Olá, Bem-Vindo',
    loginHint: 'Utilize o mesmo usuário usado no ERP',
  };

  background = './assets/imgs/background.png';
  logo = './assets/imgs/uf-logo.png';
  carregando = false

  constructor(  
      private http: HttpService,
      private rota: Router,
      private poNotifica: PoNotificationService
    )
     {

   }

  ngOnInit() {

  }

  async efetuarLogin(dados: any) {    
      this.carregando = true

     this.http.post('/authagisrn/login', dados).subscribe(
      (data: any) => {
        if (data.code == '200') {
          localStorage.setItem('PO_USER_LOGIN', JSON.stringify(data))
          this.rota.navigate(['/'])
        } else {
          this.poNotifica.error('Usuário ou senha inválidos.')
        }
      },
      (error: any) => {
        this.carregando = false 
        this.poNotifica.error('Usuário ou senha inválidos.')
      },
      () => {
        this.carregando = false 
      }
    )   
  }

}
