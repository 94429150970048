import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { PoNotificationService } from '@po-ui/ng-components';

@Component({
  selector: 'app-tarefa-ordem',
  templateUrl: './tarefa-ordem.component.html',
  styleUrls: ['./tarefa-ordem.component.css']
})
export class TarefaOrdemComponent implements OnInit {

  ordens = [];
  tarefas = [];
  codigos = [];
  tipos = [];

  idprojeto;
  titulo;

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tarefas, event.previousIndex, event.currentIndex);
    moveItemInArray(this.codigos, event.previousIndex, event.currentIndex);
  }

  constructor(
    private notitificacao: PoNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    public httpService: HttpService,
  ) {
  }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        this.idprojeto = params.projeto;
        this.titulo = params.titulo;
        JSON.parse(params.dados).map((tarefa) => {
          this.tarefas.push(tarefa.titulo);
          this.ordens.push(tarefa.ordem);
          this.codigos.push(tarefa.codigo);
          this.tipos.push(tarefa.tipo);
        }
        );
   });
 }


 confirmar() {

   const body = {tarefas: this.codigos, ordens: this.ordens};
   this.httpService.post('/projetos?projeto=' + this.idprojeto + '&ordena=G', body, ).subscribe(
    data => {
      this.router.navigate(['/tarefas', this.idprojeto ]);
    },
    error => {
      this.notitificacao.error('Erro ao tentar gravar a reordenação.');
    },
    () => {
    }
  );

 }

 cancelar() {
  this.router.navigate(['/tarefas', this.idprojeto ]);
 }

}
