import { element } from 'protractor';
import { Component, OnInit, ViewEncapsulation, ViewChild  } from '@angular/core';
import { HttpService } from '../services/http.service';
import { PoNotificationService, PoMultiselectOption } from '@po-ui/ng-components';
import { ActivatedRoute } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
@Component({
  selector: 'app-cronograma',
  templateUrl: './cronograma.component.html',
  styleUrls: ['./cronograma.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class cronogramaComponent implements OnInit {

  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;

  columnDefs = [];
  rowData;
  dados;
  carregando: boolean;
  linhas = [];
  idprojeto = '';
  pesquisasel = 'A';
  tiposel = 'T';
  rowStyle;
  datade;
  dataate;
  ordens = '';
  titulo;
  codigotarefa = '';

  tipopesquisa: Array<PoMultiselectOption> = [
    { value: 'P', label: 'Previsto' },
    { value: 'R', label: 'Realizado' }
  ];

  tipofiltro: Array<PoMultiselectOption> = [
    { value: 'A', label: 'Analítico' },
    { value: 'S', label: 'Sintético' },
    { value: 'T', label: 'Todos' }
  ];

  private gridApi;
  private gridColumnApi;

  constructor(
    private route: ActivatedRoute,
    private notitificacao: PoNotificationService,
    private httpService: HttpService) { }

  ngOnInit() {

    this.rowStyle = { background: 'black' };

    this.pesquisasel = 'P';
    this.tiposel = 'T';
    
    this.route
      .queryParams
      .subscribe(params => {
        this.codigotarefa = params.tarefa;
       });    

    
    this.route.paramMap.subscribe(parameters => {
      this.idprojeto = parameters.get('projeto');
      this.getdados();
    });



  }

  getdados() {

    let filtros = 'tipo=' + this.tiposel + '&ordens=' + this.ordens + '&tipocronograma=' + this.pesquisasel + '&' ;

    if (this.datade && this.dataate) {
      filtros += 'datade=' + this.datade.replace(/-/g, '');
      filtros += '&dataate=' + this.dataate.replace(/-/g, '');
    }

    if (this.codigotarefa === undefined) {
      this.codigotarefa = '';
    }


    this.carregando = true;
    this.httpService.get('/cronograma?projeto=' + this.idprojeto + '&' + filtros + '&tarefa='+this.codigotarefa).subscribe(
      data => {
        this.titulo = data.titulo;
        this.columnDefs = data.headers;
        this.columnDefs.push({headerName: 'Tarefa'  , field: 'tarefa'  , pinned: 'left', width: 250,  tooltipField: 'tarefa', });
        this.columnDefs.push({headerName: 'Analista', field: 'analista', pinned: 'left', width: 110});
        this.columnDefs.push({headerName: 'Horas'   , field: 'duracao' , pinned: 'left', width: 55});
        this.rowData = data.rows;
        this.linhas = [];
        this.columnDefs.forEach((e, index) => {
          if (this.columnDefs[index].children) {
            this.columnDefs[index].children.forEach((element, idchild) => {
              this.columnDefs[index].children[idchild].children[0].cellStyle = (params: any) => {
                if (!this.linhas[params.node.__objectId - 1]) {
                  const fields = {maior: params.data.maiorfield, menor: params.data.menorfield};
                  this.linhas[params.node.__objectId - 1] = fields;
                }
                if (params.colDef.field >= this.linhas[params.node.__objectId - 1].menor  &&
                    params.colDef.field <= this.linhas[params.node.__objectId - 1].maior ) {
                      if (params.data.tipo === 'S') {
                        return {backgroundColor: '#0cbe79', color: 'white', 'font-weight': 'bold'};
                      } else {
                        return {backgroundColor: '#0c9abe', color: 'white', 'font-weight': 'bold'};
                      }
                }
              };
            });
          }
         }
        );
      },
      error => {
        this.carregando = false;
      },
      () => {
        this.carregando = false;
      }
    );
  }

  editar(params) {

    let atualiza = false;
    const paramsaux = {
      force: true,
      rowNodes: [params.node],
    };

    if (params.colDef.field < this.linhas[params.node.__objectId - 1].menor ) {
      this.linhas[params.node.__objectId - 1].menor  = params.colDef.field;
      atualiza = true;
    }

    if (params.colDef.field > this.linhas[params.node.__objectId - 1].maior) {
      this.linhas[params.node.__objectId - 1].maior  = params.colDef.field;
      atualiza = true;
    }

    if (atualiza || params.newValue) {
      this.gridApi.refreshCells(paramsaux);
    }

    const dados = {projeto: this.idprojeto, datatarefa: params.colDef.field, tarefa: params.data.codtarefa, hora: params.newValue};
    this.httpService.post('/cronograma', dados ).subscribe(
      data => {        
        if(data['code'] === '400') {
          this.notitificacao.error(data['detail']);
        } else {
          this.getdados();
        }
      },
      error => {
        this.notitificacao.error('Erro na gravação do cronograma');
      },
      () => {
      }
    );
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.agGrid.gridOptions.rowClassRules = {
      'sintetico': function(params) { return params.data.tipo === 'S'},
    };
  }

}
