import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PoModalComponent, PoNotificationService, PoPageAction, PoTableAction, PoTableColumn } from '@po-ui/ng-components';
import { BaseComponent } from '../base/base.component';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-ordens-servico',
  templateUrl: './ordens-servico.component.html',
  styleUrls: ['./ordens-servico.component.css']
})
export class OrdensServicoComponent extends BaseComponent implements OnInit {
  
  @ViewChild('poModal') poModal1: PoModalComponent;
  
  constructor( 
    private notitificacao: PoNotificationService,
    public httpService: HttpService) {
      super(httpService, '/atendimento/ordens/', '' )
     }

  textoLaudo;

  tipo = [
      { value: 'todas', label: 'Todas' },
      { value: 'Ticket', label: 'Tickets' },
      { value: 'Agenda', label: 'Agendas' },
  ];  

  filters;

  ngOnInit(): void {

    let auxfiltros = [];

      const dtbase = new Date();
      const datainicio = new Date(dtbase.getFullYear(), dtbase.getMonth(), 1);
      const datafinal = new Date(dtbase.getFullYear(), dtbase.getMonth() + 1, 0);
      let datainiciostr = datainicio.toISOString().slice(0, 10);
      let datafinalstr = datafinal.toISOString().slice(0, 10);
      let tipofiltro = 'todas';

      this.sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));

      if (localStorage.getItem('filtrosordens')) {
         auxfiltros = JSON.parse(localStorage.getItem('filtrosordens'));
         auxfiltros.forEach(element => {
          if (element.property === 'datade') {
            datainiciostr = element.value;
          } else if (element.property === 'dataate') {
            datafinalstr = element.value;
          } else if (element.property === 'tipo') {
            tipofiltro = element.property
           }
         });
      }

      this.carregando = true;

      this.filters = [
        { property: 'tipo', label: 'Tipo', gridColumns: 4, options: this.tipo, initValue: tipofiltro},
        { property: 'datade', label: 'Data inicial', gridColumns: 4, type: 'date',
        initValue: datainiciostr },
        { property: 'dataate', label: 'Data final', gridColumns: 4, type: 'date',
        initValue: datafinalstr }
      ];

      this.colunas = this.getColumns();
      
      if (auxfiltros.length === 0) {        
        this.get();
      } else {
        this.carregando = false;
      }


  }


  tableActions: Array<PoTableAction> = [
    {label: 'Laudo'    , action: this.laudo.bind(this), icon: 'po-icon po-icon-eye' },
    // {label: 'Histórico' , action: this.historico.bind(this), icon: 'po-icon po-icon-history' }
  ];

  pageactions: Array<PoPageAction> = [

  ];

  getColumns(): Array<PoTableColumn> {
    return [
      { property: 'data', label: 'Data' },
      {
        property: 'tipo',
        type: 'label',
        width: '10%',
        labels: [
          { value: 'Ticket'       , color: 'color-01', label: 'Ticket'},
          { value: 'Agenda'       , color: 'color-08', label: 'Agenda'},
        ]
      },  
      { property: 'os', label: 'Num. OS' },
      { property: 'inicio', label: 'Inicio' },
      { property: 'fim', label: 'Fim' },
      { property: 'intervalo', label: 'Intervalo' },
      { property: 'total', label: 'Total' },
      { property: 'cliente', label: 'Cliente' },
      { property: 'projeto', label: 'Projeto' },
      { property: 'motivo', label: 'Motivo' },
      { property: 'ticket', label: 'Ticket' },
    ];
  }

  changeFilter(disclaimer) {

    let filtroaux = '';
    let valor;
    

    disclaimer.forEach(fil => {
      if (Array.isArray(fil.value)) {
        valor = '';
        fil.value.forEach(e => {
          valor += e + ',';
        });
      } else {
        valor = fil.value;
      }
      filtroaux += '&' + fil.property + '=' + valor;
    });

    localStorage.setItem('filtrosordens', JSON.stringify(disclaimer));

    this.filtros = filtroaux;
    this.filtros = this.filtros.replace(/-/g, '');
    this.get();
  }

  buscaavancada(filter) {
    let disclaimer = [];

    console.log(filter);

    if (filter.datade) {
      disclaimer.push({property: 'datade', value: filter.datade});
    }
    if (filter.dataate) {
      disclaimer.push({property: 'dataate', value: filter.dataate});
    }
    if (filter.tipo) {
      disclaimer.push({property: 'tipo', value: filter.tipo});
    }

    this.changeFilter(disclaimer);

  }

  laudo(row) {
    this.textoLaudo = row.laudo
    this.poModal1.open()
   }
}
