import { Component, OnInit  } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-portifolio',
  templateUrl: './portifolio.component.html',
  styleUrls: ['./portifolio.component.css']
})
export class PortifolioComponent implements OnInit {

  constructor(private rounte: Router) { }

  ngOnInit() {
  }

  detalhes(produto: string) {
    this.rounte.navigate(['/detalhes', produto]);
  }

}
